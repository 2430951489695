import { WhatsappIcon } from '@assets/svg/WhatsappIcon'
import { CustomLink } from '@components/CustomLink'
import { FOOTER_PHONES } from '@components/Layout/components/Footer/components/ContactsAndInstitutional/constants'
import { ColoredSvg } from '@components/ui/ColoredSvg'
import { EXTERNAL_LINKS } from '@constants/common'
import { themeStyled } from '@theme/theme'
import * as Styled from './styles'

export const PopoverContentView = () => (
    <Styled.Container>
        <Styled.PopoverItem>
            <ColoredSvg
                color={themeStyled.colors.gray[500]}
                svg={<WhatsappIcon width={16} height={16} />}
            />
            <Styled.PopoverText>
                <CustomLink
                    href={EXTERNAL_LINKS.televendas_whatsapp_web}
                    target="_blank"
                >
                    Televendas:
                    {` ${FOOTER_PHONES.televendas.text}`}
                </CustomLink>
            </Styled.PopoverText>
        </Styled.PopoverItem>
        <Styled.PopoverItem>
            <ColoredSvg
                color={themeStyled.colors.gray[500]}
                svg={<WhatsappIcon width={16} height={16} />}
            />
            <Styled.PopoverText>
                <CustomLink
                    href={EXTERNAL_LINKS.contato_whatsapp_web}
                    target="_blank"
                >
                    Atendimento ao cliente:
                    {` ${FOOTER_PHONES.whatsapp.text}`}
                </CustomLink>
            </Styled.PopoverText>
        </Styled.PopoverItem>
    </Styled.Container>
)
