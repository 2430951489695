import { DESKTOP_SIZE } from '@constants/device'
import { createTheme } from '@material-ui/core/styles'

const customThemeStyled = (isDesktop?: boolean) => ({
    colors: {
        primary: {
            '900': '#E65200',
            '800': '#EF6D00',
            '700': '#F57D00',
            '600': '#FB8D00',
            '500': '#FF9900',
            '400': '#FFA826',
            '300': '#FFB84D',
            '200': '#FFCD80',
            '100': '#FFE0B2',
            '50': '#FFF3E0'
        },
        secondary: {
            '900': '#2341E0',
            '800': '#0066FF',
            '700': '#0079FF',
            '600': '#008EFF',
            '500': '#009DFF',
            '400': '#00ADFF',
            '300': '#46BDFF',
            '200': '#88D0FF',
            '100': '#BAE2FF',
            '50': '#E2F4FF'
        },
        dark: {
            '1000': '#000000',
            '900': '#090A0B',
            '800': '#1C1F22',
            '700': '#2E3338',
            '600': '#40474F',
            '500': '#535B65',
            '400': '#656F7B',
            '300': '#788391',
            '200': '#8F98A3',
            '100': '#A5ADB6',
            '50': '#BCC1C8'
        },
        gray: {
            '1000': '#D2D8DA',
            '900': '#2E3538',
            '800': '#404B4F',
            '700': '#536065',
            '600': '#65757B',
            '500': '#788A91',
            '400': '#8F9DA3',
            '300': '#A5B1B6',
            '200': '#BCC4C8',
            '100': '#E9E9E9',
            '50': '#F4F5F6'
        },
        green: {
            '900': '#1B4809',
            '800': '#296B0E',
            '700': '#368F13',
            '600': '#3EA515',
            '500': '#44B717',
            '400': '#4DCB1B',
            '300': '#54DB1E',
            '200': '#58E81F',
            '100': '#A1EE84',
            '50': '#E0FFD3'
        },
        yellow: {
            '900': '#A06903',
            '800': '#C88304',
            '700': '#F09E05',
            '600': '#FBAF23',
            '500': '#FBB73B',
            '400': '#FCC55F',
            '300': '#FCCC73',
            '200': '#FDD387',
            '100': '#FEE2AF',
            '50': '#FEF1D8'
        },
        red: {
            '900': '#8F0007',
            '800': '#B80009',
            '700': '#E0000B',
            '600': '#FF1F2A',
            '500': '#FF333D',
            '400': '#FF4751',
            '300': '#FF5C64',
            '200': '#FF7077',
            '100': '#FFC2C5',
            '50': '#FFEBEC'
        },
        blackFriday: {
            '700': '#3C3C3B'
        },
        white: '#FFFFFF',
        black: '#000000',
        border: '#E9E9E9',
        boxShadow: 'rgba(108, 65, 0, 0.05)',
        backgroundShadow: 'rgba(0, 0, 0, 0.5)',
        transparent: 'transparent',
        opacity: 'rgba(255, 255, 255, 0.3)'
    },
    socialMedia: {
        email: '#FF333D',
        whatsapp: '#25D366',
        facebook: '#1877F2',
        twitter: '#1DA1F2'
    },
    fontFamily: {
        sansSerif:
            'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, "Noto Sans", "Liberation Sans", Arial, sans-serif'
    },
    fontWeight: {
        sansSerifLight: 300,
        sansSerifRegular: 400,
        sansSerifMedium: 500,
        sansSerifBold: 700
    },
    boxShadow: {
        heavy: '0px 1px 3px rgba(0, 0, 0, 0.2)'
    },
    shape: {
        borderRadius: 4
    },
    breakpoints: {
        xs: 0,
        sm: 720,
        md: 960,
        lg: DESKTOP_SIZE,
        xl: 1580
    },
    isDesktop
})

const themeStyled = customThemeStyled()

const themeMaterialUI = createTheme({
    overrides: {
        MuiButton: {
            text: {
                fontFamily: themeStyled.fontFamily.sansSerif,
                fontWeight: themeStyled.fontWeight.sansSerifMedium,
                fontSize: '14px'
            }
        },
        MuiPaper: {
            elevation8: {
                boxShadow: '0px',
                border: `0.5px solid ${themeStyled.colors.gray[200]}`
            }
        },
        MuiOutlinedInput: {
            root: {
                '&$focused $notchedOutline': {
                    borderColor: `1px solid ${themeStyled.colors.gray[200]}`
                }
            }
        },
        MuiInput: {
            root: {
                backgroundColor: `${themeStyled.colors.gray[100]}`
            }
        },
        MuiMenu: {
            list: {
                display: 'flex',
                flexDirection: 'column'
            }
        },
        MuiRadio: {
            colorPrimary: {
                '&$root': {
                    color: themeStyled.colors.gray[500],
                    '&$checked': {
                        color: themeStyled.colors.primary[500]
                    }
                }
            }
        },
        MuiBackdrop: {
            root: {
                '&[style*="opacity: 0"]': {
                    pointerEvents: 'none'
                }
            }
        }
    },
    typography: {
        fontFamily: themeStyled.fontFamily.sansSerif
    },
    breakpoints: {
        values: {
            xs: themeStyled.breakpoints.xs,
            sm: themeStyled.breakpoints.sm,
            md: themeStyled.breakpoints.md,
            lg: themeStyled.breakpoints.lg,
            xl: themeStyled.breakpoints.xl
        }
    },
    palette: {
        common: {
            white: themeStyled.colors.white,
            black: themeStyled.colors.black
        },
        primary: {
            light: themeStyled.colors.primary[300],
            main: themeStyled.colors.primary[500],
            dark: themeStyled.colors.primary[700]
        },
        secondary: {
            light: themeStyled.colors.secondary[300],
            main: themeStyled.colors.secondary[800],
            dark: themeStyled.colors.secondary[900]
        },
        error: {
            light: themeStyled.colors.red[300],
            main: themeStyled.colors.red[500],
            dark: themeStyled.colors.red[700]
        },
        warning: {
            light: themeStyled.colors.yellow[300],
            main: themeStyled.colors.yellow[500],
            dark: themeStyled.colors.yellow[700]
        },
        success: {
            light: themeStyled.colors.green[300],
            main: themeStyled.colors.green[500],
            dark: themeStyled.colors.green[700]
        },
        grey: {
            '50': themeStyled.colors.gray[50],
            '100': themeStyled.colors.gray[100],
            '200': themeStyled.colors.gray[200],
            '300': themeStyled.colors.gray[300],
            '400': themeStyled.colors.gray[400],
            '500': themeStyled.colors.gray[500],
            '600': themeStyled.colors.gray[600],
            '700': themeStyled.colors.gray[700],
            '800': themeStyled.colors.gray[800],
            '900': themeStyled.colors.gray[900]
        }
    },
    shape: {
        borderRadius: themeStyled.shape.borderRadius
    }
})

export { customThemeStyled, themeStyled, themeMaterialUI }
