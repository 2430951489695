import { CampaignData } from '@store/modules/home/types'

export const isCampaignData = (obj: any): obj is CampaignData => {
    return (
        obj.nome !== undefined &&
        obj.imagem !== undefined &&
        obj.link !== undefined &&
        obj.tamanho !== undefined &&
        obj.acaoClick !== undefined
    )
}

export const isHotsiteLink = (link: string) => link.includes('hotsite')

export const getHotSiteSlugFromLink = (link: string) => {
    const splittedLink = link.split('/')
    const hotsitePosition = splittedLink.indexOf('hotsite')
    const slugPosition = hotsitePosition + 1

    return splittedLink[slugPosition]
}
