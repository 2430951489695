import * as Styled from './styles'
import { themeStyled } from '@theme/theme'
import { Typography } from '@components/ui/Typography'
import { CustomLink } from '@components/CustomLink'
import { Routes } from '@service/routes'

export type QuerySearchProps = {
    query: string
    link: string
}

export const QuerySearchView = ({
    query,
    link
}: QuerySearchProps): JSX.Element => {
    const querySearchParams = new URLSearchParams(
        link.substring(link.indexOf('?'), link.length)
    )

    return (
        <CustomLink
            href={{
                pathname: Routes.search,
                query: {
                    searchTerm: query,
                    ranking: querySearchParams.get('ranking'),
                    p: querySearchParams.get('p'),
                    origin: querySearchParams.get('origin')
                }
            }}
        >
            <Styled.Container
                container
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography
                    size="xs"
                    variant="span"
                    fontFamily="medium"
                    color={themeStyled.colors.gray[700]}
                >
                    {query}
                </Typography>
                <Styled.ArrowIcon />
            </Styled.Container>
        </CustomLink>
    )
}
