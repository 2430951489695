import { CustomLink } from '@components/CustomLink'
import { Routes } from '@service/routes'
import { UserAvatar } from '../UserAvatar'
import * as Styled from './styles'

export const LoggedOutUserView = (): JSX.Element => {
    return (
        <Styled.Container>
            <UserAvatar />
            <div>
                <Styled.Title>Bem-vindo ;)</Styled.Title>

                <Styled.Link>
                    <CustomLink href={Routes.login}>
                        Entre ou cadastre-se
                    </CustomLink>
                </Styled.Link>
            </div>
        </Styled.Container>
    )
}
