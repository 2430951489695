import { getConfig } from 'src/seo/utils/defaultSeo'
import { SeoData } from '@store/modules/url/types'
import { DefaultSeo, NextSeo } from 'next-seo'
import { Routes } from '@service/routes'
import Head from 'next/head'
import { getSafePathName } from '@utils/url'

type DefaultHeadProps = {
    metaTags?: SeoData
    pathname?: string
}

export const DefaultHeadView = ({
    pathname,
    metaTags
}: DefaultHeadProps): JSX.Element => {
    const config = getConfig()

    return (
        <Head>
            {getSafePathName(pathname ?? '') !== Routes.resetPassword && (
                <>
                    <script
                        id="api-context-a"
                        dangerouslySetInnerHTML={{
                            __html: `(function() {
                                var firstScript = document.getElementsByTagName('script')[0]
                                  , newScript = document.createElement('script');
                                  newScript.id = 'api-context-b';
                                  newScript.fetchPriority = 'high';
                                try {
                                    var getCookieByName = (c,n)=>c.find(el=>el.startsWith(n + '='))?.replace(n + '=', '')
                                      , cookies = decodeURIComponent(document.cookie).split('; ');
                                    var storeId = getCookieByName(cookies, 'StoreId') || '10154'
                                      , urlParams = new URLSearchParams(window.location.search)
                                      , getCanal = (c)=>getCookieByName(c, 'acessoRestrito') === 'true' ? 'ca_861' : 'ca_0';
                                    var canal = urlParams.get('canal') || getCookieByName(cookies, 'UserSegment') || getCanal(cookies);
                                    newScript.src = '/loja/API-Context.js?storeId=' + storeId + '&canal=' + canal;
                                } catch (e) {
                                    newScript.src = '/loja/API-Context.js';
                                }
                                localStorage.setItem('cookieHasLoaded', 'false');
                                localStorage.setItem('efacilContextHasLoaded', '0');
                                firstScript.parentNode.insertBefore(newScript, firstScript);
                            }
                            )();`
                        }}
                    ></script>
                </>
            )}

            {getSafePathName(pathname ?? '') !== Routes.checkoutConclusion && (
                <>
                    <script
                        id="gtmScript"
                        dangerouslySetInnerHTML={{
                            __html: `window.dataLayer=window.dataLayer||[];
                            // Google Tag Manager
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
                            // End Google Tag Manager`
                        }}
                    ></script>
                </>
            )}
            <DefaultSeo {...config} />
            <NextSeo
                title={metaTags?.page.title}
                description={metaTags?.page.metaDescription}
                additionalMetaTags={[
                    {
                        name: 'keyword',
                        content: metaTags ? metaTags?.page.metaKeyword : ''
                    },
                    {
                        name: 'viewport',
                        content: 'width=device-width, initial-scale=1'
                    }
                ]}
                additionalLinkTags={[
                    {
                        rel: 'icon',
                        href: 'favicon.ico',
                        type: 'image/gif',
                        sizes: '16x16'
                    },
                    {
                        rel: 'manifest',
                        href: '/site.webmanifest',
                        keyOverride: 'site-manifest'
                    }
                ]}
            />
        </Head>
    )
}
