export const CANAL_TELEVENDEDOR = 'ca_861'
export const CANAL_CLIENTE = 'ca_0'

type OptionalParameter<DataType> = DataType extends never
    ? [data?: never]
    : [data: DataType]

export type ThunkOptions<SuccessData = never, ErrorData = never> = {
    onSuccess?: (...data: OptionalParameter<SuccessData>) => void
    onError?: (...data: OptionalParameter<ErrorData>) => void
}

export type DefaultErrorThunkOptions<SuccessData = void> = ThunkOptions<
    SuccessData,
    string | undefined
>
