import { isDesktopPage } from '@utils/device'
import { useRouter } from 'next/router'
import React, { createContext, useContext } from 'react'

type DeviceContextState = {
    isDesktop?: boolean
}

type DeviceProviderProps = {
    children?: React.ReactNode
} & DeviceContextState

export const DeviceContext = createContext<DeviceContextState | null>(null)
DeviceContext.displayName = 'DeviceContext'

export const DeviceProvider = ({
    children,
    isDesktop
}: DeviceProviderProps) => {
    const value: DeviceContextState = { isDesktop }
    const router = useRouter()

    if (isDesktop === undefined)
        value.isDesktop = isDesktopPage(router.pathname)

    return (
        <DeviceContext.Provider value={value}>
            {children}
        </DeviceContext.Provider>
    )
}

export const useDeviceContext = (): DeviceContextState => {
    return useContext(DeviceContext) as DeviceContextState
}
