import { ArrowDownIcon } from '@assets/svg/ArrowIcons/ArrowDownIcon'
import { CustomPopover } from '@components/Layout/components/CustomPopover'
import { Stack } from '@components/Stack'
import { IOProps } from 'react-compose-io'
import { MediaChannelModal } from './components/MediaChannelModal'
import { TelevendedorAvatar } from './components/TelevendedorAvatar'
import { LoggedInTelevendedorIO } from './io'
import * as Styled from './styles'

export type LoggedInTelevendedorProps = {
    userName: string
    isTelevendedor: boolean
}

export const LoggedInTelevendedorView = ({
    _io
}: IOProps<LoggedInTelevendedorIO, LoggedInTelevendedorProps>): JSX.Element => {
    return (
        <Styled.Container>
            <TelevendedorAvatar />
            <Stack>
                <Styled.UserNameContainer>
                    {process.env.NEXT_PUBLIC_FEATURE_TROCA_MIDIA_TELEVENDAS ===
                    'true' ? (
                        <CustomPopover
                            isOpen={_io.isOpen}
                            handlePopover={_io.handlePopover}
                            buttonContent={
                                <Styled.UserNameButton>
                                    <Styled.UserName>
                                        {_io.firstName()}
                                    </Styled.UserName>
                                    <ArrowDownIcon />
                                </Styled.UserNameButton>
                            }
                            popoverContent={
                                <>
                                    <Styled.PopoverContent>
                                        <Styled.InfoChannel>
                                            <Styled.TextChannel>
                                                Canal:{' '}
                                                <Styled.CurrentChannel>
                                                    {_io.channelName}
                                                </Styled.CurrentChannel>
                                            </Styled.TextChannel>
                                        </Styled.InfoChannel>
                                        <Styled.PopoverButton>
                                            <Styled.PopoverText
                                                onClick={
                                                    _io.handleChangeChannel
                                                }
                                            >
                                                Alterar canal
                                            </Styled.PopoverText>
                                        </Styled.PopoverButton>
                                        <Styled.PopoverButton
                                            onClick={_io.handleLogout}
                                        >
                                            <Styled.PopoverText>
                                                Sair
                                            </Styled.PopoverText>
                                        </Styled.PopoverButton>
                                    </Styled.PopoverContent>
                                </>
                            }
                        />
                    ) : (
                        <CustomPopover
                            buttonContent={
                                <Styled.UserNameButton>
                                    <Styled.UserName>
                                        {_io.firstName()}
                                    </Styled.UserName>
                                    <ArrowDownIcon />
                                </Styled.UserNameButton>
                            }
                            popoverContent={
                                <Styled.PopoverContent>
                                    <Styled.PopoverButton
                                        onClick={_io.handleLogout}
                                    >
                                        <Styled.PopoverText>
                                            Sair
                                        </Styled.PopoverText>
                                    </Styled.PopoverButton>
                                </Styled.PopoverContent>
                            }
                        />
                    )}
                </Styled.UserNameContainer>
                {process.env.NEXT_PUBLIC_FEATURE_TROCA_MIDIA_TELEVENDAS ===
                'true' ? (
                    <Styled.MediaName>
                        {_io.televendasChannelName}
                    </Styled.MediaName>
                ) : (
                    <></>
                )}
            </Stack>
            <MediaChannelModal
                isModalOpen={_io.showModal}
                closeModal={_io.handleCloseModal}
                isTelevendedor={_io.isTelevendedor}
            />
        </Styled.Container>
    )
}
