import { isMobile } from 'react-device-detect'

const hasDesktopInUrl = (url: string) => {
    return url.toLowerCase().includes('desktop')
}
// NOSONAR TODO: Medida paleativa para renderizar páginas de erro desktop enquanto a
// const DESKTOP_PATHS ainda existe
const isAnErrorPage = (url: string) => {
    return url.toLowerCase().includes('/404')
}

export const isDesktopPage = (url: string) => {
    return hasDesktopInUrl(url) || (isAnErrorPage(url) && !isMobile)
}
