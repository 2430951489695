import { Stack } from '@components/Stack'
import { themeStyled } from '@theme/theme'
import styled, { css } from 'styled-components'

const { breakpoints } = themeStyled

export type BreakpointNames = keyof typeof breakpoints

type AdaptiveStackProps = {
    $maxWidth: BreakpointNames
    $minWidth: BreakpointNames
}

const getMediaQueries = (
    maxWidth: BreakpointNames,
    minWidth: BreakpointNames
) => {
    const breakpointNames = Object.keys(breakpoints) as BreakpointNames[]
    const maxIndex = breakpointNames.indexOf(maxWidth)
    const minIndex = breakpointNames.indexOf(minWidth)
    const breakpointsToApply = breakpointNames.slice(minIndex, maxIndex + 1)

    return breakpointsToApply.map(getMediaQuery)
}

const getMediaQuery = (breakpoint: BreakpointNames) => {
    if (breakpoint === 'xs') return ''
    return css`
        @media (min-width: ${breakpoints[breakpoint]}px) {
            max-width: ${breakpoints[breakpoint]}px;
        }
    `
}

export const Container = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`

export const AdaptiveStack = styled(Stack)<AdaptiveStackProps>`
    width: 100%;
    ${({ $maxWidth, $minWidth }) => getMediaQueries($maxWidth, $minWidth)}
`
