import { Routes } from '@service/routes'
import { appActions } from '@store/modules/app/slice'
import { useAppDispatch, useAppSelector } from '@store/modules/store'
import { useEffect } from 'react'

/**
 * Hook utilizado para o redirecionamento após o login.
 * Define e retorna qual deve ser a próxima página após o login.
 */
export function useLoginRedirection() {
    const dispatch = useAppDispatch()
    const appRoutes = useAppSelector(state => state.app.routes)

    const route =
        typeof window !== 'undefined'
            ? window.location.pathname + window.location.search
            : undefined

    // Na abertura da página, define a rota atual e a rota anterior.
    useEffect(() => {
        if (route && !window.location.pathname.match(Routes.resetPassword)) {
            dispatch(appActions.setCurrentRoute(route))
        }
    }, [route])

    const getPageToRedirectAfterLogin = () => appRoutes.last || Routes.home

    return { getPageToRedirectAfterLogin }
}
