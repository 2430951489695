import MUISkeleton from '@mui/material/Skeleton'
import * as Styled from './SkeletonStyles'

export const Skeleton = ({
    numberOfLoadingLines,
    height = '24px',
    width = '100%'
}: {
    numberOfLoadingLines: number
    height?: string
    width?: string
}): JSX.Element => {
    return (
        <Styled.Container data-testid="skeleton">
            {[...Array(numberOfLoadingLines)].map((_, i) => (
                <MUISkeleton
                    key={i}
                    animation="wave"
                    style={{
                        height: height,
                        width: width
                    }}
                />
            ))}
        </Styled.Container>
    )
}
