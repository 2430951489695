const PHONES = {
    sac: {
        text: '(34) 3301-5818',
        number: '34 3301-5818'
    },
    televendas: {
        text: '0800 729 5296'
    }
}

export { PHONES }
