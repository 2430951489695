import { ThunkOptions } from '@store/types'
import { handleErrorResponse } from '@store/utils'
import { AppThunk } from '../store'
import {
    getAutocompletes,
    getPopularSearches,
    getSearchProducts
} from './services'
import {
    AutoCompleteQueriesType,
    GetAutocompletesPayload,
    GetProductsBySearchPayload,
    SearchAutoCompleteLinx,
    SearchData
} from './types'

export const getUpdatedSearchFilterListThunk =
    (
        payload: GetProductsBySearchPayload,
        useSolr = 'false',
        { onSuccess, onError }: ThunkOptions<SearchData | undefined> = {}
    ): AppThunk =>
    async () => {
        try {
            const searchResult = await getSearchProducts(
                {
                    ...payload,
                    resultsPerPage: 1
                },
                undefined,
                useSolr
            )
            onSuccess && onSuccess(searchResult)
        } catch (error: any) {
            handleErrorResponse(error, { onError })
        }
    }

export const getSearchAutocompletesThunk =
    (
        payload: GetAutocompletesPayload,
        { onSuccess, onError }: ThunkOptions<SearchAutoCompleteLinx>
    ): AppThunk =>
    async () => {
        try {
            const autoCompletes = await getAutocompletes(payload)
            onSuccess && onSuccess(autoCompletes)
        } catch (error: any) {
            handleErrorResponse(error, { onError })
        }
    }

export const getPopularSearchesThunk =
    ({
        onSuccess,
        onError
    }: ThunkOptions<AutoCompleteQueriesType[] | undefined>): AppThunk =>
    async () => {
        try {
            const popularSearches = await getPopularSearches()
            onSuccess && onSuccess(popularSearches.queries)
        } catch (error: any) {
            handleErrorResponse(error, { onError })
        }
    }
