import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import { Typography } from '@components/ui/Typography'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { Button as StyledButton } from '@components/ui/Button'

export const MenuHeaderContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.gray[900]};
`

export const StyledCloseIcon = styled(CloseIcon)`
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 20px;
    color: ${({ theme }) => theme.colors.white};
`

export const DivFullWidth = styled.div`
    width: 100%;
`

export const AccountContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export const Greeting = styled(Typography)<{ $showPointerCursor?: boolean }>`
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.white};
    cursor: ${props => (props.$showPointerCursor ? '' : 'pointer')};
`

export const SmallAccountIcon = styled(AccountCircle)`
    margin: 16px;
    font-size: 20px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
`

export const BigAccountIcon = styled(SmallAccountIcon)`
    font-size: 33px;
`

export const AccountButton = styled(StyledButton)`
    min-height: 32px;
    padding-left: 7px;
    padding-right: 7px;
    margin-right: 16px;
    font-family: ${({ theme }) => theme.fontFamily.sansSerif};
    font-weight: ${({ theme }) => theme.fontWeight.sansSerifRegular};
`

export const MenuHeaderButton = styled(StyledButton)`
    margin-left: 8px;
    padding-left: 8px;
    padding-right: 8px;
    white-space: nowrap;
    width: 33%;
    background-color: ${({ theme }) => theme.colors.gray[900]};

    :first-of-type {
        margin-left: 16px;
    }
    :last-of-type {
        width: 16%;
    }
`

export const MenuHeaderText = styled(Typography).attrs({
    size: 'sm',
    fontFamily: 'regular'
})`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
`
