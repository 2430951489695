import styled from 'styled-components'

export const LogoAlign = styled.div`
    cursor: pointer;

    &.checkout-header {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`
