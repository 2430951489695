import { ContentWithIcon } from '@components/ContentWithIcon'
import { menuThemes } from '@styles/menu-theme'
import * as Styled from './styles'
import { MenuItemProps } from './types'

export const MenuItemView = ({
    showBottomDivider = false,
    text,
    menuTheme = 'light',
    subItem,
    ...props
}: MenuItemProps): JSX.Element => {
    return (
        <Styled.MenuItemWrapper
            className={subItem ? 'subItem' : ''}
            $dividerColor={menuThemes[menuTheme].dividerColor}
            $showBottomDivider={showBottomDivider}
        >
            <ContentWithIcon
                arrowColor={menuThemes[menuTheme].arrowColor}
                {...props}
            >
                <Styled.Description color={menuThemes[menuTheme].textColor}>
                    {text}
                </Styled.Description>
            </ContentWithIcon>
        </Styled.MenuItemWrapper>
    )
}
