import { ApiError } from '@service/modules/error'

const isApiError = (error: any): error is ApiError => {
    return typeof error === 'object' && typeof error.detalhes === 'string'
}

export const getErrorMessage = (error: any): string => {
    const data = error.response?.data
    if (isApiError(data)) return data.detalhes
    return ''
}

export const getRedirectDestination = (error: any): string => {
    let redirectDestination = undefined
    try {
        const errorJson = JSON.parse(error.response.data.detalhesTecnicos)
        redirectDestination = errorJson.redirectDestination
    } catch (e) {
        // Não faz nada, se deu erro é porque não existe um json nos detalhes tecnicos
    }

    return redirectDestination
}
