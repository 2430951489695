import { ProductRatingScript } from './ProductRatingScript'

export const ProductRatingListScript = () => {
    return (
        <>
            <script type="text/javascript">
                var _trustvox_shelf_rate = _trustvox_shelf_rate || [];
                _trustvox_shelf_rate.push([`_storeId`,`545`]);
            </script>

            <ProductRatingScript />
        </>
    )
}
