import { pickObjectKeys } from '@utils/objectUtils'
import { removeDefaultsFromQuery } from '@utils/resultsContainer'
import { parseObjectToQueryString, parseQueryStringToObject } from '@utils/url'
import { DefaultSeoProps } from 'next-seo'
import { useRouter } from 'next/router'

const CURRENT_URL = process.env.NEXT_PUBLIC_CURRENT_SITE_URL ?? ''

/**
 * Retorna URL canonica e sem a interferência do Desktop
 * @param path - pathname da rota em tempo de build
 * @param getUrlWithQuery  - deixa a query pesquisada junto com a url
 *  * @returns URL sem UTM params
 */
export const getCanonical = (path: string, getUrlWithQuery?: boolean) => {
    try {
        if (CURRENT_URL) {
            const fullURL = new URL(path, CURRENT_URL)

            const query = getUrlWithQuery
                ? getCanonicalQuery(fullURL.search)
                : ''

            return `${fullURL.origin}${fullURL.pathname}${query}`.replace(
                '/desktop',
                ''
            )
        }
    } catch (err) {
        console.error(err)
    }
    return CURRENT_URL
}

/**
 * Retorna as configurações padrão de SEO
 */
export const getConfig = (): DefaultSeoProps => {
    const router = useRouter()
    const canonical = getCanonical(router.asPath)

    return {
        title: 'eFácil',
        titleTemplate: '%s - eFácil',
        description: 'eFácil',
        openGraph: {
            title: 'eFácil',
            description:
                'Ofertas incríveis, produtos de varejo alimentar | eFácil',
            type: 'website',
            locale: 'pt_BR',
            url: 'https://www.efacil.com.br',
            site_name: 'eFácil',
            images: [
                {
                    url: '/yourUrl.png',
                    width: 800,
                    height: 600,
                    alt: 'eFácil'
                }
            ]
        },
        twitter: {
            handle: '@efacil',
            site: '@efacil',
            cardType: 'images'
        },
        canonical
    }
}

const getCanonicalQuery = (query: string) =>
    parseObjectToQueryString(
        removeDefaultsFromQuery(
            pickObjectKeys(parseQueryStringToObject(query), [
                'page',
                'searchTerm'
            ])
        )
    )
