export const COPY_TO_CLIPBOARD_DISMISS_TIME = 3000

export const LOCALHOST_IPV4 = 'http://127.0.0.1'

export const EXTERNAL_LINKS = {
    siteCorreios:
        'https://buscacepinter.correios.com.br/app/endereco/index.php',
    trackingCorreios: 'https://rastreamento.correios.com.br/',
    ebit: 'https://www.ebit.com.br/857',
    camara: 'https://camara-e.net/site/conteudo/associados?menu_id=5',
    trustvox: 'https://certificados.trustvox.com.br/efacil',
    google: 'https://transparencyreport.google.com/safe-browsing/search?url=https:%2F%2Fwww.efacil.com.br',
    martins: 'https://martinsdistribuidor.com.br/',
    instagram: 'https://www.instagram.com/efaciloficial/',
    facebook: 'https://www.facebook.com/efacil',
    youtube: 'https://www.youtube.com/channel/UCtC65frOIOmrSsF5Z-2yzdQ',
    televendas_whatsapp_app:
        'https://api.whatsapp.com/send?phone=5508007295206&text=Ol%C3%A1%21',
    televendas_whatsapp_web:
        'https://api.whatsapp.com/send?phone=5508007295206',
    contato_whatsapp_app:
        'https://api.whatsapp.com/send?phone=553433015818&text=Ol%C3%A1%21',
    contato_whatsapp_web: 'https://api.whatsapp.com/send?phone=553433015818',
    simplifica: 'https://simplifica.efacil.com.br',
    linx: 'https://api.linximpulse.com/engage/search/',
    linxChaordics: 'https://recs.chaordicsystems.com/'
}

export const BLUR_DATA_URL =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN8Nl+1HgAGSAIrbhzqCQAAAABJRU5ErkJggg=='
