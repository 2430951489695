import {
    RecommendedProduct,
    Recommendation,
    ShowcasesData
} from '@store/modules/recommendations/types'
import { pickObjectKeys } from '@utils/objectUtils'

const getUsefulDataFromProducts = (
    products: RecommendedProduct[]
): RecommendedProduct[] =>
    products.map(product =>
        pickObjectKeys(product, [
            'idProduto',
            'sku',
            'nome',
            'quantidadeMax',
            'quantidadeMin',
            'quantidadeMult',
            'thumbnail',
            'url',
            'disponivel',
            'preco',
            'selo'
        ])
    )

// NOSONAR: TODO: Verificar necessidade desse método
const getUsefulDataFromRecommendations = (
    recommendations: Recommendation[]
): Recommendation[] => {
    if (!recommendations) return []

    return recommendations.map(recommendation => ({
        ...pickObjectKeys(recommendation, [
            'id',
            'title',
            'name',
            'feature',
            'imageUrl',
            'destinyUrl',
            'produtosTrackingUrl',
            'impressionUrl',
            'context'
        ]),
        produtos: getUsefulDataFromProducts(recommendation.produtos)
    }))
}

export const formatRecommendationsResponse = (
    recommendation: ShowcasesData
): ShowcasesData => {
    return {
        top: getUsefulDataFromRecommendations(recommendation?.top),
        middle: getUsefulDataFromRecommendations(recommendation?.middle),
        bottom: getUsefulDataFromRecommendations(recommendation?.bottom)
    }
}

export const checkRecommendations = (
    recommendationsLinx: ShowcasesData | null | undefined
): boolean => {
    return !!(
        recommendationsLinx?.top?.length ||
        recommendationsLinx?.middle?.length ||
        recommendationsLinx?.bottom?.length
    )
}
