import { RightSideMenuProps } from './view'

export const rightSideMenuIO = ({ categoriesData }: RightSideMenuProps) => {
    const showExtraCategories =
        categoriesData?.extra && categoriesData?.extra.length > 0

    return {
        showExtraCategories
    }
}

export type RightSideMenuIO = ReturnType<typeof rightSideMenuIO>
