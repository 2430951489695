import MUIIconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/icons/Menu'
import styled from 'styled-components'

export const MenuIcon = styled(Menu)`
    width: 32px;
    height: 32px;
    color: ${({ theme }) => theme.colors.white};
`

export const IconButton = styled(MUIIconButton)`
    padding: 0;
`
