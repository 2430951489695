const FOOTER_PHONES = {
    sac1: {
        text: '4004 5999',
        number: '4004 5999'
    },
    sac2: {
        text: '(34) 3301 5777',
        number: '34 3301-5777'
    },
    whatsapp: {
        text: '(34) 3301-5818'
    },
    ouvidoria: {
        text: '0800 722 1953'
    },
    televendas: {
        text: '0800 729 5206'
    }
}

export { FOOTER_PHONES }
