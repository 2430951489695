import { useDeviceContext } from '@contexts/device'
import * as Styled from './styles'

type ModalBodyType = {
    title: string
    content: string | React.ReactNode
    onClose: () => void
    closable?: boolean
}

export const ModalBody = ({
    title,
    content,
    onClose,
    closable = true
}: ModalBodyType): JSX.Element => {
    const { isDesktop } = useDeviceContext()
    return (
        <Styled.Container>
            <Styled.TitleContainer>
                <Styled.Title>{title}</Styled.Title>
                {closable && (
                    <Styled.CloseIcon
                        onClick={onClose}
                        data-testid="close-modal"
                    />
                )}
            </Styled.TitleContainer>
            {isDesktop && <Styled.HorizontalLine />}
            {typeof content === 'string' ? (
                <Styled.Description id="descricao_erro">
                    <Styled.DescriptionText>{content}</Styled.DescriptionText>
                </Styled.Description>
            ) : (
                content
            )}
        </Styled.Container>
    )
}
