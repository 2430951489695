export const TelevendedorIcon = () => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_19602_92085)">
                <path
                    d="M12.5 1C7.53 1 3.5 5.03 3.5 10V17C3.5 18.66 4.84 20 6.5 20H9.5V12H5.5V10C5.5 6.13 8.63 3 12.5 3C16.37 3 19.5 6.13 19.5 10V12H15.5V20H19.5V21H12.5V23H18.5C20.16 23 21.5 21.66 21.5 20V10C21.5 5.03 17.47 1 12.5 1Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_19602_92085">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
