import { CustomImage } from '@components/CustomImage'
import { CustomLink } from '@components/CustomLink'
import { BLUR_DATA_URL } from '@constants/common'
import { useDeviceContext } from '@contexts/device'
import { Routes } from '@service/routes'
import * as Styled from './styles'

type Assets = {
    Logo: string
}

type LogoProps = {
    assets: Assets
    layoutClassName: string
}

export const LogoView = ({
    assets,
    layoutClassName
}: LogoProps): JSX.Element => {
    const { isDesktop } = useDeviceContext()

    return (
        <Styled.LogoAlign className={layoutClassName}>
            <CustomLink href={Routes.home}>
                <CustomImage
                    layout="fixed"
                    src={assets.Logo}
                    alt="eFácil logo"
                    placeholder="blur"
                    width={isDesktop ? 132 : 96}
                    height={isDesktop ? 48 : 34}
                    blurDataURL={BLUR_DATA_URL}
                    priority
                />
            </CustomLink>
        </Styled.LogoAlign>
    )
}
