import { Typography } from '@components/ui/Typography'
import ButtonBase from '@material-ui/core/ButtonBase'
import styled from 'styled-components'

const ItemContainer = styled(ButtonBase)`
    flex-grow: 1;
    justify-content: flex-start;
    position: relative;
    display: flex;
    padding: 16px;
    cursor: pointer;
    width: 100%;
`

const IconContainer = styled.div`
    color: ${({ theme }) => theme.colors.secondary[500]};
    height: 24px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
`

type ChevronIconProps = { rotation?: string }
const ChevronContainer = styled.div<ChevronIconProps>`
    color: ${({ theme }) => theme.colors.gray[800]};
    transform: rotate(${({ rotation }) => rotation});
    transition: all 0.3s ease-out;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`
const Text = styled(Typography).attrs({
    size: 'sm',
    fontFamily: 'regular'
})`
    color: ${({ theme }) => theme.colors.gray[900]};
    flex-grow: 1;
    text-align: left;
`

export { ItemContainer, IconContainer, Text, ChevronContainer }
