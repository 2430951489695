import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthState } from './types'

const initialState: AuthState = {
    isAuthenticated: false,
    isTelevendedorAuthenticated: false,
    authError: false
} as const

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        showError(state) {
            state.authError = true
        },
        hideError(state) {
            state.authError = false
        },
        logoutUser(state) {
            state.isAuthenticated = false
        },
        _onLoginSuccess(state) {
            state.isAuthenticated = true
        },
        setTelevendedorAuthenticated(state, action: PayloadAction<boolean>) {
            state.isTelevendedorAuthenticated = action.payload
        }
    }
})

export const authActions = authSlice.actions

export default authSlice
