import { CustomLink } from '@components/CustomLink'
import { useDeviceContext } from '@contexts/device'
import { ClickAwayListener } from '@material-ui/core'
import { Routes } from '@service/routes'
import { ComponentBaseProps } from '@static/types'
import { formatThumbnail } from '@utils/product'
import { getURLSafe } from '@utils/url'
import { IOProps } from 'react-compose-io'
import { ProductSearch } from './components/ProductSearch'
import { TooltipBar } from './components/TooltipBar'
import { SearchBarIO } from './io'
import * as Styled from './styles'
import { QuerySearch } from './components/QuerySearch'

export const SearchBarView = ({
    _io
}: IOProps<SearchBarIO, ComponentBaseProps>): JSX.Element => {
    const { isDesktop } = useDeviceContext()
    return (
        <ClickAwayListener onClickAway={_io.handleClickAway}>
            <Styled.Container id="input_searchbar">
                <Styled.StyledInputBase
                    value={_io.searchTerm}
                    inputProps={{
                        'aria-label': 'search',
                        enterKeyHint: 'search'
                    }}
                    onKeyDown={_io.handleOnKeyDown}
                    onChange={e => {
                        _io.setSearchTerm(e.target.value)
                        _io.setShowTooltip(true)
                    }}
                    onFocus={() => _io.setShowTooltip(true)}
                    placeholder={
                        isDesktop
                            ? 'Digite o produto, marca ou modelo'
                            : 'Estou buscando…'
                    }
                    startAdornment={
                        !isDesktop && (
                            <CustomLink
                                href={{
                                    pathname: Routes.search,
                                    query: { searchTerm: _io.searchTerm.trim() }
                                }}
                            >
                                <Styled.SearchIcon />
                            </CustomLink>
                        )
                    }
                    endAdornment={
                        isDesktop && (
                            <CustomLink
                                href={{
                                    pathname: Routes.search,
                                    query: { searchTerm: _io.searchTerm.trim() }
                                }}
                            >
                                <Styled.SearchIcon />
                            </CustomLink>
                        )
                    }
                />

                {_io.showTooltip &&
                    _io.searchTerm &&
                    _io.hasAutocompleteProduct && (
                        <TooltipBar $width={_io.containerWidth}>
                            {_io.queries?.map((query, index) => (
                                <QuerySearch
                                    key={index}
                                    query={query.query}
                                    link={query.link}
                                />
                            ))}

                            {_io.products.length > 0 && (
                                <Styled.ProductsContainer>
                                    <Styled.Title>
                                        Produtos Sugeridos
                                    </Styled.Title>

                                    {_io.products.map((product, index) => (
                                        <>
                                            <ProductSearch
                                                key={index}
                                                name={product.name}
                                                clickUrl={
                                                    product.clickUrl ?? ''
                                                }
                                                thumbnail={formatThumbnail(
                                                    product.images?.default ||
                                                        ''
                                                )}
                                                priceFrom={
                                                    String(product.oldPrice) ??
                                                    ''
                                                }
                                                currentPrice={
                                                    String(product.price) ?? ''
                                                }
                                                url={
                                                    getURLSafe(
                                                        product.url ?? ''
                                                    ).pathname
                                                }
                                                sku={
                                                    product.collectInfo
                                                        ?.productId ?? ''
                                                }
                                            />
                                            <input
                                                id={
                                                    'click-ac-btn_sku' +
                                                    product.collectInfo
                                                        ?.productId
                                                }
                                                type="hidden"
                                                value={
                                                    isDesktop
                                                        ? product.clickUrl +
                                                          '&source=desktop'
                                                        : product.clickUrl +
                                                          '&source=mobile'
                                                }
                                            />
                                        </>
                                    ))}
                                </Styled.ProductsContainer>
                            )}
                            <input
                                id="ac-search-id"
                                type="hidden"
                                value={_io.searchId}
                            />
                        </TooltipBar>
                    )}
            </Styled.Container>
        </ClickAwayListener>
    )
}
