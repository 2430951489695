import { GenericLargeErrorIcon } from '@assets/svg/GenericLargeErrorIcon'
import { AdaptiveContainer } from '@components/AdaptiveContainer'
import { CustomLink } from '@components/CustomLink'
import { Layout } from '@components/Layout'
import { CompleteHeader } from '@components/Layout/components/header/CompleteHeader'
import { SimpleHeader } from '@components/Layout/components/header/SimpleHeader'
import { Stack } from '@components/Stack'
import { ProductRatingListScript } from '@components/trustvox/ProductRatingListScript'
import { Typography } from '@components/ui/Typography'
import { useDeviceContext } from '@contexts/device'
import { Routes } from '@service/routes'
import { themeStyled } from '@theme/theme'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { IOProps } from 'react-compose-io'
import { GenericErrorIcon } from '../../assets/svg/GenericErrorIcon'
import { StaticErrorIO } from './io'
import * as Styled from './styles'
import { ErrorType, StaticErrorProps } from './types'

const ProductShowcase = dynamic(
    () =>
        import('@components/product/ProductShowcase').then(
            module => module.ProductShowcase
        ),
    { ssr: false }
)

export const StaticErrorView = ({
    _io,
    staticRecommendations,
    errorType
}: IOProps<StaticErrorIO, StaticErrorProps>): JSX.Element => {
    const { isDesktop } = useDeviceContext()

    return (
        <>
            <Head>
                <ProductRatingListScript />
                <meta name="robots" content="noindex,nofollow" />
            </Head>
            <Layout
                removeFloatingElementPadding
                navbarComponent={
                    errorType === ErrorType.CLIENT ? (
                        <SimpleHeader />
                    ) : (
                        <CompleteHeader />
                    )
                }
                contentTopPadding="lg"
            >
                <AdaptiveContainer direction="column" spacing={48}>
                    <Stack justifyContent="center" direction="row">
                        <Styled.TitleContainer>
                            <Stack
                                direction="row"
                                spacing={16}
                                justifyContent="center"
                            >
                                {isDesktop ? (
                                    <GenericLargeErrorIcon />
                                ) : (
                                    <GenericErrorIcon />
                                )}
                                <Typography
                                    size={isDesktop ? 'xl6' : 'xl5'}
                                    fontFamily="bold"
                                    textAlign="center"
                                    color={themeStyled.colors.gray[400]}
                                >
                                    Ooops...
                                </Typography>
                            </Stack>
                            <Typography
                                size="lg"
                                fontFamily="regular"
                                textAlign="center"
                                color={themeStyled.colors.gray[500]}
                            >
                                {_io.description}
                            </Typography>
                            <CustomLink href={Routes.home}>
                                <Styled.BackToStartButton
                                    fullWidth
                                    onClick={() => {
                                        errorType === ErrorType.CLIENT &&
                                            _io.resetBoundary()
                                    }}
                                >
                                    Voltar para a página inicial
                                </Styled.BackToStartButton>
                            </CustomLink>
                        </Styled.TitleContainer>
                    </Stack>
                    <ProductShowcase
                        recommendations={staticRecommendations?.top}
                    />
                    <ProductShowcase
                        recommendations={staticRecommendations?.middle}
                    />
                    <ProductShowcase
                        recommendations={staticRecommendations?.bottom}
                    />
                </AdaptiveContainer>
            </Layout>
        </>
    )
}
