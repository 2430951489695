import { AdaptiveContainer } from '@components/AdaptiveContainer'
import { DESKTOP_SIZE } from '@constants/device'
import Menu from '@material-ui/icons/Menu'
import styled from 'styled-components'

export const MenuIcon = styled(Menu)`
    width: 32px;
    height: 32px;
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
`

export const Container = styled(AdaptiveContainer).attrs({
    spacing: 48
})`
    width: ${DESKTOP_SIZE}px;
    height: 412px;
`
