import { isStaticOrServerSide } from '@utils/common'
import { MouseEvent, useEffect, useState } from 'react'
import { ExpansibleMenuItemProps } from './view'

export const expansibleMenuItemIO = ({
    onClick,
    initialOpen = false
}: ExpansibleMenuItemProps) => {
    const [open, setOpen] = useState(initialOpen)

    const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setOpen(!open)
        onClick && onClick(e)
    }

    useEffect(() => {
        if (!isStaticOrServerSide()) setOpen(false)
    }, [])

    return {
        open,
        handleOnClick
    }
}

export type ExpansibleMenuItemIO = ReturnType<typeof expansibleMenuItemIO>
