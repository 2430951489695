import { useLoading } from '@contexts/loading'
import { Routes } from '@service/routes'
import { logoutCommonUserThunk } from '@store/modules/auth/thunk'
import { useAppSelector } from '@store/modules/store'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import { MenuHeaderProps } from './view'

export const menuHeaderIO = ({ onClose }: MenuHeaderProps) => {
    const router = useRouter()
    const { showLoading, hideLoading } = useLoading()
    const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated)
    const dispatch = useDispatch()

    const navigateToPage = (page: string) => {
        onClose()
        router.push(page)
    }

    const handleLogout = () => {
        showLoading()
        dispatch(
            logoutCommonUserThunk({
                onSuccess: () => {
                    router.replace(Routes.home)
                    hideLoading()
                },
                onError: () => {
                    hideLoading()
                }
            })
        )
    }

    return {
        navigateToPage,
        isAuthenticated,
        handleLogout
    }
}

export type MenuHeaderIO = ReturnType<typeof menuHeaderIO>
