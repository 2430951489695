import { Category } from '@store/modules/department/types'
import { MenuTheme } from '@styles/menu-theme'
import { DepartmentMenuItem } from '../menu/DepartmentMenuItem'
import dynamic from 'next/dynamic'

const ExpansibleMenuItem = dynamic(
    () =>
        import('../menu/ExpansibleMenuItem').then(
            module => module.ExpansibleMenuItem
        ),
    { ssr: true }
)

type CategoryListProps = {
    categories: Category[] | undefined
    menuTheme: MenuTheme
    initialOpen?: boolean
    onSelection?: () => void
}

export const CategoryListView = ({
    categories,
    menuTheme,
    initialOpen,
    onSelection
}: CategoryListProps): JSX.Element => {
    return (
        <>
            {categories?.map((category, index) => {
                if (
                    !category.subcategorias ||
                    category.subcategorias.length === 0
                )
                    return (
                        <DepartmentMenuItem
                            key={category.idCategoria}
                            link={category.link}
                            onClick={onSelection}
                            text={category.nome}
                            menuTheme={menuTheme}
                            arrowDirection="right"
                            showBottomDivider
                        />
                    )

                return (
                    <ExpansibleMenuItem
                        key={category.idCategoria}
                        text={category.nome}
                        menuTheme={menuTheme}
                        showBottomDivider={index < categories.length - 1}
                        initialOpen={initialOpen}
                    >
                        {category.subcategorias?.map(subcategory => (
                            <DepartmentMenuItem
                                key={subcategory.idCategoria}
                                link={subcategory.link}
                                onClick={onSelection}
                                text={subcategory.nome}
                                menuTheme={menuTheme}
                                arrowDirection="right"
                                className="subItem"
                                showBottomDivider
                                subItem
                            />
                        ))}
                    </ExpansibleMenuItem>
                )
            })}
        </>
    )
}
