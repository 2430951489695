import { isStaticOrServerSide } from '@utils/common'
import { getURLSafe } from '@utils/url'
import { AxiosRequestConfig } from 'axios'
import { parseCookies } from 'nookies'

interface StoreChannelConfig {
    channel?: string
    storeId?: string
}

export enum BrowserQueryParam {
    CHANNEL = 'canal'
}

export enum StorageKey {
    CHANNEL = 'UserSegment',
    STORE_ID = 'StoreId'
}

export const getStoreIdAndChannelForRequest = (): StoreChannelConfig => {
    if (isStaticOrServerSide()) {
        return defaultStoreIdAndChannel()
    } else {
        return getStoreIdAndChannelByPriority()
    }
}

export const defaultStoreIdAndChannel = (): Required<StoreChannelConfig> => {
    if (
        !process.env.NEXT_PUBLIC_DEFAULT_CHANNEL ||
        !process.env.NEXT_PUBLIC_DEFAULT_CHANNEL_TELEVENDAS ||
        !process.env.NEXT_PUBLIC_DEFAULT_STORE_ID
    ) {
        throw new Error('Undefined default channel or store id in environment.')
    }

    const cookies = parseCookies()

    return {
        channel:
            cookies['acessoRestrito'] === 'true'
                ? process.env.NEXT_PUBLIC_DEFAULT_CHANNEL_TELEVENDAS
                : process.env.NEXT_PUBLIC_DEFAULT_CHANNEL,
        storeId: process.env.NEXT_PUBLIC_DEFAULT_STORE_ID
    }
}

const getStoreIdAndChannelByPriority = (): StoreChannelConfig => {
    const urlChannel = getChannelFromUrl()

    const { storeId: cookieStoreId, channel: cookieChannel } =
        getStoreIdAndChannelFromCookies() || {}

    const { storeId: defaultStoreId, channel: defaultChannel } =
        defaultStoreIdAndChannel()

    return {
        channel: urlChannel || cookieChannel || defaultChannel,
        storeId: cookieStoreId || defaultStoreId
    }
}

export const getChannelFromUrl = (): string | undefined | null => {
    if (isStaticOrServerSide()) return

    const { searchParams } = getURLSafe(window.location.href)
    return searchParams.get(BrowserQueryParam.CHANNEL)
}

const getStoreIdAndChannelFromCookies = (): StoreChannelConfig | undefined => {
    if (isStaticOrServerSide()) return

    const cookies = parseCookies()
    return {
        channel: cookies[StorageKey.CHANNEL] || '',
        storeId: cookies[StorageKey.STORE_ID] || ''
    }
}

export const getChannelFromRequest = (
    config: AxiosRequestConfig
): string | undefined => {
    return config.params?.canal
}

export const getChannelFromCookies = () => {
    const cookies = parseCookies()
    return cookies['UserSegment']
}
