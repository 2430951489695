import styled from 'styled-components'

export const Container = styled.div<{ $width: number }>`
    z-index: 2;
    width: ${({ $width }) => $width + 'px'};
    height: 100%;
    position: absolute;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
`

export const Arrow = styled.div`
    width: 18px;
    height: 8px;
    margin-left: 38px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    background: ${({ theme }) => theme.colors.white};
`

export const OptionsContainer = styled.div`
    width: 100%;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.white};
`
