import { Stack } from '@components/Stack'
import { Typography } from '@components/ui/Typography'
import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    white-space: pre-wrap;
`

export const SubmitButtonContainer = styled.div`
    width: 100%;
    button {
        width: 100%;
    }
`
export const SubmitButtonContent = styled(Typography).attrs(({ theme }) => ({
    size: theme.isDesktop ? 'sm' : 'xs'
}))``

export const ButtonContainer = styled(Stack).attrs(({ theme }) => ({
    direction: theme.isDesktop ? 'row-reverse' : 'column',
    justifyContent: 'space-between',
    fullWidth: true,
    spacing: theme.isDesktop ? 32 : 16
}))`
    ${({ theme }) =>
        !theme.isDesktop && 'padding: 0 16px 16px;margin-top: 24px;'}
`
