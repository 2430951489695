import { themeStyled } from '@theme/theme'

export const menuThemes = Object.freeze({
    light: {
        textColor: themeStyled.colors.gray[800],
        arrowColor: themeStyled.colors.gray[800],
        dividerColor: themeStyled.colors.gray[50]
    },
    dark: {
        textColor: themeStyled.colors.white,
        arrowColor: themeStyled.colors.primary[500],
        dividerColor: themeStyled.colors.gray[700]
    },
    blue: {
        textColor: themeStyled.colors.secondary[500],
        arrowColor: themeStyled.colors.gray[800],
        dividerColor: themeStyled.colors.gray[50]
    }
})

export type MenuTheme = keyof typeof menuThemes
