import { DefaultErrorThunkOptions, ThunkOptions } from '@store/types'
import { handleErrorResponse } from '@store/utils'
import { cartActions } from '../cart/slice'
import { checkoutActions } from '../checkout/slice'
import { AppThunk, persistor } from '../store'
import { userActions } from '../user/slice'
import {
    postAuth,
    postAuthTelevendedor,
    postAuthUserAsTelevendedor,
    postLogout,
    postLogoutTelevendedor,
    postPreLogin,
    postPreLoginTelevendedor,
    refreshCookies
} from './services'
import { authActions } from './slice'
import { Credentials, LoginPayload, PreLoginReturn } from './types'

export const preLoginThunk =
    (
        credentials: Credentials,
        { onSuccess, onError }: ThunkOptions<PreLoginReturn>,
        params?: { resetPassword?: boolean; paymentTypeRedirect?: string }
    ): AppThunk =>
    async () => {
        try {
            const data = await postPreLogin(
                credentials,
                params?.resetPassword,
                params?.paymentTypeRedirect
            )
            onSuccess && onSuccess(data)
        } catch (error: any) {
            handleErrorResponse(error, {
                onError,
                visibility: 'always'
            })
        }
    }

export const preLoginTelevendedorThunk =
    (
        credentials: Credentials,
        { onSuccess, onError }: ThunkOptions<void>
    ): AppThunk =>
    async () => {
        try {
            await postPreLoginTelevendedor(credentials)
            onSuccess && onSuccess()
        } catch (error: any) {
            handleErrorResponse(error, {
                onError,
                visibility: 'always'
            })
        }
    }

export const loginThunk =
    (
        credentials: LoginPayload,
        { onSuccess, onError }: DefaultErrorThunkOptions<boolean>,
        params?: { paymentTypeRedirect?: string }
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            const loginResponse = getState().auth.isTelevendedorAuthenticated
                ? await postAuthUserAsTelevendedor(credentials)
                : await postAuth(credentials, params?.paymentTypeRedirect)
            dispatch(authActions._onLoginSuccess())
            dispatch(checkoutActions.clearCheckout())
            onSuccess && onSuccess(loginResponse.alterarSenha)
        } catch (error: any) {
            handleErrorResponse(error, {
                onError,
                visibility: 'always'
            })
        }
    }

export const loginTelevendedorThunk =
    (
        credentials: LoginPayload,
        { onSuccess, onError }: DefaultErrorThunkOptions<void>
    ): AppThunk =>
    async dispatch => {
        try {
            await postAuthTelevendedor(credentials)
            dispatch(authActions.setTelevendedorAuthenticated(true))
            dispatch(checkoutActions.clearCheckout())

            onSuccess && onSuccess()
        } catch (error: any) {
            handleErrorResponse(error, {
                onError,
                visibility: 'always'
            })
        }
    }

export const logoutCommonUserThunk =
    ({ onSuccess, onError }: ThunkOptions = {}): AppThunk =>
    async dispatch => {
        try {
            await postLogout()
            dispatch(
                clearSessionThunk({
                    onSuccess,
                    onError
                })
            )
        } catch (error: any) {
            handleErrorResponse(error, { onError })
        }
    }

export const logoutTelevendedorThunk =
    ({ onSuccess, onError }: ThunkOptions): AppThunk =>
    async dispatch => {
        try {
            await postLogoutTelevendedor()
            dispatch(authActions.setTelevendedorAuthenticated(false))

            dispatch(clearSessionThunk({ onSuccess, onError }))
        } catch (error: any) {
            handleErrorResponse(error, { onError })
        }
    }

export const clearSessionThunk =
    ({ onSuccess, onError }: ThunkOptions = {}): AppThunk =>
    async (dispatch, getState) => {
        try {
            const televendedorExists =
                getState().auth.isTelevendedorAuthenticated
            dispatch(cartActions.clearCartData())
            dispatch(checkoutActions.clearCheckout())
            dispatch(authActions.logoutUser())
            if (!televendedorExists) {
                dispatch(userActions.clearUserData())
                await persistor.flush()
                await persistor.purge()
            }

            onSuccess && onSuccess()
        } catch (error: any) {
            handleErrorResponse(error, { onError })
        }
    }

export const refreshCookiesThunk =
    ({ onSuccess, onError }: ThunkOptions): AppThunk =>
    async () => {
        try {
            await refreshCookies()
            onSuccess && onSuccess()
        } catch (error: any) {
            console.error('Error starting page.')
            handleErrorResponse(error, { onError, visibility: 'never' })
        }
    }
