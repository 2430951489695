import { Collapse } from '@material-ui/core'
import { MenuTheme } from '@styles/menu-theme'
import { IOProps } from 'react-compose-io'
import { MenuItem } from '../MenuItem'
import { MenuItemProps } from '../MenuItem/types'
import { ExpansibleMenuItemIO } from './io'
import * as Styled from './styles'

export type ExpansibleMenuItemProps = {
    children?: any
    reverse?: boolean
    menuTheme: MenuTheme
    initialOpen?: boolean
} & Omit<MenuItemProps, 'arrowDirection'>

export const ExpansibleMenuItemView = ({
    children,
    reverse,
    menuTheme,
    showBottomDivider,
    _io,
    ...menuItemProps
}: IOProps<ExpansibleMenuItemIO, ExpansibleMenuItemProps>): JSX.Element => {
    return (
        <Styled.Container reverse={reverse} data-testid="menuItemContainer">
            <MenuItem
                onClick={_io.handleOnClick}
                arrowDirection={_io.open ? 'up' : 'down'}
                menuTheme={menuTheme}
                showBottomDivider={Boolean(showBottomDivider) && !_io.open}
                {...menuItemProps}
            />
            <Collapse in={_io.open} data-testid="colapseContainer">
                {children}
            </Collapse>
        </Styled.Container>
    )
}
