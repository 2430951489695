import { BaseProductData } from '@store/modules/product/types'
import { ProductInCart } from '../cart/types'

export type StampImageProps = {
    nome?: string
    url?: string
}

export type RecommendedProduct = {
    trackingUrl?: string
} & BaseProductData

export type Recommendation = {
    produtos: RecommendedProduct[]
    id: string
    title: string
    name: string
    feature: string
    imageUrl: string[]
    destinyUrl: string
    produtosTrackingUrl: { [key: string]: string }
    impressionUrl: string
    context: string
}

export type ShowcasesData = {
    top: Recommendation[]
    middle: Recommendation[]
    bottom: Recommendation[]
}

export enum RecommendationsNames {
    CART = 'cart',
    CATEGORY = 'category',
    PRODUCT = 'product',
    HOME = 'home',
    SEARCH = 'search',
    NOT_FOUND = 'not_found'
}

export type GetRecommendationsPayload = RecommendationsPayload & {
    deviceId?: string
    source?: string
    userId?: string
}

export type RecommendationsThunkPayload = RecommendationsPayload & {
    desktop: boolean | undefined
}

export type RecommendationsPayload = {
    name: RecommendationsNames
    categoryId?: string | string[]
    productId?: string | string[]
    cartItens?: ProductInCart[]
}
