import { CookieProvider } from '@contexts/cookie'
import { DeviceProvider } from '@contexts/device'
import { DialogProvider } from '@contexts/dialog'
import { LoadingProvider } from '@contexts/loading'
import { TrustvoxProvider } from '@contexts/trustvox'
import { StaticError } from '@features/error'
import { ErrorType } from '@features/error/types'
import { useGtmCommunication } from '@hooks/dataLayer/useGtmCommunication'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { DefaultHead } from '@seo/DefaultHead'
import { persistor, store } from '@store/modules/store'
import '@styles/tailwind.css'
import { ThemeProvider } from '@theme/index'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

const Noop = ({ children }: { children: React.ReactNode }): JSX.Element => (
    <>{children}</>
)

export default function App({
    Component,
    pageProps,
    router
}: AppProps): JSX.Element {
    const Layout = (Component as any).Layout || Noop
    const nextRouter = useRouter()

    useGtmCommunication()

    useMemo(() => {
        nextRouter.prefetch = async () => {
            return void 0
        }
    }, [nextRouter])

    useEffect(() => {
        const appInsightsKey =
            process.env.NEXT_PUBLIC_APPINSIGHTS_INSTRUMENTATIONKEY_CLIENT
        if (appInsightsKey) {
            const appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: appInsightsKey
                }
            })
            appInsights.trackPageView()

            // Chamada manual ao trackPageView para obter o usuário, sessão e pageView
            appInsights.loadAppInsights()
        }
    }, [])

    return (
        <>
            <DefaultHead pathname={router.pathname} />
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    {() => (
                        <ThemeProvider {...pageProps}>
                            <DeviceProvider {...pageProps}>
                                <DialogProvider>
                                    <LoadingProvider>
                                        <CookieProvider>
                                            <TrustvoxProvider>
                                                <ErrorBoundary
                                                    FallbackComponent={() => (
                                                        <StaticError
                                                            errorType={
                                                                ErrorType.CLIENT
                                                            }
                                                            staticRecommendations={
                                                                null
                                                            }
                                                        ></StaticError>
                                                    )}
                                                    onError={error =>
                                                        console.error(
                                                            'Client Side Error thrown with error: ',
                                                            error
                                                        )
                                                    }
                                                >
                                                    <Layout
                                                        pageProps={pageProps}
                                                        showFooter={false}
                                                    >
                                                        <Component
                                                            {...pageProps}
                                                        />
                                                    </Layout>
                                                </ErrorBoundary>
                                            </TrustvoxProvider>
                                        </CookieProvider>
                                    </LoadingProvider>
                                </DialogProvider>
                            </DeviceProvider>
                        </ThemeProvider>
                    )}
                </PersistGate>
            </Provider>
        </>
    )
}
