import styled from 'styled-components'
import MUIBadge from '@material-ui/core/Badge'

export const BadgeContainer = styled(MUIBadge)`
    cursor: pointer;

    .MuiBadge-badge {
        margin-top: 29px;
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.secondary[500]};
    }
`

export const CartLinkContainer = styled.div`
    > a {
        height: 30px;
        width: 34px;
    }
`
