import { Logo } from '../../Logo'
import * as Styled from './styles'

const assets = {
    Logo: '/img/navbarlogo_white.png'
}

export const SimpleHeaderView = (): JSX.Element => {
    return (
        <Styled.HeaderContainer
            fullWidth
            alignItems="center"
            justifyContent="center"
        >
            <Logo assets={assets} layoutClassName="simpleHeader" />
        </Styled.HeaderContainer>
    )
}
