import { ButtonProps as MUIButtonProps } from '@material-ui/core'
import { Override } from '@utils/Typescript/customOperators'
import { ForwardRefExoticComponent, forwardRef } from 'react'
import {
    ButtonIconContent,
    IconContentProps
} from './components/IconContent/ButtonIconContent'
import * as Styled from './styles'

export type ButtonProps = Override<
    MUIButtonProps,
    {
        $size?: 'small' | 'medium'
        color: Styled.ColorsNameType
    }
> &
    Partial<IconContentProps>

export const ButtonView: ForwardRefExoticComponent<ButtonProps> = forwardRef(
    (
        {
            children,
            color,
            icon,
            iconPosition,
            variant = 'contained',
            $size = 'medium',
            ...props
        },
        ref
    ): JSX.Element => {
        return (
            <Styled.Button
                variant={variant}
                disableElevation
                $size={$size}
                {...props}
                $colorName={color}
                ref={ref}
            >
                {icon ? (
                    <ButtonIconContent icon={icon} iconPosition={iconPosition}>
                        {children}
                    </ButtonIconContent>
                ) : (
                    children
                )}
            </Styled.Button>
        )
    }
)
