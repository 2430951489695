import api from '@service/index'
import { CANAL_TELEVENDEDOR } from '@store/types'
import { Credentials, LoginPayload, PreLoginReturn } from './types'

export const postPreLogin = async (
    loginData: Credentials,
    resetPassword?: boolean,
    paymentTypeRedirect?: string
) => {
    return api
        .post<PreLoginReturn>('users/auth/prelogin', loginData, {
            params: {
                rePass: resetPassword,
                paymentTypeRedirect: paymentTypeRedirect
            }
        })
        .then(res => res.data)
}

export const postPreLoginTelevendedor = async (loginData: Credentials) => {
    return api
        .post<PreLoginReturn>('users/auth/prelogin', loginData, {
            params: {
                acessoRestrito: true,
                canal: CANAL_TELEVENDEDOR
            }
        })
        .then(res => res.data)
}

export const postAuthTelevendedor = async (loginData: LoginPayload) => {
    return api
        .post('users/auth', loginData, {
            params: {
                acessoRestrito: true
            }
        })
        .then(res => res.data)
}

export const postAuth = async (
    loginData: LoginPayload,
    paymentTypeRedirect?: string
) => {
    return api
        .post('users/auth', loginData, {
            params: {
                paymentTypeRedirect
            }
        })
        .then(res => res.data)
}

export const postAuthUserAsTelevendedor = async (loginData: LoginPayload) => {
    return api.post('users/auth/televendas', loginData).then(res => res.data)
}

export const postLogout = async () => {
    return api.post(`users/auth/logout`, null).then(res => res.data)
}

export const postLogoutTelevendedor = async () => {
    return api
        .post(`users/auth/logout`, null, {
            params: {
                acessoRestrito: true,
                canal: CANAL_TELEVENDEDOR
            }
        })
        .then(res => res.data)
}

export const refreshCookies = async (): Promise<void> => {
    /*
    Observações importantes:
    Esta chamada é feita no mesmo padrão da sensedia, porém é feito um rewrite e proxy para chamar diretamente o WCS
    - localhost:
        No arquivo next.config.js existe um rewrite para esta chamada
        ex: /wcs/resources/10154/urls/context -> /loja/API-URLsGetContext
    - outros ambientes: 
        No arquivo ConfigMapNginx.yaml existe um location com rewrite e proxy feitos no nginx
        ex: /wcs/resources/10154/urls/context -> /loja/API-URLsGetContext
        No CDN (Azion), também existe uma regra de rewrite similar 
    */
    await api.get('urls/context')
}
