import { PropsWithChildren, createContext, useContext, useState } from 'react'

type LoadingContextData = {
    isLoading: boolean
    showLoading: () => void
    hideLoading: () => void
}

export const LoadingContext = createContext<LoadingContextData | null>({
    isLoading: false,
    showLoading: () => null,
    hideLoading: () => null
})
LoadingContext.displayName = 'LoadingContext'

export const LoadingProvider = ({ children }: PropsWithChildren) => {
    const [isLoading, setIsLoading] = useState(false)

    const showLoading = () => setIsLoading(true)
    const hideLoading = () => setIsLoading(false)

    return (
        <LoadingContext.Provider
            value={{ isLoading, showLoading, hideLoading }}
        >
            {children}
        </LoadingContext.Provider>
    )
}
export const useLoading = (): LoadingContextData => {
    return useContext(LoadingContext) as LoadingContextData
}
