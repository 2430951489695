import { MANDATORY_PASSWORD_CHANGE } from '@constants/dialog'
import { useLoading } from '@contexts/loading'
import { useGtmEventLoadedEffect } from '@hooks/dataLayer/useGtmEventLoadedEffect'
import useDialog from '@hooks/dialog/useDialog'
import { useLoadedEffect } from '@hooks/useLoadedEffect'
import { useLoginRedirection } from '@hooks/useLoginRedirection'
import { Routes } from '@service/routes'
import { appActions } from '@store/modules/app/slice'
import { authActions } from '@store/modules/auth/slice'
import { clearSessionThunk } from '@store/modules/auth/thunk'
import { useAppDispatch, useAppSelector } from '@store/modules/store'
import { updatePersonalizationsThunk } from '@store/modules/user/thunk'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import {
    checkIfTheUserIsNotAuthenticatedInCookies,
    shouldValidateUserSession
} from './utils'
import { LayoutProps } from './view'

export const layoutIO = ({
    floatingElement,
    removeFloatingElementPadding
}: LayoutProps) => {
    const [paddingBottom, setPaddingBottom] = useState(0)
    const appState = useAppSelector(state => state.app)
    const authError = useAppSelector(state => state.auth.authError)
    const isTelevendedorAuthenticated = useAppSelector(
        state => state.auth.isTelevendedorAuthenticated
    )
    const fixedContainerRef = useRef<HTMLDivElement>(null)
    const headerRef = useRef<HTMLHeadElement>(null)
    const { showErrorDialog, showConfirmationDialog, showInfoDialog } =
        useDialog()
    const dispatch = useAppDispatch()
    const router = useRouter()
    const { isLoading, hideLoading } = useLoading()

    useLoginRedirection()

    /**
     * Este useEffect serve para:
     * 1. Validar se a sessão do usuário expirou
     * 2. Persistir o canal informado na rota (se houver)
     * 3. Atualizar dados do cabeçalho (bagde carrinho e nome)
     */
    // NOSONAR TODO: Separar essas validações de sessão (tirar do layout). Uma sugestão foi cria um componente ValidadeSession
    useLoadedEffect(() => {
        if (
            checkIfTheUserIsNotAuthenticatedInCookies(
                isTelevendedorAuthenticated
            )
        )
            dispatch(clearSessionThunk())
        const validateUserSession = () => {
            dispatch(updatePersonalizationsThunk())
        }

        if (shouldValidateUserSession()) validateUserSession()
    }, [])

    useEffect(() => {
        if (!removeFloatingElementPadding)
            setPaddingBottom(fixedContainerRef.current?.clientHeight || 0)
        else setPaddingBottom(0)
    }, [floatingElement, removeFloatingElementPadding])

    // NOSONAR TODO: Melhorar a forma de tratar o erro diferenciando se é global ou local.
    useEffect(() => {
        if (appState.error) {
            if (appState.error.includes(MANDATORY_PASSWORD_CHANGE)) {
                showInfoDialog({
                    title: 'Ops...',
                    content: appState.error,
                    confirmationButtonText: 'Entendi',
                    onConfirm: () => {
                        dispatch(appActions.hideError())
                    }
                })
            } else {
                showErrorDialog(
                    {
                        content: appState.error,
                        onConfirm: () => dispatch(appActions.hideError())
                    },
                    { closable: false }
                )
            }
        }
    }, [appState.error])

    useEffect(() => {
        if (authError) {
            hideLoading()
            showConfirmationDialog(
                {
                    title: 'Sessão expirada...',
                    content:
                        'A sua sessão expirou. Para continuar, tente fazer o login novamente.',
                    confirmationButtonText: 'Continuar',
                    cancelButtonText: 'Ir para o início',
                    onConfirm: () => {
                        dispatch(authActions.hideError())
                        router.replace(Routes.login)
                    },
                    onClose: () => {
                        dispatch(authActions.hideError())
                        router.replace(Routes.home)
                    },
                    variant: 'verticalButtons'
                },
                { closable: false }
            )
        }
    }, [authError])

    useGtmEventLoadedEffect()

    return {
        paddingBottom,
        loading: isLoading,
        fixedContainerRef,
        headerRef
    }
}

export type LayoutIO = ReturnType<typeof layoutIO>
