import api from '@service/index'
import { EXTERNAL_LINKS } from '@constants/common'
import axios, { RawAxiosRequestHeaders } from 'axios'
import { formatRecommendationsResponse } from '@utils/recommendation'
import { GetRecommendationsPayload, ShowcasesData } from './types'

export const getRecommendations = async ({
    name,
    source,
    deviceId,
    categoryId,
    userId,
    productId,
    cartItens
}: GetRecommendationsPayload) => {
    // NOSONAR Considerar Render no Server e no Client.
    const baseParams = {
        name,
        source,
        deviceId: deviceId || '',
        ...(userId && { userId: userId }),
        ...(categoryId && { categoryId: categoryId }),
        ...(productId && { productId: productId })
    }

    if (name == 'cart' && cartItens) {
        const productIdList: string[] = []
        const categoryIdList: string[] = []

        cartItens.forEach(cartItem => {
            productIdList.push(cartItem.sku.toString())
            categoryIdList.push(cartItem.idCategoria?.toString() ?? '')
        })

        baseParams.productId = productIdList
        baseParams.categoryId = categoryIdList
    }

    const params =
        process.env.NEXT_PUBLIC_SHOW_DUMMY_RECOMMENDATIONS !== 'true'
            ? baseParams
            : {
                  ...baseParams,
                  homologation: true,
                  dummy: true
              }

    return api
        .get<ShowcasesData>(`catalog/pages/recommendations`, {
            params
        })
        .then(res => formatRecommendationsResponse(res.data))
}

export const sendImpressionEvent = async (impressionUrl: string) => {
    fetch(impressionUrl, { method: 'GET', keepalive: true })
}

export const getRecommendationsFromLinx = async (
    {
        name,
        source,
        categoryId,
        productId,
        deviceId,
        userId
    }: GetRecommendationsPayload,
    headers?: RawAxiosRequestHeaders
) => {
    const isLocalhost =
        window.location.hostname === 'localhost'
            ? process.env.LINX_API_SECRETKEY
            : ''

    const baseParams = {
        name,
        source,
        deviceId: deviceId || '',
        secretKey: isLocalhost,
        ...(userId && { userId: userId }),
        ...(categoryId && { categoryId: categoryId }),
        ...(productId && { productId: productId })
    }

    const customHeader = {
        ...headers,
        origin: process.env.NEXT_PUBLIC_CURRENT_SITE_URL
    }
    return axios
        .get<ShowcasesData>(
            `${EXTERNAL_LINKS.linxChaordics}v0/pages/recommendations`,
            {
                params: {
                    ...baseParams,
                    apiKey: process.env.NEXT_PUBLIC_LINX_API_KEY
                },
                headers: customHeader
            }
        )
        .then(res => res.data)
}
