import { RightSideMenu } from '../../RightSideMenu'
import { MenuButtonProps } from '../types'
import * as Styled from './styles'

export const MenuButtonMobileView = ({
    isSideMenuOpen,
    categoriesData,
    personalizations,
    onToggleMenu
}: MenuButtonProps): JSX.Element => {
    return (
        <>
            <Styled.IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => onToggleMenu(true)}
            >
                <Styled.MenuIcon />
            </Styled.IconButton>

            <RightSideMenu
                open={isSideMenuOpen}
                userName={personalizations}
                categoriesData={categoriesData}
                onClose={() => onToggleMenu(false)}
            />
        </>
    )
}
