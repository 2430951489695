import styled from 'styled-components'

type StyledProps = {
    direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
    spacing?: number
    justifyContent?:
        | 'flex-start'
        | 'center'
        | 'flex-end'
        | 'space-between'
        | 'space-around'
        | 'space-evenly'

    alignItems?: 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'stretch'
    fullWidth?: boolean
}

const getMarginLeft = ({ direction, spacing }: StyledProps) => {
    if (direction === 'row' && spacing) return `${spacing}px`
}

const getMarginTop = ({ direction, spacing }: StyledProps) => {
    if ((!direction || direction === 'column') && spacing) return `${spacing}px`
}

const getMarginRight = ({ direction, spacing }: StyledProps) => {
    if (direction === 'row-reverse' && spacing) return `${spacing}px`
}

const getMarginBottom = ({ direction, spacing }: StyledProps) => {
    if (direction === 'column-reverse' && spacing) return `${spacing}px`
}

const StackContainer = styled.div<StyledProps>`
    display: flex;
    flex-direction: ${({ direction }) => direction || 'column'};
    justify-content: ${({ justifyContent }) => justifyContent};
    align-items: ${({ alignItems }) => alignItems};
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

    > * + * {
        margin-left: ${props => getMarginLeft(props)};
        margin-top: ${props => getMarginTop(props)};
        margin-right: ${props => getMarginRight(props)};
        margin-bottom: ${props => getMarginBottom(props)};
    }
`

export { StackContainer }
export type { StyledProps }
