import { useDeviceContext } from '@contexts/device'
import { CategoriesBasePageProps } from '@static/types'
import { CompleteHeaderDesktop } from './desktop'
import { CompleteHeaderMobile } from './mobile'

export const CompleteHeader = (props: CategoriesBasePageProps) => {
    const { isDesktop } = useDeviceContext()

    return isDesktop ? (
        <CompleteHeaderDesktop {...props} />
    ) : (
        <CompleteHeaderMobile {...props} />
    )
}
