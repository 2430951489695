import { Stack } from '@components/Stack'
import styled from 'styled-components'

export const HeaderContainer = styled(Stack)`
    padding: 16px 0;
    background-color: ${({ theme }) =>
        process.env.NEXT_PUBLIC_IS_BLACK_FRIDAY === 'true'
            ? theme.colors.dark[1000]
            : theme.colors.primary[500]};
    height: 80px;
`
