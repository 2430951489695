import { useDeviceContext } from '@contexts/device'
import { useAppSelector } from '@store/modules/store'
import { userActions } from '@store/modules/user/slice'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

export const cookiesMessageModalIO = () => {
    const [showModal, setShowModal] = useState(false)
    const userAcceptedCookies = useAppSelector(
        state => state.user.acceptedCookies
    )
    const { isDesktop } = useDeviceContext()
    const dispatch = useDispatch()

    const handleOnButtonClick = () => {
        setShowModal(false)
        dispatch(userActions.setAcceptedCookies())
    }

    useEffect(() => {
        if (!userAcceptedCookies) setShowModal(true)
    }, [userAcceptedCookies])

    return { showModal, userAcceptedCookies, isDesktop, handleOnButtonClick }
}

export type CookiesMessageModalIO = ReturnType<typeof cookiesMessageModalIO>
