import { ArrowIconProps } from './types'

export const ArrowDownIcon = ({ disabled = false }: ArrowIconProps) => (
    <svg
        width="10"
        height="7"
        viewBox="0 0 10 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.175 0.158447L5 3.97511L8.825 0.158447L10 1.33345L5 6.33345L0 1.33345L1.175 0.158447Z"
            fill={disabled ? '#c4c4c4' : '#FFF'}
        />
    </svg>
)
