import { getURLSafe } from '@utils/url'

const getUrlWithStoreIdAndTrailingSlash = (url = '', storeId = '') => {
    return getUrlWithTrailingSlash(getUrlWithStoreId(url, storeId))
}

const getUrlWithTrailingSlash = (url: string) => {
    const { pathname, search } = getURLSafe(url)

    const updatedPathname =
        pathname.slice(-1) !== '/' ? pathname + '/' : pathname

    return updatedPathname + search
}

const getUrlWithStoreId = (url: string, storeId: string) => `${storeId}/${url}`

export { getUrlWithStoreIdAndTrailingSlash }
