import { useAppSelector } from '@store/modules/store'
import { LoginLinkProps } from './view'

export const loginLinkIO = ({ userName }: LoginLinkProps) => {
    const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated)

    const fullName = () => {
        const userNameArray = userName.split(' ')
        return userNameArray[0] + ' ' + userNameArray[1]
    }
    return {
        isAuthenticated,
        fullName
    }
}

export type MenuHeaderIO = ReturnType<typeof loginLinkIO>
