import { useRouter } from 'next/router'
import { useEffect } from 'react'
import packageData from '../../../package.json'

export function useGtmCommunication() {
    const router = useRouter()

    const sendNextEffectEvent = () => {
        const typedWindow = window as any
        typedWindow.dataLayer = typedWindow.dataLayer || []

        typedWindow.dataLayer.push({
            event: 'next.effect',
            app_name: packageData.name,
            app_version: packageData.version,
            url: window.location.href
        })
    }

    useEffect(() => {
        // Envia evento next.effect em navegações Web
        sendNextEffectEvent()

        // Envia evento next.effect em navegações SPA
        router.events.on('routeChangeComplete', sendNextEffectEvent)

        return () => {
            router.events.off('routeChangeComplete', sendNextEffectEvent)
        }
    }, [])
}
