import { Button } from '@components/ui/Button'
import { ConfirmationModalButtonProps } from '../../types'
import * as Styled from './ConfirmationModalVerticalButtonsStyles'

export const ConfirmationModalVerticalButtons = ({
    handleClose,
    handleSubmit,
    cancelButtonText,
    confirmationButtonText
}: ConfirmationModalButtonProps): JSX.Element => {
    return (
        <Styled.ButtonsContainer>
            <Button color="primary" size="small" onClick={handleSubmit}>
                {confirmationButtonText}
            </Button>
            <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={handleClose}
            >
                {cancelButtonText}
            </Button>
        </Styled.ButtonsContainer>
    )
}
