import * as Styled from './styles'
import { MenuHeaderIO } from './io'
import { Routes } from '@service/routes'
import { Stack } from '@components/Stack'
import { IOProps } from 'react-compose-io'
import FavoriteIcon from '@material-ui/icons/Favorite'
import LogoutIcon from '@mui/icons-material/Logout'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import { CustomLink } from '@components/CustomLink'
import { ErrorBoundary } from 'react-error-boundary'

export type MenuHeaderProps = {
    userName?: string
    onClose: () => void
}

export const MenuHeaderView = ({
    _io,
    onClose,
    userName
}: IOProps<MenuHeaderIO, MenuHeaderProps>): JSX.Element => {
    return (
        <ErrorBoundary
            fallback={<></>}
            onError={error => console.error('Client Side Error:', error)}
        >
            <Styled.MenuHeaderContainer>
                {_io.isAuthenticated && userName ? (
                    <Styled.DivFullWidth>
                        <Stack
                            fullWidth
                            direction="row"
                            justifyContent="space-between"
                        >
                            <Stack direction="row" alignItems="center">
                                <Styled.BigAccountIcon />

                                <Styled.Greeting size="sm" fontFamily="regular">
                                    {`Olá, ${userName}`}
                                </Styled.Greeting>
                            </Stack>

                            <Styled.AccountContent>
                                <Styled.AccountButton
                                    color="primary"
                                    variant="outlined"
                                    onClick={() =>
                                        _io.navigateToPage(Routes.myAccount)
                                    }
                                >
                                    Minha conta
                                </Styled.AccountButton>

                                <Styled.StyledCloseIcon onClick={onClose} />
                            </Styled.AccountContent>
                        </Stack>
                        <Styled.MenuHeaderButton
                            color="white"
                            variant="text"
                            startIcon={<LocalShippingIcon />}
                            onClick={() => _io.navigateToPage(Routes.orders)}
                        >
                            <Styled.MenuHeaderText>
                                Meus pedidos
                            </Styled.MenuHeaderText>
                        </Styled.MenuHeaderButton>

                        <Styled.MenuHeaderButton
                            color="white"
                            variant="text"
                            onClick={() => _io.navigateToPage(Routes.favorite)}
                            startIcon={<FavoriteIcon />}
                        >
                            <Styled.MenuHeaderText>
                                Favoritos
                            </Styled.MenuHeaderText>
                        </Styled.MenuHeaderButton>

                        <Styled.MenuHeaderButton
                            color="white"
                            variant="text"
                            onClick={_io.handleLogout}
                            startIcon={<LogoutIcon />}
                        >
                            <Styled.MenuHeaderText>Sair</Styled.MenuHeaderText>
                        </Styled.MenuHeaderButton>
                    </Styled.DivFullWidth>
                ) : (
                    <Stack
                        fullWidth
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <CustomLink href={Routes.login}>
                            <Stack direction="row" alignItems="center">
                                <Styled.SmallAccountIcon />

                                <Styled.Greeting
                                    size="sm"
                                    $showPointerCursor
                                    fontFamily="regular"
                                >
                                    Entre ou cadastre-se
                                </Styled.Greeting>
                            </Stack>
                        </CustomLink>
                        <Styled.StyledCloseIcon onClick={onClose} />
                    </Stack>
                )}
            </Styled.MenuHeaderContainer>
        </ErrorBoundary>
    )
}
