import { Stack } from '@components/Stack'
import styled from 'styled-components'

export const HeaderContainer = styled(Stack)`
    padding: 16px;
    background-color: ${({ theme }) =>
        process.env.NEXT_PUBLIC_IS_BLACK_FRIDAY === 'true'
            ? theme.colors.dark[1000]
            : theme.colors.primary[400]};
`

export const FixedContainer = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 500;
`

export const FavoritesAndCartContainer = styled(Stack).attrs({
    direction: 'row',
    spacing: 32,
    justifyContent: 'center',
    alignItems: 'center'
})``
