import { ModalBody } from '../ModalBody/view'
import { ConfirmationModalHorizontalButtons } from './components/HorizontalButtons'
import { ConfirmationModalVerticalButtons } from './components/VerticalButtons'
import * as Styled from './ConfirmationModalStyles'
import { ConfirmationModalButtonProps, ConfirmationModalVariant } from './types'

type ConfirmationModalType = {
    title: string
    content: string | React.ReactNode
    closable?: boolean
    variant?: ConfirmationModalVariant
} & ConfirmationModalButtonProps

export const ConfirmationModal = ({
    variant = 'horizontalButtons',
    ...rest
}: ConfirmationModalType): JSX.Element => {
    const { title, content, handleClose, closable } = rest
    return (
        <Styled.Container>
            <ModalBody
                title={title}
                content={content}
                onClose={handleClose}
                closable={closable}
            />

            {variant === 'horizontalButtons' ? (
                <ConfirmationModalHorizontalButtons {...rest} />
            ) : (
                <ConfirmationModalVerticalButtons {...rest} />
            )}
        </Styled.Container>
    )
}
