export const Chevron = () => (
    <svg
        width="10"
        height="7"
        viewBox="0 0 10 7"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.06 6.05994L5 2.11994L8.94 6.05994L10 4.99994L5 -6.4373e-05L0 4.99994L1.06 6.05994Z"
            fill="currentColor"
        />
    </svg>
)
