import { HeaderFavoriteIcon } from '@assets/svg/HeaderFavoriteIcon'
import { CustomLink } from '@components/CustomLink'
import { Routes } from '@service/routes'
import * as Styled from './styles'

type FavoritesLinkProps = {
    setRouteToFavorites: () => Promise<void>
}

export const FavoritesLinkView = ({
    setRouteToFavorites
}: FavoritesLinkProps): JSX.Element => {
    return (
        <Styled.FavoriteIconContainer onClick={setRouteToFavorites}>
            <CustomLink href={Routes.favorite}>
                <HeaderFavoriteIcon />
            </CustomLink>
        </Styled.FavoriteIconContainer>
    )
}
