import { Button } from '@components/ui/Button'
import { ColorsNameType } from '@components/ui/Button/styles'
import { ModalBody } from '../ModalBody/view'
import * as Styled from './styles'

type InfoModalType = {
    title: string
    description: string | React.ReactNode
    confirmationButtonText?: string
    confirmationButtonColor?: ColorsNameType
    alternativeButtonText?: string
    alternativeButtonColor?: ColorsNameType
    handleSubmit: () => void
    handleAlternative?: () => void
    handleClose: () => void
    closable?: boolean
    buttonDisabled?: boolean
}

export const InfoModal = ({
    title,
    description,
    confirmationButtonText,
    confirmationButtonColor,
    alternativeButtonColor,
    alternativeButtonText,
    handleAlternative,
    handleSubmit,
    handleClose,
    closable,
    buttonDisabled
}: InfoModalType): JSX.Element => {
    return (
        <Styled.Container data-testid="info-modal">
            <ModalBody
                title={title}
                content={description}
                onClose={handleClose}
                closable={closable}
            />

            <Styled.ButtonContainer>
                {confirmationButtonText && (
                    <Styled.SubmitButtonContainer>
                        <Button
                            color={confirmationButtonColor || 'primary'}
                            $size="medium"
                            onClick={handleSubmit}
                            disabled={buttonDisabled}
                        >
                            <Styled.SubmitButtonContent>
                                {confirmationButtonText}
                            </Styled.SubmitButtonContent>
                        </Button>
                    </Styled.SubmitButtonContainer>
                )}

                {alternativeButtonText && (
                    <Styled.SubmitButtonContainer>
                        <Button
                            color={alternativeButtonColor || 'primary'}
                            $size="medium"
                            variant="outlined"
                            onClick={handleAlternative}
                            disabled={buttonDisabled}
                        >
                            <Styled.SubmitButtonContent>
                                {alternativeButtonText}
                            </Styled.SubmitButtonContent>
                        </Button>
                    </Styled.SubmitButtonContainer>
                )}
            </Styled.ButtonContainer>
        </Styled.Container>
    )
}
