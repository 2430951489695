import { useLoading } from '@contexts/loading'
import useDialog from '@hooks/dialog/useDialog'
import { Routes } from '@service/routes'
import { authActions } from '@store/modules/auth/slice'
import { refreshCookiesThunk } from '@store/modules/auth/thunk'
import { useRouter } from 'next/router'
import {
    PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useState
} from 'react'
import { useDispatch } from 'react-redux'

type CookieContextData = {
    hasLoaded: ContextCode
}

export const CookieContext = createContext<CookieContextData>({
    hasLoaded: 0
})
CookieContext.displayName = 'CookieContext'

export enum ContextCode {
    'CookieNotLoaded' = 0,
    'CookieLoaded' = 1,
    'SessionExpired' = 2
}

const getFlagValue = () => {
    if (typeof window !== 'undefined') {
        try {
            return JSON.parse(
                localStorage.getItem('efacilContextHasLoaded') ??
                    `${ContextCode.CookieNotLoaded}`
            )
        } catch (err) {
            console.error('Erro no parse do efacilContextHasLoaded: ', err)
            return ContextCode.CookieNotLoaded
        }
    } else {
        return ContextCode.CookieNotLoaded
    }
}

export const CookieProvider = ({ children }: PropsWithChildren) => {
    const router = useRouter()
    const dispatch = useDispatch()
    const [hasLoaded, setHasLoaded] = useState<ContextCode>(
        ContextCode.CookieNotLoaded
    )

    const { showConfirmationDialog } = useDialog()
    const { hideLoading } = useLoading()

    const setHasLoadedCookie = (value: ContextCode) => {
        localStorage.setItem('efacilContextHasLoaded', String(value))
        setHasLoaded(value)
    }

    const handleRouteChangeStart = () => {
        setHasLoadedCookie(ContextCode.CookieNotLoaded)
    }

    const handleLoadCookie = (cookieLoaded: ContextCode) => {
        if (
            cookieLoaded === ContextCode.CookieNotLoaded &&
            !window.location.pathname.match(Routes.resetPassword)
        ) {
            dispatch(
                refreshCookiesThunk({
                    onSuccess: () => {
                        setHasLoadedCookie(ContextCode.CookieLoaded)
                    },
                    onError: () => {
                        hideLoading()
                        showSessionExpiredDialog()
                    }
                })
            )
        }
    }

    const handleRouteChangeComplete = () => {
        const cookieLoaded = getFlagValue()
        handleLoadCookie(cookieLoaded)
    }

    const showSessionExpiredDialog = () => {
        showConfirmationDialog(
            {
                title: 'Sessão expirada...',
                content:
                    'A sua sessão expirou. Para continuar, tente fazer o login novamente.',
                confirmationButtonText: 'Continuar',
                cancelButtonText: 'Ir para o início',
                onConfirm: () => {
                    dispatch(authActions.hideError())
                    router.replace(Routes.login)
                },
                onClose: () => {
                    dispatch(authActions.hideError())
                    router.replace(Routes.home)
                },
                variant: 'verticalButtons'
            },
            { closable: false }
        )
    }

    useEffect(() => {
        const cookieLoaded = getFlagValue()
        setHasLoaded(cookieLoaded)

        if (cookieLoaded === ContextCode.SessionExpired) {
            hideLoading()
            showSessionExpiredDialog()
        }

        handleLoadCookie(cookieLoaded)

        router.events.on('routeChangeStart', handleRouteChangeStart)
        router.events.on('routeChangeComplete', handleRouteChangeComplete)

        return () => {
            router.events.off('routeChangeStart', handleRouteChangeStart)
            router.events.off('routeChangeComplete', handleRouteChangeComplete)
        }
    }, [])

    return (
        <CookieContext.Provider value={{ hasLoaded }}>
            {children}
        </CookieContext.Provider>
    )
}

export const useCookieContext = (): CookieContextData => {
    return useContext(CookieContext)
}
