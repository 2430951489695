import { PhoneIcon } from '@assets/svg/PhoneIcon'
import { CustomPopover } from '@components/Layout/components/CustomPopover'
import { ColoredSvg } from '@components/ui/ColoredSvg'
import { themeStyled } from '@theme/theme'
import { PopoverContent } from './components/PopoverContent'
import * as Styled from './styles'

export const ContactsView = () => {
    return (
        <CustomPopover
            buttonContent={
                <Styled.ButtonContainer>
                    <ColoredSvg
                        svg={<PhoneIcon width={16} height={16} />}
                        color={themeStyled.colors.white}
                    />

                    <Styled.TextMessage>Fale com eFácil</Styled.TextMessage>
                </Styled.ButtonContainer>
            }
            popoverContent={<PopoverContent />}
        />
    )
}
