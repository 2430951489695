import { Typography } from '@components/ui/Typography'
import CloseIconComponent from '@material-ui/icons/Close'
import styled from 'styled-components'

export const Container = styled.div``

export const TitleContainer = styled.div`
    margin: 0;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${({ theme }) => !theme.isDesktop && 'padding: 0 16px;'}
`

export const Title = styled(Typography).attrs(({ theme }) => ({
    size: theme.isDesktop ? 'xl2' : 'xl',
    fontFamily: theme.isDesktop ? 'medium' : 'regular'
}))`
    color: ${({ theme }) => theme.colors.gray[900]};
`

export const CloseIcon = styled(CloseIconComponent)`
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors.gray[900]};
    cursor: pointer;
`

export const Description = styled.div`
    ${({ theme }) => !theme.isDesktop && 'padding: 0 16px;'}
`

export const DescriptionText = styled(Typography).attrs(({ theme }) => ({
    size: 'base',
    variant: 'paragraph',
    fontFamily: 'regular',
    color: theme.isDesktop ? theme.colors.gray[500] : theme.colors.gray[900]
}))`
    margin-bottom: ${({ theme }) => (theme.isDesktop ? '32px;' : '16px;')};
`

export const HorizontalLine = styled.div`
    height: 1px;
    width: 500px;
    margin: 24px auto;
    background: ${({ theme }) => theme.colors.gray[50]};
`
