import React, {
    createContext,
    PropsWithChildren,
    ReactNode,
    useContext,
    useRef,
    useState
} from 'react'
import { ContextDialog } from './components/ContextDialog'

export type DialogTimerConfigType = {
    autoClose?: boolean
    autoCloseTime?: number
}

export type DialogConfigType = DialogTimerConfigType & {
    closable?: boolean
}

const DEFAULT_DIALOG_CONFIG = Object.freeze({
    autoClose: false,
    autoCloseTime: 3000,
    closable: true
})

type DialogContextType = {
    showContextDialog: (
        dialogContent: ReactNode,
        config?: Partial<DialogTimerConfigType>
    ) => Promise<void>
    hideContextDialog: (reason: 'cancel' | 'confirm') => void
}

export const DialogContext = createContext<DialogContextType | null>(null)
DialogContext.displayName = 'DialogContext'

export const DialogProvider = ({ children }: PropsWithChildren) => {
    const [isDialogVisible, setIsDialogVisible] = useState(false)
    const [content, setContent] = useState<ReactNode>()
    const [timerId, setTimerId] = useState<number>()
    const [closable, setClosable] = useState<boolean>()

    const promiseRef = useRef({
        resolve: () => {
            return
        },
        reject: () => {
            return
        }
    })

    const showContextDialog = async (
        dialogContent: ReactNode,
        config?: Partial<DialogConfigType>
    ): Promise<void> => {
        setContent(dialogContent)

        const completeConfig = { ...DEFAULT_DIALOG_CONFIG, ...config }
        setClosable(completeConfig.closable)

        const { autoClose, autoCloseTime } = completeConfig

        handleCloseTimer({ autoClose, autoCloseTime })
        return new Promise((resolve, reject) => {
            promiseRef.current = {
                resolve,
                reject
            }
            setIsDialogVisible(true)
        })
    }

    const handleCloseTimer = ({
        autoClose,
        autoCloseTime
    }: DialogTimerConfigType) => {
        if (autoClose) {
            if (typeof timerId !== 'undefined') {
                clearTimeout(timerId)
                setTimerId(undefined)
            }

            const timer = setTimeout(() => {
                handleOnClose('confirm')
            }, autoCloseTime)

            setTimerId(Number(timer))
        }
    }

    const handleOnClose = (reason: 'cancel' | 'confirm') => {
        setIsDialogVisible(false)
        reason === 'confirm'
            ? promiseRef.current.resolve()
            : promiseRef.current.reject()
    }

    const handleBackdropClick = (_: never, reason: string) => {
        if (!closable && reason && reason === 'backdropClick') return

        handleOnClose('cancel')
    }

    return (
        <DialogContext.Provider
            value={{ showContextDialog, hideContextDialog: handleOnClose }}
        >
            {children}
            <ContextDialog
                open={Boolean(isDialogVisible)}
                onClose={handleBackdropClick}
            >
                {content}
            </ContextDialog>
        </DialogContext.Provider>
    )
}

export const useDialogContext = (): DialogContextType => {
    return useContext(DialogContext) as DialogContextType
}
