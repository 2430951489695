import { getAllowedSortByOptions } from '@utils/resultsContainer'
import { SearchData } from './types'

export const formatDepartmentResponse = (data: SearchData | undefined) => {
    if (!data) return undefined

    return {
        ...data,
        sort: getAllowedSortByOptions(data?.sort || [])
    }
}
