import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProductToAddToFavoritesListAfterLogin } from './types'

const initialState: ProductToAddToFavoritesListAfterLogin = {
    idProductToAddToFavoritesListAfterLogin: undefined
}

const favoritesSlice = createSlice({
    name: 'favorites',
    initialState,
    reducers: {
        _clearFavoriteData() {
            return initialState
        },
        _saveProductToAddToFavoriteListAfterLogin(
            state,
            action: PayloadAction<ProductToAddToFavoritesListAfterLogin>
        ) {
            state.idProductToAddToFavoritesListAfterLogin =
                action.payload.idProductToAddToFavoritesListAfterLogin
        }
    }
})

export const favoritesActions = favoritesSlice.actions

export default favoritesSlice
