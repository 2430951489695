import { useLoading } from '@contexts/loading'
import { Routes } from '@service/routes'
import { logoutCommonUserThunk } from '@store/modules/auth/thunk'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'

export const loggedInUserIO = () => {
    const router = useRouter()
    const { showLoading, hideLoading } = useLoading()
    const dispatch = useDispatch()

    const handleLogout = () => {
        showLoading()
        dispatch(
            logoutCommonUserThunk({
                onSuccess: () => {
                    router.replace(Routes.home)
                    hideLoading()
                },
                onError: () => {
                    hideLoading()
                }
            })
        )
    }

    return {
        handleLogout
    }
}

export type LoggedInUserIO = ReturnType<typeof loggedInUserIO>
