import { TypographyVariants } from './types'

export const typographyIO = () => {
    const getVariant = (variant: TypographyVariants) => {
        if (variant === 'span') return 'caption'
        if (variant === 'paragraph') return 'body2'
        return variant
    }

    return {
        getVariant
    }
}

export type TypographyIO = ReturnType<typeof typographyIO>
