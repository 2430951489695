import { getSearchAutocompletesThunk } from '@store/modules/search/thunk'
import {
    AutoCompleteQueriesType,
    ProductLinx
} from '@store/modules/search/types'
import { useAppDispatch, useAppSelector } from '@store/modules/store'
import { isClientSide } from '@utils/common'
import { useEffect, useState } from 'react'

export const searchBarIO = () => {
    const dispatch = useAppDispatch()
    const [searchTerm, setSearchTerm] = useState('')
    const [showTooltip, setShowTooltip] = useState(true)
    const [products, setProducts] = useState<ProductLinx[]>([])
    const [queries, setQueries] = useState<AutoCompleteQueriesType[]>([])
    const [searchId, setSearchId] = useState('')

    const userId = useAppSelector(state => state.user.userId)
    const deviceId = useAppSelector(state => state.user.deviceId)

    useEffect(() => {
        if (searchTerm.length > 3) {
            const timeOutId = setTimeout(() => getAutocompletes(), 300)

            return () => clearTimeout(timeOutId)
        }
    }, [searchTerm])

    const getAutocompletes = () => {
        dispatch(
            getSearchAutocompletesThunk(
                { terms: searchTerm, deviceId: deviceId, userId: userId ?? '' },
                {
                    onSuccess: ({ products, queries, searchId }) => {
                        setProducts(products || [])
                        setQueries(queries ?? [])
                        setSearchId(searchId)
                    }
                }
            )
        )
    }

    const newSearchWithNoSpaces = () => {
        const newSearchTerm = searchTerm.trim().replace(/\s/g, '+')
        return `/loja/busca/?searchTerm=` + newSearchTerm
    }

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            window.location.href = newSearchWithNoSpaces()
        }

        if (e.key === 'Escape') return setShowTooltip(false)
    }

    const hasAutocompleteProduct = Boolean(products.length > 0)

    const handleClickAway = () => {
        setShowTooltip(false)
    }

    return {
        products,
        queries,
        searchTerm,
        showTooltip,
        searchId,
        setSearchTerm,
        setShowTooltip,
        handleOnKeyDown,
        handleClickAway,
        hasAutocompleteProduct,
        containerWidth: isClientSide()
            ? document.getElementById('input_searchbar')?.clientWidth
            : 570
    }
}

export type SearchBarIO = ReturnType<typeof searchBarIO>
