import { CartLink } from '@components/Layout/components/CartLink'
import { Logo } from '@components/Layout/components/Logo'
import { SearchBar } from '@components/Layout/components/SearchBar'
import { CategoriesBasePageProps } from '@static/types'
import { IOProps } from 'react-compose-io'
import { Contacts } from '../../components/Contacts'
import { FavoritesLink } from '../../components/FavoritesLink'
import { LoggedInTelevendedor } from '../../components/LoggedInTelevendedor'
import { LoginLink } from '../../components/LoginLink'
import { MenuBar } from '../../components/MenuBar'
import { CompleteHeaderIO } from '../io'
import * as Styled from './styles'
import { ErrorBoundary } from 'react-error-boundary'
import { SimpleHeader } from '../../SimpleHeader'

export const CompleteHeaderDesktopView = ({
    categoriesData,
    _io
}: IOProps<CompleteHeaderIO, CategoriesBasePageProps>): JSX.Element => {
    return (
        <ErrorBoundary
            fallback={<SimpleHeader></SimpleHeader>}
            onError={error => console.error('Client Side Error:', error)}
        >
            <Styled.Container>
                <Styled.MaxWidth>
                    <Logo
                        assets={_io.assets}
                        layoutClassName="CompleteHeader"
                    />
                    <SearchBar />
                    <ErrorBoundary
                        fallback={<></>}
                        onError={error =>
                            console.error('Client Side Error:', error)
                        }
                    >
                        <LoginLink userName={_io.userNameClient} />
                        {_io.isTelevendedor ? (
                            <LoggedInTelevendedor
                                userName={_io.userNameTelevendedor}
                                isTelevendedor={_io.isTelevendedor}
                            />
                        ) : (
                            <Contacts />
                        )}
                    </ErrorBoundary>
                    <Styled.FavoritesAndCartContainer>
                        <FavoritesLink
                            setRouteToFavorites={_io.setRouteToFavorites}
                        />
                        <CartLink
                            numberOfItemsInCart={_io.numberOfItemsInCart}
                        />
                    </Styled.FavoritesAndCartContainer>
                </Styled.MaxWidth>
            </Styled.Container>
            <MenuBar
                categoriesData={categoriesData}
                isSideMenuOpen={_io.isSideMenuOpen}
                onToggleMenu={_io.setIsSideMenuOpen}
                hide={_io.hideComponent}
            />
        </ErrorBoundary>
    )
}
