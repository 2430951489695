import { composeIO } from 'react-compose-io'
import { completeHeaderIO } from '../io'
import { CompleteHeaderDesktopView } from './view'
import { withErrorBoundary } from 'react-error-boundary'
import { SimpleHeader } from '../../SimpleHeader'

export const CompleteHeaderDesktop = withErrorBoundary(
    composeIO(CompleteHeaderDesktopView, completeHeaderIO),
    {
        fallback: <SimpleHeader></SimpleHeader>
    }
)
