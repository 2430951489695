import styled from 'styled-components'

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    margin: ${({ theme }) => (theme.isDesktop ? '0' : '16px')};

    button:nth-child(2n) {
        margin-left: 16px;
    }
`

export { ButtonsContainer }
