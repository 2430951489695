import { Stack } from '@components/Stack'
import styled from 'styled-components'

export const FavoriteIconContainer = styled(Stack).attrs({
    justifyContent: 'center',
    alignItems: 'center'
})`
    > a {
        line-height: 0;
    }
`
