import { Routes } from '@service/routes'
import { ProductService } from '@store/modules/cart/types'
import { DetailedProductPrice } from '@store/modules/product/types'
import { removeCurrency } from '@utils/currency'
import { getNonEmptyPathParts } from '@utils/url'

export const formatWarrantyQuantity = (quantity: number): string =>
    quantity !== 1 ? `${quantity} incluídas` : `${quantity} incluída`

export const getFirstSelectedService = (
    services: ProductService[]
): ProductService | undefined =>
    services.find(service => service.quantidade !== 0)

export const getSelectedServiceText = (services: ProductService[]): string => {
    let finalText: string

    const selectedService = getFirstSelectedService(services)

    if (!selectedService) finalText = 'Adicionar garantia estendida'
    else {
        if (selectedService.nome.includes('12 meses'))
            finalText = 'Garantia de 1 ano selecionada'
        else if (selectedService.nome.includes('24 meses'))
            finalText = 'Garantia de 2 anos selecionada'
        else return selectedService.nome

        finalText += ` (${formatWarrantyQuantity(selectedService.quantidade)})`
    }

    return finalText
}

export const getFormattedServiceName = (serviceName: string) => {
    if (serviceName.includes('12 meses')) return 'Garantia estendida 1 ano'
    else if (serviceName.includes('24 meses'))
        return 'Garantia estendida 2 anos'
    else return serviceName
}

export const formatServiceList = (
    services: ProductService[]
): ProductService[] => {
    return services.map(service => ({
        ...service,
        nome: getFormattedServiceName(service.nome)
    }))
}

/**
 * Esta função serve para retornar a url relativa e
 * padronizar o link dos produtos, trazendo ganhos
 * de performance e evitando duplicação de cache.
 *
 * Alguns serviços (ex.: obtenção de dados da categoria)
 * retornam links de produtos diferentes para o mesmo
 * produto nas chamadas Server Side e Client Side.
 * Padronizando, evita-se cachear duas vezes a
 * mesma página.
 */
export const formatProductRelativeUrl = (url: string): string => {
    const urlParts = getNonEmptyPathParts(url)
    return `${Routes.productDetails}${urlParts[urlParts.length - 1]}/`
}

/**
 * NÃO UTILIZAR EM SSR (getStaticProps, getStaticPaths)
 */
export const formatProductFullUrl = (url: string) => {
    const path = formatProductRelativeUrl(url)

    const origin = window.location.origin

    return `${origin}${path}`
}

export const formatPriceToPtBr = (price: string) => {
    const numericalPrice = getPriceValueOrEmptyString(price)

    return Number(numericalPrice).toLocaleString('pt-br', {
        minimumFractionDigits: 2
    })
}

export const formatPriceStringToHaveOnePoint = (price: string) => {
    return price.replace('.', '').replace(',', '.').trim()
}

export const replaceCommaToPoint = (price: string) => {
    return price.replace(',', '.')
}

export const convertPriceWithCommaToPoint = (price: string) => {
    return Number(replaceCommaToPoint(price))
}

export const formatWithTwoDecimalPlaces = (price: number | string) => {
    return Number(price).toFixed(3).slice(0, -1)
}

export const returnTotalPriceIfInstallmentEqualsZero = (
    price: Partial<DetailedProductPrice>
) => {
    return Number(
        price.parcelas?.numeroParcelas === 0
            ? getPriceValueOrEmptyString(price.precoPor?.toString() ?? '')
            : getPriceValueOrEmptyString(
                  price.parcelas?.valorParcela.toString() ?? ''
              )
    )
}

export const ifZeroChangeToOne = (value: number) => {
    return value === 0 ? 1 : value
}

export const getSpotPrice = (prices: {
    percentualDescontoAVista: number
    precoPrazoText: string
    precoCheioText: string
    precoPorText: string
}) => {
    if (prices.percentualDescontoAVista > 0 && prices.precoPrazoText) {
        return `${prices.precoCheioText}`
    }

    return prices.precoPorText
}

export const formatThumbnail = (url: string): string => {
    if (url.includes('https://') || url.includes('static')) return url

    if (url.includes('//www.')) return `https:${url}`

    return `https://www.efacil.com.br${url}`
}

export const getMaximumAmountReachedMessage = (
    maxQuantity: string | number
) => {
    return `Produto limitado a uma quantidade máxima de ${maxQuantity}. Para comprar acima de ${maxQuantity}, entre em contato com o nosso Televendas.`
}

export const formatForwardPrice = (price?: string) =>
    price && `total de R$ ${formatPriceToPtBr(price)} a prazo`

export const getPriceValueOrEmptyString = (price: string) => {
    if (price === '') return price

    const priceWithoutCurrency = removeCurrency(price)

    if (isValidPriceNumber(priceWithoutCurrency)) return priceWithoutCurrency
    else {
        const priceStringWithOnePoint =
            formatPriceStringToHaveOnePoint(priceWithoutCurrency)
        return formatWithTwoDecimalPlaces(priceStringWithOnePoint)
    }
}

const isValidPriceNumber = (price: string) => {
    return !!Number(price)
}

export const removePointFromString = (value: string) =>
    value && value.replace(/\./g, '')

export const shareProduct = async (dates: ShareData) => {
    try {
        await navigator.share(dates)
    } catch (err) {
        console.error(err)
    }
}
