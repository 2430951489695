import { Button } from '@components/ui/Button'
import { IOProps } from 'react-compose-io'
import { CookiesMessageModalIO } from './io'
import * as Styled from './styles'
import { CustomLink } from '@components/CustomLink'
import { Routes } from '@service/routes'
import { themeStyled } from '@theme/theme'

export const CookiesMessageModalView = ({
    _io
}: IOProps<CookiesMessageModalIO, void>) => {
    return _io.showModal && !_io.userAcceptedCookies ? (
        <Styled.Container>
            <Styled.TextIconContainer>
                <Styled.CookiesSvgIcon />
                <Styled.Text>
                    Cookies: O eFácil usa cookies para personalizar anúncios e
                    melhorar a sua experiência no site. Ao continuar navegando,
                    você concorda com a nossa{' '}
                    <CustomLink href={Routes.privacy}>
                        <Styled.Text color={themeStyled.colors.secondary[500]}>
                            política de privacidade
                        </Styled.Text>
                    </CustomLink>
                </Styled.Text>
            </Styled.TextIconContainer>
            <Button color="primary" onClick={_io.handleOnButtonClick}>
                Continuar
            </Button>
        </Styled.Container>
    ) : (
        <></>
    )
}
