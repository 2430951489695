import { CustomLink } from '@components/CustomLink'
import { EXTERNAL_LINKS } from '@constants/common'
import { useDeviceContext } from '@contexts/device'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import StarIcon from '@material-ui/icons/Star'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import { Routes } from '@service/routes'
import { ComponentBaseProps } from '@static/types'
import { IOProps } from 'react-compose-io'
import { CategoryList } from '../../CategoryList'
import { ExpansibleMenuItem } from '../ExpansibleMenuItem'
import { MenuHeader } from '../MenuHeader'
import { MenuItem } from '../MenuItem'
import { PHONES } from './constants'
import { RightSideMenuIO } from './io'
import * as Styled from './styles'

export type RightSideMenuProps = ComponentBaseProps & {
    open: boolean
    userName?: string
    onClose: () => void
}

export const RightSideMenuView = ({
    _io,
    open,
    onClose,
    userName,
    categoriesData
}: IOProps<RightSideMenuIO, RightSideMenuProps>): JSX.Element => {
    const { isDesktop } = useDeviceContext()

    return (
        <Styled.Drawer
            open={open}
            onClose={onClose}
            data-testid="sidemenu-drawer"
            anchor={isDesktop ? 'left' : 'right'}
            keepMounted
        >
            <Styled.ContentContainer>
                <MenuHeader onClose={onClose} userName={userName} />

                <Styled.SectionTitle>Destaques</Styled.SectionTitle>

                <Styled.ItemsContainer>
                    <CustomLink href={Routes.dealsOfTheDay} target="_parent">
                        <MenuItem
                            icon={<StarIcon />}
                            text="Ofertas do dia"
                            arrowDirection="right"
                        />
                    </CustomLink>
                </Styled.ItemsContainer>

                <Styled.ItemsContainer>
                    <CustomLink href={Routes.discountCoupon} target="_parent">
                        <MenuItem
                            arrowDirection="right"
                            text="Cupons de desconto"
                            icon={<LocalOfferIcon />}
                        />
                    </CustomLink>
                </Styled.ItemsContainer>

                <Styled.SectionTitle>Departamentos</Styled.SectionTitle>

                <Styled.ItemsContainer>
                    <CategoryList
                        menuTheme="light"
                        onSelection={onClose}
                        categories={categoriesData?.start}
                    />

                    {_io.showExtraCategories && (
                        <ExpansibleMenuItem
                            reverse
                            menuTheme="light"
                            text="Veja todos os departamentos"
                        >
                            <CategoryList
                                menuTheme="light"
                                onSelection={onClose}
                                categories={categoriesData?.extra}
                            />
                        </ExpansibleMenuItem>
                    )}
                </Styled.ItemsContainer>

                <Styled.SectionTitle>Televendas</Styled.SectionTitle>

                <Styled.ItemsContainer>
                    <CustomLink
                        href={EXTERNAL_LINKS.televendas_whatsapp_app}
                        target="_blank"
                    >
                        <MenuItem
                            menuTheme={'blue'}
                            icon={<WhatsAppIcon />}
                            text={PHONES.televendas.text}
                        />
                    </CustomLink>
                </Styled.ItemsContainer>

                <Styled.SectionTitle>Opções eFácil</Styled.SectionTitle>

                <Styled.ItemsContainer>
                    <CustomLink href={Routes.about} target="_parent">
                        <MenuItem
                            text="Sobre o eFácil"
                            arrowDirection="right"
                        />
                    </CustomLink>
                    <CustomLink href={Routes.terms} target="_parent">
                        <MenuItem text="Termos de uso" arrowDirection="right" />
                    </CustomLink>
                    <CustomLink href={Routes.privacy} target="_parent">
                        <MenuItem
                            text="Política de privacidade"
                            arrowDirection="right"
                        />
                    </CustomLink>
                    <CustomLink href={Routes.devolutions} target="_parent">
                        <MenuItem
                            text="Troca e devoluções"
                            arrowDirection="right"
                        />
                    </CustomLink>
                    <CustomLink href={Routes.regulation} target="_parent">
                        <MenuItem
                            text="Regulamento das Campanhas"
                            arrowDirection="right"
                        />
                    </CustomLink>
                    <CustomLink href={Routes.callCenter} target="_parent">
                        <MenuItem
                            text="Central de atendimento"
                            arrowDirection="right"
                        />
                    </CustomLink>

                    <CustomLink href={Routes.lpPoints} target="_parent">
                        <MenuItem text="Pontos eFácil" arrowDirection="right" />
                    </CustomLink>
                    <CustomLink href={Routes.warranty} target="_parent">
                        <MenuItem
                            text="Garantia Extendida"
                            arrowDirection="right"
                        />
                    </CustomLink>
                    <CustomLink href={Routes.safety} target="_parent">
                        <MenuItem
                            text="Guia de Segurança"
                            arrowDirection="right"
                        />
                    </CustomLink>
                    <CustomLink href={Routes.evaluate} target="_parent">
                        <MenuItem
                            text="Avalie o eFácil"
                            arrowDirection="right"
                        />
                    </CustomLink>
                </Styled.ItemsContainer>

                <Styled.SacContainer href={`tel:${PHONES.sac.number}`}>
                    <Styled.SacText>SAC:</Styled.SacText>

                    <Styled.StyledWhatsApp />

                    <CustomLink
                        href={EXTERNAL_LINKS.contato_whatsapp_web}
                        target="_blank"
                    >
                        <Styled.SacNumber>{PHONES.sac.text}</Styled.SacNumber>
                    </CustomLink>
                </Styled.SacContainer>
            </Styled.ContentContainer>
        </Styled.Drawer>
    )
}
