import { CustomLink } from '@components/CustomLink'
import { MenuButton } from '@components/Layout/components/menu/MenuButton'
import { MenuButtonProps } from '@components/Layout/components/menu/MenuButton/types'
import { Routes } from '@service/routes'
import { getURLSafe } from '@utils/url'
import * as Styled from './styles'

export const MenuBarView = ({
    isSideMenuOpen,
    categoriesData,
    onToggleMenu,
    hide
}: MenuButtonProps): JSX.Element => {
    return (
        <Styled.MenuBar $hide={hide}>
            <Styled.Container>
                <Styled.MenuContainer>
                    <MenuButton
                        isSideMenuOpen={isSideMenuOpen}
                        categoriesData={categoriesData}
                        onToggleMenu={onToggleMenu}
                    />

                    <Styled.Text>Menu</Styled.Text>
                </Styled.MenuContainer>
                <Styled.DepartmentsContainer>
                    {categoriesData?.start.map((category, index) => (
                        <Styled.DepartmentTitle key={index}>
                            <CustomLink
                                href={`${Routes.department}/[...slug].tsx`}
                                as={getURLSafe(category.link).pathname}
                            >
                                {category.nome}
                            </CustomLink>
                        </Styled.DepartmentTitle>
                    ))}
                </Styled.DepartmentsContainer>
            </Styled.Container>
        </Styled.MenuBar>
    )
}
