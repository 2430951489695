import { DependencyList, EffectCallback, useEffect } from 'react'
import { useCookieContext } from '@contexts/cookie'
/**
 * Hook utilizado para executar useEffect depois que o contexto do cookie foi carregado.
 * @param callback
 * @param deps
 */

export const useLoadedEffect = (
    callback: EffectCallback,
    deps: DependencyList
) => {
    const { hasLoaded } = useCookieContext()
    useEffect(() => {
        if (hasLoaded) {
            return callback()
        }
    }, [hasLoaded, ...deps])
}
