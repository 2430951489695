import {
    SRC_TRUSTVOX_RATING_PRODUCTION,
    SRC_TRUSTVOX_RATING_STAGING
} from '@components/trustvox/constants'
import {
    createContext,
    PropsWithChildren,
    useContext,
    useRef,
    useState
} from 'react'

type TrusvoxContextData = {
    updateTrustvoxStars: () => void
}

const TrustvoxContext = createContext<TrusvoxContextData | null>(null)
TrustvoxContext.displayName = 'TrustvoxContext'

export const TrustvoxProvider = ({ children }: PropsWithChildren) => {
    const [isLoading, setIsLoading] = useState(false)
    const ref = useRef<HTMLDivElement>(null)

    const url =
        process.env.NEXT_PUBLIC_SHOW_PRODUCT_STAGING_REVIEWS === 'true'
            ? SRC_TRUSTVOX_RATING_STAGING
            : SRC_TRUSTVOX_RATING_PRODUCTION

    const updateTrustvoxStars = () => {
        if (!isLoading) {
            setIsLoading(true)

            const script = document.createElement('script')
            script.src = url
            script.async = true
            script.defer = true
            script.onload = () => setIsLoading(false)

            if (ref.current) {
                ref.current.innerHTML = ''
                ref.current.appendChild(script)
            }
        }
    }

    return (
        <TrustvoxContext.Provider value={{ updateTrustvoxStars }}>
            {children}
            <div ref={ref}></div>
        </TrustvoxContext.Provider>
    )
}

export const useTrustvoxContext = (): TrusvoxContextData => {
    return useContext(TrustvoxContext) as TrusvoxContextData
}
