import {
    getFormattedChannelDescription,
    getFormattedChannelNumber
} from '@components/Layout/utils'
import { IOProps } from 'react-compose-io'
import { MediaChannelModalIO } from './io'
import * as Styled from './styles'

export type MediaChannelProps = {
    isTelevendedor: boolean
    isModalOpen: boolean
    closeModal: () => void
}

export const MediaChannelModalView = ({
    _io,
    isModalOpen
}: IOProps<MediaChannelModalIO, MediaChannelProps>): JSX.Element => {
    return (
        <>
            {isModalOpen ? (
                <Styled.BackgroundContainer>
                    <Styled.Container>
                        <Styled.HeaderContainer>
                            <Styled.TitleContainer>
                                <Styled.Title>Selecionar canal</Styled.Title>
                                <Styled.CloseModalButton
                                    onClick={_io.handleCloseModal}
                                >
                                    X
                                </Styled.CloseModalButton>
                            </Styled.TitleContainer>
                            <Styled.Subtitle>
                                Escolha o canal de atendimento:
                            </Styled.Subtitle>
                        </Styled.HeaderContainer>
                        <Styled.HorizontalLine />
                        <Styled.InputContainer>
                            <Styled.InputText
                                placeholder="Pesquise por canais"
                                name="televendasChannelSearch"
                                value={_io.searchValue}
                                onChange={e => _io.handleChannelSearch(e)}
                            />
                            <Styled.SearchInputButton>
                                <Styled.SearchIcon />
                            </Styled.SearchInputButton>
                        </Styled.InputContainer>
                        <Styled.ChannelContainer>
                            {_io.searchResults?.map(channel => (
                                <Styled.ChannelItem
                                    key={channel.canal}
                                    onClick={() =>
                                        _io.handleChannelChange(channel.canal)
                                    }
                                >
                                    <span>
                                        {getFormattedChannelDescription(
                                            channel.nome
                                        )}
                                    </span>
                                    <span>
                                        {getFormattedChannelNumber(
                                            channel.nome
                                        )}
                                    </span>
                                </Styled.ChannelItem>
                            ))}
                        </Styled.ChannelContainer>
                    </Styled.Container>
                </Styled.BackgroundContainer>
            ) : (
                <></>
            )}
        </>
    )
}
