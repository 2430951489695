import styled from 'styled-components'

type ContainerProps = {
    $color: string
}

const Container = styled.span<ContainerProps>`
    fill: ${({ $color }) => $color};
    color: ${({ $color }) => $color};
    display: flex;
`

export { Container }
