import { Stack } from '@components/Stack'
import { Typography } from '@components/ui/Typography'
import { DESKTOP_SIZE } from '@constants/device'
import styled, { css } from 'styled-components'

type StyledMenuBarProps = {
    $hide?: boolean
}

const hideMenuBar = css`
    height: 0;
    opacity: 0;
`
export const MenuBar = styled.div<StyledMenuBarProps>`
    background: ${({ theme }) =>
        process.env.NEXT_PUBLIC_IS_BLACK_FRIDAY === 'true'
            ? theme.colors.blackFriday[700]
            : theme.colors.primary[600]};
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ $hide }) => $hide && hideMenuBar};
    transition: 0.2s;
`

export const Container = styled(Stack).attrs({
    direction: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fullWidth: true
})`
    max-width: ${DESKTOP_SIZE}px;
`

export const Text = styled(Typography).attrs({
    size: 'base',
    fontFamily: 'medium'
})`
    color: ${({ theme }) => theme.colors.white};
`

export const DepartmentsContainer = styled(Stack).attrs({
    direction: 'row',
    justifyContent: 'space-between',
    fullWidth: true
})`
    margin-left: 42px;
`

export const DepartmentTitle = styled(Typography).attrs(({ theme }) => ({
    direction: 'row',
    color: theme.colors.white,
    size: 'base',
    fontFamily: 'medium'
}))`
    white-space: nowrap;

    > a {
        text-decoration: none;
    }
`

export const MenuContainer = styled(Stack).attrs({
    direction: 'row',
    spacing: 12,
    justifyContent: 'center',
    alignItems: 'center'
})``
