import { Typography } from '@components/ui/Typography'
import InputBase from '@material-ui/core/InputBase'
import Search from '@material-ui/icons/Search'
import styled, { css } from 'styled-components'

export const Container = styled.div`
    width: 100%;
    margin: ${({ theme }) => !theme.isDesktop && '20px 0 0 0'};
    color: ${({ theme }) => theme.colors.gray[200]};
    background-color: transparent;
    border-radius: 5px;
`
const StyledInputBaseDesktop = css`
    border-radius: 56px;
    max-height: 48px;
    padding: 10px 0 10px 16px;
`

const StyledInputBaseMobile = css`
    border: 1px solid ${({ theme }) => theme.colors.gray[200]};
    max-height: 40px;
    padding: 10px 0 10px 3px;
`

export const StyledInputBase = styled(InputBase)`
    width: 100%;
    border-radius: 5px;
    color: ${({ theme }) => theme.colors.gray[600]};
    background-color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fontFamily.sansSerif};
    font-weight: ${({ theme }) => theme.fontWeight.sansSerifRegular};
    ${({ theme }) =>
        theme.isDesktop ? StyledInputBaseDesktop : StyledInputBaseMobile}

    .MuiInputBase-input::placeholder {
        opacity: 1;
        color: ${({ theme }) => theme.colors.gray[500]};
        font-family: ${({ theme }) => theme.fontFamily.sansSerif};
        font-weight: ${({ theme }) => theme.fontWeight.sansSerifRegular};
    }
`

export const SearchIcon = styled(Search)`
    width: 24px;
    height: 24px;
    margin: 0 12px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.gray[500]};
`

export const ProductsContainer = styled.div`
    padding: 16px 16px 4px 16px;
`

export const Title = styled(Typography).attrs({
    size: 'sm',
    fontFamily: 'bold',
    variant: 'paragraph'
})`
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors.gray[800]};
`
