export enum Routes {
    about = '/loja/departamento/lojas-especiais/QUEMSOMOS',
    terms = '/loja/TermsOfUseView',
    privacy = '/loja/PrivacyPolicyPage',
    devolutions = '/loja/PoliticasDeTrocasDevolucoesView',
    delivery = '/loja/departamento/lojas-especiais/ENTREGAEXPRESSA',
    lpPoints = '/loja/departamento/lojas-especiais/LP_PONTUACAO',
    warranty = '/loja/departamento/lojas-especiais/GARANTIAESTENDIDA',
    evaluate = '/loja/departamento/lojas-especiais/AVALIE',
    safety = '/loja/departamento/lojas-especiais/LP-GUIA-DE-SEGURANCA',
    regulation = '/loja/departamento/lojas-especiais/REGRAS_CAMPANHAS',
    discountCoupon = '/loja/departamento/lojas-especiais/CUPONSEFACIL',
    central = '#',
    home = '/',
    login = '/loja/auth/login/',
    resetPassword = '/loja/LogonForm',
    register = '/loja/auth/register',
    registrationSuccessful = '/loja/auth/registrationSuccessful',
    cart = '/loja/cart',
    productDetails = '/loja/produto/',
    checkoutDelivery = '/loja/checkout/delivery/',
    checkoutPaymentIntermediary = '/loja/checkout/payment/intermediary',
    checkoutPaymentOptions = '/loja/checkout/payment/options',
    checkoutPaymentDetails = '/loja/checkout/payment/details',
    checkoutPaymentCreditCard = '/loja/checkout/payment/credit-card',
    checkoutPaymentSummary = '/loja/checkout/payment/summary',
    checkoutConclusion = '/loja/checkout/conclusion',
    checkoutRegisterAddress = '/loja/checkout/address/register',
    checkoutChooseAddress = '/loja/checkout/address/choose',
    checkoutEditAddress = '/loja/checkout/address/',
    department = '/loja/departamento',
    orders = '/loja/my-account/orders',
    orderDetails = '/loja/my-account/orders/',
    search = '/loja/busca',
    hotsite = '/loja/hotsite',
    myAccount = '/loja/my-account',
    favorite = '/loja/my-account/favorites',
    userRegistrationData = '/loja/my-account/user-registration-data',
    myAccountChooseAddress = '/loja/my-account/addresses',
    myAccountEditAddress = '/loja/my-account/addresses/',
    myAccountCreateAddress = '/loja/my-account/addresses/create',
    myAccountChangePassword = '/loja/my-account/changePassword',
    callCenter = '/loja/central-de-atendimento/',
    userSolicitations = '/loja/my-account/minhas-solicitacoes',
    userPoints = '/loja/my-account/pontos',
    dealsOfTheDay = '/loja/hotsite/ofertas-do-dia/',
    televendasLogin = '/loja/televendas/login/',
    accountPrivacy = '/loja/my-account/privacidade'
}
