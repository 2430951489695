import MUIDialogContent from '@material-ui/core/DialogContent'
import MUIDialog from '@material-ui/core/Dialog'
import styled from 'styled-components'

export const Dialog = styled(MUIDialog)`
    z-index: 1400 !important;

    div:has(div[style*='opacity: 0']) {
        pointer-events: none;
    }

    .MuiPaper-rounded {
        border-radius: ${({ theme }) =>
            theme.isDesktop ? '8px' : '8px 8px 0px 0px'};
    }

    .MuiDialogContent-root {
        display: flex;
    }

    .MuiDialog-paperWidthXs {
        min-width: 327px;
        min-height: 80px;
    }

    .MuiDialogContentText-root {
        margin-bottom: 0px;
    }

    .MuiDialog-scrollPaper {
        align-items: flex-end;
    }

    .MuiDialog-paperFullWidth {
        width: 100%;
    }

    .MuiDialog-paper {
        ${({ theme }) =>
            theme.isDesktop
                ? 'display: flex;  align-self: center; width: 564px;'
                : 'margin: 0;'}
    }
`

export const DialogContent = styled(MUIDialogContent)`
    margin: 0;
    max-height: 91vh;
    ${({ theme }) =>
        theme.isDesktop ? 'padding: 32px !important;' : 'padding: 0;'};
`
