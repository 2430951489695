import { default as MUIPopover } from '@mui/material/Popover'
import { PopoverProps } from './types'

export const PopoverView = (props: PopoverProps) => {
    return (
        <MUIPopover {...props} keepMounted>
            {props.children}
        </MUIPopover>
    )
}
