import Link, { LinkProps } from 'next/link'
import { HTMLAttributeAnchorTarget, ReactNode } from 'react'
import { UrlObject } from 'url'
import { getRel } from './utils'

type CustomLinkProps = {
    disableClick?: boolean
    children: ReactNode
    href: UrlObject | string
    target?: HTMLAttributeAnchorTarget
    onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void
    idName?: string
    textDecoration?: string
} & LinkProps

export const CustomLinkView = ({
    disableClick = false,
    href,
    children,
    target = '_parent',
    onClick,
    idName,
    textDecoration,
    ...props
}: CustomLinkProps) => {
    const safeHref =
        typeof href === 'string' ? href.replace('/desktop', '') : href
    return disableClick ? (
        <span aria-disabled="true" role="button" {...props}>
            {children}
        </span>
    ) : (
        <Link
            href={safeHref}
            prefetch={false}
            {...props}
            style={{ cursor: 'pointer', textDecoration: textDecoration }}
            rel={getRel(target)}
            onClick={onClick}
            target={target}
            id={idName}
        >
            {children}
        </Link>
    )
}
