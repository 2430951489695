import * as Styled from './styles'

type TooltipBarProps = {
    children: React.ReactNode
    $width?: number
}

export const TooltipBarView = ({
    children,
    $width = 570
}: TooltipBarProps): JSX.Element => {
    return (
        <Styled.Container $width={$width}>
            <Styled.Arrow />
            <Styled.OptionsContainer>{children}</Styled.OptionsContainer>
        </Styled.Container>
    )
}
