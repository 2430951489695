import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@components/ui/Typography'

export const Container = styled(Grid)`
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 12px;
    box-shadow: ${({ theme }) => theme.boxShadow.heavy};
    background-color: ${({ theme }) => theme.colors.white};

    :hover {
        background-color: ${({ theme }) => theme.colors.gray[50]};
    }
`

export const GridWrapper = styled(Grid)`
    gap: 8px;
`
export const ProductNameContainer = styled(Grid)`
    max-width: 45%;
`

export const ImageContainer = styled.div`
    width: 48px;
    height: 48px;
`

export const ProductName = styled(Typography).attrs({
    size: 'xs',
    variant: 'span',
    fontFamily: 'regular'
})`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    color: ${({ theme }) => theme.colors.gray[700]};
`

export const PriceContainer = styled(Grid)`
    max-width: 33%;
`

export const OldPriceText = styled(Typography).attrs({
    size: 'xs',
    variant: 'span',
    fontFamily: 'regular'
})`
    text-align: right;
    text-decoration: line-through;
    color: ${({ theme }) => theme.colors.gray[500]};
`

export const CurrentPriceText = styled(Typography).attrs({
    size: 'sm',
    variant: 'paragraph',
    fontFamily: 'medium'
})`
    text-align: right;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.secondary[800]};
`
