import { Typography } from '@components/ui/Typography'
import styled from 'styled-components'

interface MenuItemWrapperProps {
    $dividerColor: string
    $showBottomDivider: boolean
}

export const MenuItemWrapper = styled.div<MenuItemWrapperProps>`
    display: flex;
    flex-grow: 1;
    position: relative;
    border-style: solid;
    border-color: ${({ $dividerColor }) => $dividerColor};
    border-width: 0;

    border-bottom-width: ${({ $showBottomDivider }) => {
        return $showBottomDivider ? '1px' : '0'
    }};

    .content-link {
        width: 100%;
    }

    .textWithIcon {
        padding: 12px 12px 12px 16px;
    }

    &.subItem .textWithIcon {
        padding-left: 48px;
    }
`

export const Description = styled(Typography).attrs({
    size: 'sm',
    fontFamily: 'regular',
    variant: 'paragraph'
})`
    flex-grow: 1;
    text-align: left;
`
