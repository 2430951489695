import styled from 'styled-components'
import { Stack } from '@components/Stack'
import { Typography } from '@components/ui/Typography'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import { Drawer as DefauldDrawer } from '@material-ui/core'

export const Drawer = styled(DefauldDrawer)`
    width: 100%;
`

export const ContentContainer = styled(Stack)`
    flex: 1;
    width: ${({ theme }) => (theme.isDesktop ? '400px' : '100vw')};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: ${({ theme }) => theme.colors.gray[50]};
`

export const SectionTitle = styled(Typography).attrs({
    size: 'sm',
    fontFamily: 'medium'
})`
    padding: 16px;
    color: ${({ theme }) => theme.colors.black};
`

export const ItemsContainer = styled.div`
    box-shadow: ${({ theme }) => theme.boxShadow.heavy};
    background-color: ${({ theme }) => theme.colors.white};

    & + & {
        margin-top: 8px;
    }
`

export const SacContainer = styled.a`
    display: flex;
    padding: 32px;
    align-items: center;
    justify-content: center;
`

export const SacText = styled(Typography).attrs({
    size: 'lg',
    fontFamily: 'regular'
})`
    margin-right: 7px;
    color: ${({ theme }) => theme.colors.black};
`

export const StyledWhatsApp = styled(WhatsAppIcon)`
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors.secondary[500]};
`

export const SacNumber = styled(Typography).attrs({
    size: 'sm',
    fontFamily: 'regular'
})`
    margin-left: 11px;
    color: ${({ theme }) => theme.colors.secondary[500]};
`
