import React from 'react'
import * as Styled from './ColoredSvgStyles'

type Props = {
    svg: React.ReactNode
    color: string
}

export const ColoredSvg = ({ svg, color }: Props): JSX.Element => {
    return <Styled.Container $color={color}>{svg}</Styled.Container>
}
