import { Typography } from '@components/ui/Typography'
import styled from 'styled-components'

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    min-width: 166px;
    height: 44px;
    padding: 20px 10px;
    border-radius: 44px;
    background: ${({ theme }) => theme.colors.opacity};
    border: 0;
    cursor: pointer;
`

export const TextMessage = styled(Typography).attrs(({ theme }) => ({
    size: 'sm',
    color: theme.colors.white
}))`
    white-space: nowrap;
`
