export const HeaderFavoriteIcon = () => (
    <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_18688_19943)">
            <path
                d="M18 32.025L15.825 30.045C8.1 23.04 3 18.42 3 12.75C3 8.13 6.63 4.5 11.25 4.5C13.86 4.5 16.365 5.715 18 7.635C19.635 5.715 22.14 4.5 24.75 4.5C29.37 4.5 33 8.13 33 12.75C33 18.42 27.9 23.04 20.175 30.06L18 32.025Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_18688_19943">
                <rect width="36" height="36" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
