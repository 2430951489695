export const GenericErrorIcon = () => (
    <svg
        width="65"
        height="52"
        viewBox="0 0 65 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M61.1247 17.497H3.74544C1.92799 17.497 0.5 19.0548 0.5 20.8722C0.5 22.8195 1.92799 24.2475 3.74544 24.2475H4.78398L10.2363 47.0954C10.2363 47.0954 11.0152 51.3793 16.0781 51.3793C20.4919 51.3793 29.7089 51.3793 32.1755 51.3793C32.5649 51.3793 32.8245 51.3793 32.8245 51.3793C35.2911 51.3793 44.5081 51.3793 48.9219 51.3793C53.9848 51.3793 54.7637 47.0954 54.7637 47.0954L60.216 24.2475H61.2546C63.072 24.2475 64.5 22.6897 64.5 20.8722C64.3702 18.925 62.8124 17.497 61.1247 17.497ZM31.786 25.6755C43.7292 25.6755 48.2728 36.1907 49.0517 41.643C49.1815 42.8114 48.4026 43.9797 47.1045 44.1096C45.9361 44.2394 44.7677 43.4605 44.6379 42.1623C44.5081 41.643 42.8205 30.0893 31.786 30.0893C20.8813 30.0893 20.3621 41.5132 20.3621 41.9027C20.3621 43.2008 19.3235 44.1096 18.0254 44.1096C16.7272 44.1096 15.8185 43.071 15.8185 41.7728C16.0781 36.1907 19.713 25.6755 31.786 25.6755Z"
            fill="#8F9DA3"
        />
        <path
            d="M25.6846 0.750514C25.425 0.620697 25.1653 0.620697 24.9057 0.620697C23.6075 0.620697 22.4392 1.52942 22.1795 2.69778L19.9726 10.357C19.5832 11.9148 20.4919 13.4726 21.9199 13.8621C23.4777 14.2515 25.0355 13.3428 25.425 11.9148L27.6319 4.25559C27.7617 3.47668 27.7617 2.69778 27.3722 2.04869C27.1126 1.3996 26.4635 0.880332 25.6846 0.750514Z"
            fill="#8F9DA3"
        />
        <path
            d="M39.8347 0.620697C39.5751 0.620697 39.3154 0.620697 39.0558 0.750514C38.2769 1.01015 37.7576 1.3996 37.3682 2.04869C36.9787 2.69778 36.8489 3.47668 37.1085 4.25559L39.3154 11.9148C39.7049 13.3428 41.2627 14.3813 42.8205 13.9919C44.3783 13.6024 45.287 12.0446 44.7678 10.4868L42.6907 2.69778C42.3012 1.52942 41.1329 0.620697 39.8347 0.620697Z"
            fill="#8F9DA3"
        />
    </svg>
)
