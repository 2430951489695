import { createSlice } from '@reduxjs/toolkit'
import { ProductState } from './types'

const initialState: ProductState = {
    isLoadingPrices: true,
    isLoadingTrustvoxStars: true
}

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        startLoadingProductPrice(state) {
            state.isLoadingPrices = true
        },
        finishLoadingProductPrices(state) {
            state.isLoadingPrices = false
        }
    }
})

export const productActions = productSlice.actions

export default productSlice
