export const WhatsappIcon = ({ width = 24, height = 24 }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.5032 3.48774C18.2471 1.23981 15.2467 0.00130343 12.0501 0C5.46355 0 0.102786 5.33471 0.100216 11.8918C0.0992939 13.9879 0.649558 16.0338 1.69532 17.8373L0 24L6.33478 22.3462C8.08017 23.2937 10.0453 23.7931 12.0453 23.7938H12.0502C12.0506 23.7938 12.0499 23.7938 12.0503 23.7938C18.6361 23.7938 23.9972 18.4585 24 11.9013C24.0012 8.72353 22.7593 5.73558 20.5032 3.48774ZM12.0501 21.7853H12.0461C10.264 21.7846 8.5159 21.308 6.99094 20.4075L6.6283 20.1933L2.86915 21.1746L3.87256 17.527L3.63638 17.153C2.64218 15.5792 2.11709 13.7602 2.11787 11.8925C2.12 6.44248 6.57567 2.00859 12.0542 2.00859C14.707 2.00941 17.2008 3.03898 19.076 4.90737C20.9512 6.77577 21.9834 9.25929 21.9823 11.9005C21.9801 17.3509 17.5245 21.7853 12.0501 21.7853Z"
            fill="current"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.498 14.3822C17.1995 14.2335 15.7314 13.5147 15.4578 13.4155C15.1841 13.3163 14.985 13.2667 14.786 13.5642C14.587 13.8616 14.0147 14.531 13.8405 14.7294C13.6664 14.9276 13.4922 14.9525 13.1937 14.8037C12.8951 14.6549 11.933 14.3412 10.7925 13.3288C9.90487 12.5408 9.30566 11.5678 9.13142 11.2702C8.95728 10.9728 9.11289 10.812 9.26239 10.6638C9.39671 10.5306 9.561 10.3167 9.71031 10.1432C9.85956 9.96971 9.90933 9.84565 10.0089 9.64748C10.1084 9.44911 10.0587 9.27556 9.98398 9.12688C9.90933 8.97814 9.31221 7.5155 9.06342 6.92041C8.82098 6.34105 8.57485 6.41955 8.39155 6.41033C8.21765 6.40169 8.01833 6.3999 7.81931 6.3999C7.62028 6.3999 7.29674 6.47429 7.02311 6.77172C6.74944 7.06924 5.97803 7.7882 5.97803 9.2507C5.97803 10.7134 7.04795 12.1264 7.19725 12.3247C7.34651 12.5231 9.3027 15.5245 12.2979 16.8116C13.0103 17.1178 13.5665 17.3006 14.0001 17.4376C14.7154 17.6638 15.3663 17.6318 15.8807 17.5553C16.4544 17.47 17.6473 16.8365 17.8961 16.1424C18.145 15.4482 18.145 14.8532 18.0703 14.7293C17.9957 14.6054 17.7966 14.531 17.498 14.3822Z"
            fill="current"
        />
    </svg>
)
