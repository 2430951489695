import { DEFAULT_ERROR_DESCRIPTION } from '@constants/dialog'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from './types'

const initialState: AppState = {
    loading: false,
    error: undefined,
    routes: { current: '', last: '' }
} as const

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
        showError(state, action: PayloadAction<string | undefined>) {
            state.error = action.payload || DEFAULT_ERROR_DESCRIPTION
        },
        hideError(state) {
            state.error = undefined
        },
        setCurrentRoute(state, action: PayloadAction<string>) {
            if (state.routes.current !== action.payload) {
                state.routes.last = state.routes.current
                state.routes.current = action.payload
            }
        }
    }
})

export const appActions = appSlice.actions

export default appSlice
