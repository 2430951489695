import {
    getFormattedChannelName,
    getFormattedChannelNumber
} from '@components/Layout/utils'
import { useLoading } from '@contexts/loading'
import { Routes } from '@service/routes'
import { logoutTelevendedorThunk } from '@store/modules/auth/thunk'
import { useAppSelector } from '@store/modules/store'
import { getChannelFromCookies } from '@utils/store-channel'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { LoggedInTelevendedorProps } from './view'

export const loggedInTelevendedorIO = ({
    userName,
    isTelevendedor
}: LoggedInTelevendedorProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [channelName, setChannelName] = useState<string>('861 - Telefácil')
    const [televendasChannelName, setTelevendasChannelName] =
        useState<string>('861 - Telefácil')

    const router = useRouter()
    const { showLoading, hideLoading } = useLoading()
    const dispatch = useDispatch()

    const channels = useAppSelector(state => state.user.televendasChannelData)
    const channelFromCookie = getChannelFromCookies()

    useEffect(() => {
        if (isTelevendedor && channels) {
            const channelData = channels.find(
                channel => channel.canal === channelFromCookie
            )

            const channelDataName =
                channelData?.nome !== undefined
                    ? getFormattedChannelName(channelData?.nome)
                    : 'Telefácil'

            const channelDataNumber = getFormattedChannelNumber(
                channelData?.nome || '861'
            )

            setTelevendasChannelName(
                `${channelDataName} - ${channelDataNumber}`
            )
            setChannelName(channelData?.nome || '')
        }
    }, [channelFromCookie, channels])

    const handleLogout = () => {
        showLoading()
        dispatch(
            logoutTelevendedorThunk({
                onSuccess: () => {
                    router.replace(Routes.televendasLogin)
                    hideLoading()
                },
                onError: () => {
                    hideLoading()
                }
            })
        )
    }

    const firstName = () => {
        const userNameArray = userName.split(' ')
        return userNameArray[0]
    }

    const handleCloseModal = () => setShowModal(false)

    const handlePopover = (value: boolean) => setIsOpen(value)

    const handleChangeChannel = () => {
        setShowModal(true)
        setIsOpen(false)
    }

    return {
        handleLogout,
        firstName,
        televendasChannelName,
        channelName,
        handleChangeChannel,
        showModal,
        handleCloseModal,
        isTelevendedor,
        isOpen,
        handlePopover
    }
}

export type LoggedInTelevendedorIO = ReturnType<typeof loggedInTelevendedorIO>
