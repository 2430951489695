import { Stack } from '@components/Stack'
import React from 'react'
import { IOProps } from 'react-compose-io'
import { Popover } from './components/Popover'
import { PopoverOrigin } from './components/Popover/types'
import { CustomPopoverIO } from './io'
import * as Styled from './styles'

export type CustomPopoverProps = {
    buttonContent: React.ReactNode
    popoverContent: React.ReactNode
    anchorOrigin?: PopoverOrigin
    transformOrigin?: PopoverOrigin
    isOpen?: boolean
    handlePopover?: (value: boolean) => void
}

export const CustomPopoverView = ({
    _io,
    buttonContent,
    popoverContent,
    anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'center'
    },
    transformOrigin = {
        vertical: 'top',
        horizontal: 'center'
    }
}: IOProps<CustomPopoverIO, CustomPopoverProps>) => {
    return (
        <Stack>
            <Styled.ButtonContainer onClick={_io.handleClick}>
                {buttonContent}
            </Styled.ButtonContainer>
            <Popover
                id={_io.id}
                open={_io.open}
                anchorEl={_io.anchorElement}
                onClose={_io.handleClose}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            >
                {popoverContent}
            </Popover>
        </Stack>
    )
}
