import { themeStyled } from '@theme/theme'
import styled from 'styled-components'
import MUITypography from '@material-ui/core/Typography'

const sizes = {
    xs1: { fontSize: '10px', lineHeight: '12px', letterSpacing: '' },
    xs: { fontSize: '12px', lineHeight: '16px', letterSpacing: '0.15px' },
    sm: { fontSize: '14px', lineHeight: '24px', letterSpacing: '0.15px' },
    base: { fontSize: '15px', lineHeight: '24px', letterSpacing: '0.15px' },
    lg: { fontSize: '17px', lineHeight: '24px', letterSpacing: '0.18px' },
    xl: { fontSize: '19px', lineHeight: '24px', letterSpacing: '0.18px' },
    xl2: { fontSize: '22px', lineHeight: '24px', letterSpacing: '0.18px' },
    xl3: { fontSize: '24px', lineHeight: '32px', letterSpacing: '' },
    xl4: { fontSize: '32px', lineHeight: '48px', letterSpacing: '' },
    xl5: { fontSize: '40px', lineHeight: '60px', letterSpacing: '' },
    xl6: { fontSize: '64px', lineHeight: '80px', letterSpacing: '' }
} as const

const fontFamilies = {
    light: themeStyled.fontFamily.sansSerif,
    regular: themeStyled.fontFamily.sansSerif,
    medium: themeStyled.fontFamily.sansSerif,
    bold: themeStyled.fontFamily.sansSerif
} as const

const fontWeights = {
    light: themeStyled.fontWeight.sansSerifLight,
    regular: themeStyled.fontWeight.sansSerifRegular,
    medium: themeStyled.fontWeight.sansSerifMedium,
    bold: themeStyled.fontWeight.sansSerifBold
} as const

export type SizesType = keyof typeof sizes
export type FontFamilyType = keyof typeof fontFamilies

export const getFontSize = (size: SizesType) => sizes[size].fontSize
export const getLineHeight = (size: SizesType) => sizes[size].lineHeight
const getLetterSpacing = (size: SizesType) => sizes[size].letterSpacing
const getFontFamily = (fontFamily: FontFamilyType) => fontFamilies[fontFamily]
const getFontWeight = (fontFamily: FontFamilyType) => fontWeights[fontFamily]

type FontProps = {
    $size?: SizesType
    $fontFamily?: FontFamilyType
    $color?: string
    $textAlign?: string
    $textDecoration?: string
}

export const Typography = styled(MUITypography)<FontProps>`
    font-size: ${({ $size }) => ($size ? getFontSize($size) : 'inherit')};
    line-height: ${({ $size }) => ($size ? getLineHeight($size) : 'inherit')};
    letter-spacing: ${({ $size }) =>
        $size ? getLetterSpacing($size) : 'inherit'};
    font-family: ${({ $fontFamily }) =>
        $fontFamily ? getFontFamily($fontFamily) : 'inherit'};
    font-weight: ${({ $fontFamily }) =>
        $fontFamily ? getFontWeight($fontFamily) : 'inherit'};
    color: ${({ $color }) => $color};
    text-align: ${({ $textAlign }) => $textAlign};
    text-decoration: ${({ $textDecoration }) => $textDecoration};
`
