import { DEFAULT_ERROR_DESCRIPTION } from '@constants/dialog'
import { useDeviceContext } from '@contexts/device'
import { useLoading } from '@contexts/loading'
import { useTrustvoxContext } from '@contexts/trustvox'
import { useLoadedEffect } from '@hooks/useLoadedEffect'
import { useShowCaseImpression } from '@hooks/useShowCaseImpression'
import { Routes } from '@service/routes'
import { getRecommendationsThunk } from '@store/modules/recommendations/thunk'
import {
    RecommendationsNames,
    ShowcasesData
} from '@store/modules/recommendations/types'
import { useAppDispatch } from '@store/modules/store'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { ErrorType, StaticErrorProps } from './types'

export const staticErrorIO = ({
    errorType = ErrorType.GENERIC
}: StaticErrorProps) => {
    const [recommendations, setRecommendations] = useState<ShowcasesData>()

    const router = useRouter()
    const dispatch = useAppDispatch()
    const { hideLoading } = useLoading()
    const { isDesktop } = useDeviceContext()
    const { updateTrustvoxStars } = useTrustvoxContext()
    const { resetBoundary } = useErrorBoundary()

    useLoadedEffect(() => {
        dispatch(
            getRecommendationsThunk(
                {
                    name: RecommendationsNames.NOT_FOUND,
                    desktop: isDesktop
                },
                {
                    onSuccess: onGetRecommendationsSuccess,
                    onError: hideLoading
                }
            )
        )

        useShowCaseImpression()
    }, [])

    function onGetRecommendationsSuccess(
        recommendationsResponse: ShowcasesData
    ) {
        setRecommendations(recommendationsResponse)
        updateTrustvoxStars()
        hideLoading()
    }

    const getDescription = () => {
        switch (errorType) {
            case ErrorType.NOT_FOUND:
                return 'Não foi possível encontrar a página que você está buscando...'
            case ErrorType.GENERIC:
            default:
                return DEFAULT_ERROR_DESCRIPTION
        }
    }

    return {
        recommendations,
        description: getDescription(),
        resetBoundary,
        goBack: () => router.push(Routes.home)
    }
}

export type StaticErrorIO = ReturnType<typeof staticErrorIO>
