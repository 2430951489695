import { Stack } from '@components/Stack'
import { Button } from '@components/ui/Button'
import styled from 'styled-components'

export const TitleContainer = styled(Stack).attrs({
    justifyContent: 'center',
    alignItems: 'center',
    spacing: 16
})`
    padding: 48px 24px 0;

    a {
        width: 100%;
    }
`

export const BackToStartButton = styled(Button).attrs({
    color: 'secondary',
    size: 'medium'
})`
    span {
        text-decoration: none;
    }
    margin-top: 40px;
`
export const ShowCaseContainer = styled.div`
    width: 100vw;
    margin-top: 48px;
`
