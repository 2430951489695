import { Stack } from '@components/Stack'

export interface IconContentProps {
    icon: React.ReactNode
    iconPosition?: 'left' | 'right'
    children?: React.ReactNode
}

export const ButtonIconContent = ({
    icon,
    iconPosition = 'left',
    children
}: IconContentProps): JSX.Element => {
    return (
        <Stack
            direction={iconPosition === 'left' ? 'row' : 'row-reverse'}
            spacing={13}
            alignItems="center"
            justifyContent="center"
        >
            {icon}
            <span>{children}</span>
        </Stack>
    )
}
