import { IOProps } from 'react-compose-io'
import { LoggedInUser } from './components/LoggedInUser'
import { LoggedOutUser } from './components/LoggedOutUser'
import { MenuHeaderIO } from './io'
import * as Styled from './styles'

export type LoginLinkProps = {
    userName: string
}

export const LoginLinkView = ({
    _io,
    userName
}: IOProps<MenuHeaderIO, LoginLinkProps>): JSX.Element => {
    return (
        <Styled.Container>
            {_io.isAuthenticated && userName ? (
                <LoggedInUser userName={_io.fullName()} />
            ) : (
                <LoggedOutUser />
            )}
        </Styled.Container>
    )
}
