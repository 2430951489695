import * as Styled from './styles'
import { IOProps } from 'react-compose-io'
import { TypographyIO } from './io'
import { TypographyProps } from './types'

export const TypographyView = ({
    _io,
    size,
    fontFamily,
    color,
    children,
    variant = 'inherit',
    textAlign,
    textDecoration = 'none',
    className,
    onClick,
    ...props
}: IOProps<TypographyIO, TypographyProps>): JSX.Element => {
    return (
        <Styled.Typography
            $size={size}
            $fontFamily={fontFamily}
            $color={color}
            $textAlign={textAlign}
            $textDecoration={textDecoration}
            variant={_io.getVariant(variant)}
            className={className}
            onClick={onClick}
            {...props}
        >
            {children}
        </Styled.Typography>
    )
}
