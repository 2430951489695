import { BaseProduct } from '@store/modules/cart/types'
import { ProductInCheckout } from '@store/modules/checkout/types'
import { OrderProduct } from '@store/modules/order/types'

export const countProducts = (products: BaseProduct[]): number => {
    return products.reduce((accumulator, item) => {
        const servicesCount = item.servicosDisponiveis?.reduce(
            (warrantyAccumulator, warranty) => {
                return warrantyAccumulator + warranty.quantidade
            },
            0
        )
        return accumulator + item.quantidade + (servicesCount || 0)
    }, 0)
}

export type GroupedProducts<T> = {
    item: T
    gift?: T
}

export const groupProductGifts = <T extends { valorTotalText: string }>(
    cartProducts: T[]
): GroupedProducts<T>[] => {
    const newCartItens: GroupedProducts<T>[] = []

    for (let i = 0; i < cartProducts.length; i++) {
        const item = cartProducts[i]

        // NOSONAR FIXME: Correção provisória para não quebrar o carrinho quando
        // tem um brinde seguido do outro. (solução: transformar gift em um array)
        if (item.valorTotalText?.toUpperCase() === 'BRINDE' && i !== 0)
            newCartItens[newCartItens.length - 1].gift = item
        else newCartItens.push({ item })
    }

    return newCartItens
}

export type FormattedCartItemAttributes =
    | 'sku'
    | 'thumbnail'
    | 'nome'
    | 'lojista'
    | 'prazoEntrega'
    | 'prazoEntregaText'
    | 'quantidade'
    | 'valorUnitarioText'
    | 'valorTotalText'
    | 'link'
    | 'isServico'

export type FormattedCartItemType = Pick<
    OrderProduct,
    FormattedCartItemAttributes
>

export const formatCartItemsList = (
    orderItems: OrderProduct[] | ProductInCheckout[]
): FormattedCartItemType[] => {
    return orderItems?.map(item => ({
        sku: item.sku,
        thumbnail: item.thumbnail,
        nome: item.nome,
        lojista: item.lojista,
        prazoEntrega: item.prazoEntrega || 0,
        prazoEntregaText: item.prazoEntregaText || '',
        quantidade: item.quantidade,
        valorTotalText: item.valorTotalText,
        valorUnitarioText: item.valorUnitarioText || '',
        link: item.link || '',
        isServico: item.isServico || false
    }))
}
