import { CustomLink } from '@components/CustomLink'
import * as Styled from './styles'
import { Routes } from '@service/routes'
import { WhiteIconCart } from '@assets/svg/WhiteIconCart'

type CartLinkProps = {
    numberOfItemsInCart: number
}

export const CartLinkView = ({
    numberOfItemsInCart
}: CartLinkProps): JSX.Element => {
    return (
        <Styled.CartLinkContainer>
            <CustomLink href={Routes.cart} idName="btn_cart">
                <Styled.BadgeContainer
                    badgeContent={numberOfItemsInCart}
                    overlap="rectangular"
                >
                    <WhiteIconCart />
                </Styled.BadgeContainer>
            </CustomLink>
        </Styled.CartLinkContainer>
    )
}
