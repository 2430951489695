export const WhiteIconCart = () => (
    <svg
        width="34"
        height="30"
        viewBox="0 0 34 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.3501 24.9806C10.969 24.9806 8.53861 18.9846 8.14459 15.8144C8.05615 15.1081 8.5179 14.4586 9.17522 14.3646C9.82683 14.2675 10.4332 14.7645 10.5231 15.4703C10.5615 15.7653 11.4961 22.4004 17.3501 22.4004C23.1473 22.4004 23.4573 15.8702 23.4683 15.5917C23.4943 14.8807 24.0455 14.33 24.7153 14.3548C25.3774 14.3832 25.8935 14.9836 25.8656 15.6955C25.7464 18.9072 23.8133 24.9806 17.3501 24.9806ZM32.2674 9.65569H1.73368C0.775512 9.65569 0 10.5323 0 11.6142C0 12.6973 0.775512 13.5738 1.73368 13.5738H2.27325L5.16252 26.6649C5.16252 26.6649 5.5936 29.1062 8.27089 29.1062H16.8311H17.1689H25.7291C28.4065 29.1062 28.839 26.6649 28.839 26.6649L31.7277 13.5738H32.2674C33.2245 13.5738 34 12.6973 34 11.6142C34 10.5323 33.2245 9.65569 32.2674 9.65569Z"
            fill="white"
        />
        <path
            d="M13.4255 0.0577316C13.2948 0.0197325 13.1617 0.00063324 13.0286 0.00063324C12.3439 0.00063324 11.7418 0.496941 11.5626 1.20783L10.4137 5.61089C10.197 6.47629 10.676 7.37579 11.4848 7.60961C12.2838 7.83873 13.1357 7.30865 13.3496 6.45898L14.498 2.05697C14.6023 1.63924 14.5494 1.19957 14.3471 0.820103C14.1453 0.44242 13.8176 0.170872 13.4255 0.0577316Z"
            fill="white"
        />
        <path
            d="M20.9724 0.00012207C20.8389 0.00012207 20.7053 0.0197501 20.5746 0.0572209C20.1825 0.170625 19.8557 0.441909 19.653 0.820055C19.4511 1.19906 19.3983 1.63926 19.5036 2.06082L20.6501 6.45483C20.8648 7.30867 21.7148 7.83874 22.5153 7.60962C23.324 7.37528 23.8036 6.47605 23.5864 5.60622L22.4389 1.21221C22.2593 0.496364 21.6562 0.00012207 20.9724 0.00012207Z"
            fill="white"
        />
    </svg>
)
