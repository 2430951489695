import { ImageLoaderProps } from 'next/legacy/image'

export function customImageLoader({ src }: ImageLoaderProps) {
    if (src.includes('www.efacil')) {
        const imageSource = 'static1'
        return src.replace('www', imageSource)
    }

    if (src.includes('qa.efacil')) {
        const imageSourceQA = 'qa-static1'
        return src.replace('qa', imageSourceQA)
    }

    return src
}
