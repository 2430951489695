import { ContentPaddingSizesType } from '@components/Layout/styles'
import { useDeviceContext } from '@contexts/device'
import copy from 'copy-to-clipboard'

export const isClientSide = (): boolean => {
    return !isStaticOrServerSide()
}

export const isStaticOrServerSide = (): boolean => {
    return typeof window === 'undefined'
}

export const maskUserEmail = (email: string): string => {
    const [name, domain] = email.split('@')
    const maskedName = name[0] + '****'
    return maskedName + '@' + domain
}

export const openNewTab = (url: string): void => {
    const windowData = window.open(url, '_blank')
    if (!windowData) window.open(url, '_self')
}

export const copyTextToClipboard = (text: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        if (copy(text)) resolve()
        reject()
    })
}

export function removeAccents(text: string) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const getLayoutContentPaddingTop = (
    desktopPadding: ContentPaddingSizesType,
    mobilePadding: ContentPaddingSizesType
) => {
    const { isDesktop } = useDeviceContext()
    return isDesktop ? desktopPadding : mobilePadding
}
