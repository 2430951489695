import styled from 'styled-components'

const ButtonsContainer = styled.div`
    display: grid;
    justify-content: initial;
    margin-top: 24px;
    margin: 16px;

    button:nth-child(2n) {
        margin-top: 8px;
    }
`

export { ButtonsContainer }
