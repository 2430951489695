import { useEffect, useState } from 'react'

export const useHideOnScroll = () => {
    const [hideComponent, setHideComponent] = useState(false)
    let scheduledAnimationFrame = false

    useEffect(() => {
        const handleScroll = () => {
            if (scheduledAnimationFrame) return

            scheduledAnimationFrame = true

            const hideComponent = () => {
                const scrolledToTop = window.scrollY <= 0
                setHideComponent(!scrolledToTop)
                scheduledAnimationFrame = false
            }

            requestAnimationFrame(hideComponent)
        }

        window.addEventListener('scroll', handleScroll)

        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    return {
        hideComponent
    }
}
