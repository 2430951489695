import {
    StylesProvider,
    ThemeProvider as MUIThemeProvider
} from '@material-ui/core/styles'
import { themeMaterialUI, customThemeStyled } from './theme'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { GlobalStyles } from './global'
import { useRouter } from 'next/router'
import { isDesktopPage } from '@utils/device'

export type ThemeContextDataProps = {
    isDesktop?: boolean
    children?: React.ReactNode
}

export const ThemeProvider = (props: ThemeContextDataProps): JSX.Element => {
    let isDesktop = props.isDesktop

    const router = useRouter()

    if (isDesktop === undefined) {
        isDesktop = isDesktopPage(router.pathname)
    }

    return (
        <StylesProvider injectFirst>
            <MUIThemeProvider theme={themeMaterialUI}>
                <StyledThemeProvider theme={customThemeStyled(isDesktop)}>
                    <GlobalStyles />
                    {props.children}
                </StyledThemeProvider>
            </MUIThemeProvider>
        </StylesProvider>
    )
}
