import React, { ReactNode } from 'react'
import Slide from '@material-ui/core/Slide'
import { TransitionProps } from '@material-ui/core/transitions'
import * as Styled from './styles'
import { DialogProps } from '@material-ui/core'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} timeout={300} {...props} />
})

type ContextDialogType = {
    children?: ReactNode
} & DialogProps

export const ContextDialog = ({
    children,
    open,
    ...dialogProps
}: ContextDialogType): JSX.Element => {
    return open ? (
        <Styled.Dialog
            data-testid="dialog-card"
            TransitionComponent={Transition}
            keepMounted
            fullWidth
            open={open}
            {...dialogProps}
        >
            <Styled.DialogContent>{children}</Styled.DialogContent>
        </Styled.Dialog>
    ) : (
        <></>
    )
}
