import { DEFAULT_SORT_BY } from '@components/ResultsContainer/constants'
import { FIRST_PAGE } from '@constants/pagination'
import { ENABLED_SORT_OPTIONS } from '@constants/sort'
import {
    DEFAULT_DEPARTMENT_SORT_BY_PROD_CODE,
    DEFAULT_DEPARTMENT_SORT_BY_QA_CODE
} from '@features/department/constants'
import { isHotsiteLink } from '@features/hotsite/static/utils'
import { GetProductsByHotsitePayload } from '@store/modules/hotsite/types'
import {
    BannerPositions,
    GetProductsBySearchPayload,
    SearchImage,
    SearchSortOption
} from '@store/modules/search/types'
import { excludeEmptyObjectKeys, excludeObjectKeys } from '@utils/objectUtils'
import { decodeUrl, getSafePathName } from '@utils/url'
import router from 'next/router'

export const formatDepartmentSlug = (slug: string | string[]) => {
    if (!Array.isArray(slug)) return slug

    try {
        decodeUrl(slug.at(-1) || '')
        return slug.slice(0, -1).join('/')
    } catch {
        return slug.join('/')
    }
}

export const formatHotsiteSlug = (slug: string | string[]) => {
    if (!Array.isArray(slug)) return slug.split('/').at(0)

    return slug.at(0)
}

const handleResultsContainerRedirect = (
    newQueryParams: Partial<
        GetProductsBySearchPayload | GetProductsByHotsitePayload
    >
) => {
    const lastQueryParams = router.query

    const queryWithCorrectSlug = {
        ...lastQueryParams,
        ...newQueryParams,
        slug: isHotsiteLink(router.pathname)
            ? formatHotsiteSlug(router.query.slug || '')
            : formatDepartmentSlug(router.query.slug || '')
    }

    router.push(
        {
            query: removeDefaultsFromQuery(queryWithCorrectSlug),
            pathname: getSafePathName(router.pathname)
        },
        undefined,
        { scroll: true }
    )
}

export const changeSortBy = (sortByMethod?: string) =>
    handleResultsContainerRedirect({ sortBy: sortByMethod, page: FIRST_PAGE })

export const changeFilters = (filters?: string[]) =>
    handleResultsContainerRedirect({ filter: filters, page: FIRST_PAGE })

export const castToNumberOrEmptyIfEqualsZero = (price: string) => {
    const numericalPrice = Number(price)

    if (numericalPrice === 0) return ''
    return numericalPrice
}

export const getAllowedSortByOptions = (sortOptions: SearchSortOption[]) => {
    return sortOptions.filter(sortOption =>
        Object.values(ENABLED_SORT_OPTIONS).includes(
            sortOption.name as ENABLED_SORT_OPTIONS
        )
    )
}

export const removeDefaultsFromQuery = (query: Record<string, any>) => {
    const keysToExclude: string[] = []

    if (query.page == FIRST_PAGE) keysToExclude.push('page')
    if (
        query.sortBy == DEFAULT_SORT_BY ||
        query.sortBy == DEFAULT_DEPARTMENT_SORT_BY_QA_CODE ||
        query.sortBy == DEFAULT_DEPARTMENT_SORT_BY_PROD_CODE
    )
        keysToExclude.push('sortBy')

    if (
        query.filter &&
        typeof query.filter === 'object' &&
        !query.filter.length
    )
        keysToExclude.push('filter')

    return excludeObjectKeys(excludeEmptyObjectKeys(query), keysToExclude)
}

export const getBannerByPosition = (
    banners: SearchImage[] | undefined,
    position: BannerPositions
) => {
    if (!banners) return null
    return banners.find(banner => banner.position === position) || null
}

export const parseContent = (content?: string) => {
    try {
        return JSON.parse(content ?? '')
    } catch {
        return content
    }
}

export type BannerHtmlContent = {
    image: string
    clickUrl?: string
}

export const getContentByDevice = (
    content: {
        desktop?: BannerHtmlContent
        mobile?: BannerHtmlContent
    },
    isDesktop: boolean
) => {
    if (isDesktop) return content.desktop ?? null
    else return content.mobile ?? null
}

export const getBannerContent = (
    bannerData?: SearchImage | null,
    isDesktop = false
) => {
    if (bannerData)
        return getContentByDevice(parseContent(bannerData.content), isDesktop)
}
