import { DeviceType } from '@static/types'
import { ShowcasesData } from '@store/modules/recommendations/types'
import { GetStaticProps } from 'next'

export enum ErrorType {
    GENERIC = 1,
    NOT_FOUND = 2,
    CLIENT = 3
}

export type StaticErrorProps = {
    staticRecommendations: ShowcasesData | null
    errorType?: ErrorType
}

export type GetStaticErrorProps = (
    props?: DeviceType
) => GetStaticProps<StaticErrorProps>
