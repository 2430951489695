import { shouldValidateUserSession } from '@components/Layout/utils'
import { MANDATORY_PASSWORD_CHANGE } from '@constants/dialog'
import { getErrorMessage, getRedirectDestination } from '@utils/errorUtils'
import axios from 'axios'
import { appActions } from './modules/app/slice'
import { store } from './modules/store'

export type ErrorModalVisibility = 'always' | 'never' | 'onEmptyMessage'

type ErrorOptions = {
    onError?: any
    visibility?: ErrorModalVisibility
}

export const getVisibility = (message: string, options?: ErrorOptions) => {
    const defaultVisibility = 'always'
    if (message.includes(MANDATORY_PASSWORD_CHANGE)) {
        if (shouldValidateUserSession()) return defaultVisibility
        else return 'never'
    }
    return options?.visibility || defaultVisibility
}

export const handleErrorResponse = (error: any, options?: ErrorOptions) => {
    if (axios.isAxiosError(error) && error.response?.status === 401) return

    const redirectDestination = getRedirectDestination(error)

    const message = getErrorMessage(error)
    const visibility = getVisibility(message, options)
    if (
        visibility === 'always' ||
        (visibility === 'onEmptyMessage' && !message)
    )
        store.dispatch(appActions.showError(message))
    if (!message.includes(MANDATORY_PASSWORD_CHANGE))
        options?.onError && options.onError(message, redirectDestination)
}
