import { StyledProps } from '@components/Stack/StackStyles'
import * as Styled from './styles'

type AdaptiveContainerProps = {
    children?: React.ReactNode
    maxWidth?: Styled.BreakpointNames
    minWidth?: Styled.BreakpointNames
} & StyledProps

export const AdaptiveContainerView = ({
    children,
    maxWidth = 'lg',
    minWidth = 'xs',
    direction = 'row',
    ...props
}: AdaptiveContainerProps): JSX.Element => {
    return (
        <Styled.Container className="adaptive-container">
            <Styled.AdaptiveStack
                $maxWidth={maxWidth}
                $minWidth={minWidth}
                direction={direction}
                {...props}
            >
                {children}
            </Styled.AdaptiveStack>
        </Styled.Container>
    )
}
