import { useState } from 'react'
import { CustomPopoverProps } from './view'

export const customPopoverIO = ({
    isOpen,
    handlePopover
}: CustomPopoverProps) => {
    const [anchorElement, setAnchorElement] =
        useState<HTMLButtonElement | null>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElement(event.currentTarget)
        handlePopover?.(true)
    }

    const handleClose = () => {
        setAnchorElement(null)
        handlePopover?.(false)
    }

    const openResult = isOpen === undefined ? Boolean(anchorElement) : isOpen

    const open = openResult
    const id = open ? 'simple-popover' : undefined

    return {
        anchorElement,
        open,
        id,
        handleClose,
        handleClick
    }
}

export type CustomPopoverIO = ReturnType<typeof customPopoverIO>
