import { CustomImage } from '@components/CustomImage'
import { CustomLink } from '@components/CustomLink'
import { Routes } from '@service/routes'
import * as Styled from './styles'

type ProductAutocompleteProps = {
    url: string
    name: string
    clickUrl: string
    priceFrom: string
    thumbnail: string
    currentPrice: string
    sku: string
}

export const ProductSearchView = ({
    url,
    name,
    clickUrl,
    thumbnail,
    sku
}: ProductAutocompleteProps): JSX.Element => {
    return (
        <CustomLink
            idName={'ac-btn_sku' + sku}
            as={url}
            href={`${Routes.productDetails}[slug].tsx`}
        >
            <Styled.Container
                container
                wrap="nowrap"
                alignItems="center"
                onClick={() => clickUrl}
                justifyContent="space-between"
            >
                <Styled.GridWrapper
                    item
                    xs={2}
                    container
                    wrap="nowrap"
                    alignItems="center"
                >
                    <div>
                        <Styled.ImageContainer>
                            {thumbnail && (
                                <CustomImage
                                    alt={name}
                                    width={84}
                                    height={84}
                                    src={thumbnail}
                                    layout="responsive"
                                />
                            )}
                        </Styled.ImageContainer>
                    </div>
                </Styled.GridWrapper>

                <Styled.ProductNameContainer xs={6}>
                    <Styled.ProductName>{name}</Styled.ProductName>
                </Styled.ProductNameContainer>

                <Styled.PriceContainer item xs={3} container direction="column">
                    {/* NOSONAR TODO: Verificar se realmente esse componente não terá mais os preços */}
                    {/* {priceFrom && (
                        <Styled.OldPriceText>
                            {`R$ ${formatPriceToPtBr(priceFrom)}`}
                        </Styled.OldPriceText>
                    )}

                    <Styled.CurrentPriceText>
                        {` R$ ${formatPriceToPtBr(currentPrice)}`}
                    </Styled.CurrentPriceText> */}
                </Styled.PriceContainer>
            </Styled.Container>
        </CustomLink>
    )
}
