export const GenericLargeErrorIcon = () => (
    <svg
        width="81"
        height="64"
        viewBox="0 0 81 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M76.2809 21.2788H4.5568C2.28499 21.2788 0.5 23.243 0.5 25.5345C0.5 27.9898 2.28499 29.7903 4.5568 29.7903H5.85497L12.6704 58.5985C12.6704 58.5985 13.644 64 19.9726 64C25.4899 64 37.0112 64 40.0943 64C40.5811 64 40.9057 64 40.9057 64C43.9888 64 55.5101 64 61.0274 64C67.356 64 68.3296 58.5985 68.3296 58.5985L75.145 29.7903H76.4432C78.715 29.7903 80.5 27.8261 80.5 25.5345C80.3377 23.0793 78.3905 21.2788 76.2809 21.2788ZM39.6075 31.5908C54.5365 31.5908 60.216 44.8491 61.1897 51.7238C61.3519 53.1969 60.3783 54.6701 58.7556 54.8338C57.2951 54.9975 55.8347 54.0154 55.6724 52.3785C55.5101 51.7238 53.4006 37.156 39.6075 37.156C25.9767 37.156 25.3276 51.5601 25.3276 52.0512C25.3276 53.688 24.0294 54.8338 22.4067 54.8338C20.784 54.8338 19.6481 53.5243 19.6481 51.8875C19.9726 44.8491 24.5162 31.5908 39.6075 31.5908Z"
            fill="#8F9DA3"
        />
        <path
            d="M31.9807 0.163683C31.6562 -1.21953e-08 31.3316 0 31.0071 0C29.3844 0 27.9239 1.14578 27.5994 2.61893L24.8408 12.2762C24.354 14.2404 25.4899 16.2046 27.2748 16.6957C29.2221 17.1867 31.1694 16.0409 31.6562 14.2404L34.4148 4.58312C34.5771 3.60102 34.5771 2.61893 34.0903 1.80051C33.7657 0.982097 32.9544 0.327366 31.9807 0.163683Z"
            fill="#8F9DA3"
        />
        <path
            d="M49.6684 0C49.3438 0 49.0193 -1.21953e-08 48.6947 0.163683C47.7211 0.491049 47.072 0.982097 46.5852 1.80051C46.0984 2.61893 45.9361 3.60102 46.2607 4.58312L49.0193 14.2404C49.5061 16.0409 51.4534 17.3504 53.4006 16.8593C55.3479 16.3683 56.4838 14.4041 55.8347 12.4399L53.2383 2.61893C52.7515 1.14578 51.2911 0 49.6684 0Z"
            fill="#8F9DA3"
        />
    </svg>
)
