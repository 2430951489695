import { CampaignOnClickAction } from '@store/modules/home/types'
import { getURLSafe, getUrlWithoutHost } from '@utils/url'
import { DefaultTheme } from 'styled-components'

export const getIconSize = ({ theme }: { theme: DefaultTheme }) => {
    if (theme.isDesktop) {
        return { width: 54, height: 54 }
    } else return { width: 40, height: 40 }
}

const getRedirectLink = (
    acaoClick: CampaignOnClickAction | string,
    link: string
): string => {
    const url = getURLSafe(link)

    switch (acaoClick) {
        case 'produto':
        case 'categoria':
        case 'hotsite_neemu':
        case 'busca_neemu':
            return getUrlWithoutHost(url)
        case 'open_webview':
        case 'open_url':
            return link
        default:
            return ''
    }
}

export { getRedirectLink }
