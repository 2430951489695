import { CustomPopover } from '@components/Layout/components/CustomPopover'
import dynamic from 'next/dynamic'
import { MenuButtonProps } from '../types'
import * as Styled from './styles'

const PopoverMenuContent = dynamic(
    () =>
        import('../components/PopoverMenuContent/index').then(
            module => module.PopoverMenuContent
        ),
    { ssr: false }
)

export const MenuButtonDesktopView = ({
    categoriesData
}: MenuButtonProps): JSX.Element => {
    return (
        <CustomPopover
            buttonContent={<Styled.MenuIcon />}
            popoverContent={
                <Styled.Container>
                    <PopoverMenuContent categoriesData={categoriesData} />
                </Styled.Container>
            }
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
        />
    )
}
