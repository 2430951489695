import { useHideOnScroll } from '@hooks/useHideOnScroll'
import { Routes } from '@service/routes'
import { appActions } from '@store/modules/app/slice'
import { useAppSelector } from '@store/modules/store'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const assets = {
    Logo: '/img/navbarlogo_white.png'
}

export const completeHeaderIO = () => {
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false)
    const [userNameClient, setUserNameClient] = useState<string>('')
    const [userNameTelevendedor, setUserNameTelevendedor] = useState<string>('')
    const [isTelevendedor, setIsTelevendedor] = useState(false)

    const personalizations = useAppSelector(
        state => state.user.personalizations
    )
    const numberOfItemsInCart = useAppSelector(
        state => state.cart.numberOfItemsInCart
    )

    const { hideComponent } = useHideOnScroll()
    const dispatch = useDispatch()

    useEffect(() => {
        setUserNameClient(personalizations.nomeCompleto)
        setUserNameTelevendedor(personalizations.nomeTelevendedor)
        setIsTelevendedor(Boolean(personalizations.nomeTelevendedor?.length))
    }, [personalizations.nomeCompleto, personalizations.nomeTelevendedor])

    const setRouteToFavorites = async () => {
        dispatch(appActions.setCurrentRoute(Routes.favorite))
    }

    return {
        setRouteToFavorites,
        assets,
        isSideMenuOpen,
        personalizations,
        setIsSideMenuOpen,
        numberOfItemsInCart,
        hideComponent,
        userNameClient,
        userNameTelevendedor,
        isTelevendedor
    }
}

export type CompleteHeaderIO = ReturnType<typeof completeHeaderIO>
