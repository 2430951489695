import { useDeviceContext } from '@contexts/device'
import { MenuButtonDesktop } from './desktop'
import { MenuButtonMobile } from './mobile'
import { MenuButtonProps } from './types'

export const MenuButton = (props: MenuButtonProps) => {
    const { isDesktop } = useDeviceContext()

    return isDesktop ? (
        <MenuButtonDesktop {...props} />
    ) : (
        <MenuButtonMobile {...props} />
    )
}
