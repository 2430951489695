import { DEFAULT_ERROR_DESCRIPTION } from '@constants/dialog'
import { DialogConfigType, useDialogContext } from '@contexts/dialog'
import { ConfirmationModal } from './components/ConfirmationModal/ConfirmationModal'
import { ConfirmationModalVariant } from './components/ConfirmationModal/types'
import { InfoModal } from './components/InfoModal/view'

type DialogContentType = {
    title: string
    content: string | React.ReactNode
    confirmationButtonText?: string
    confirmationButtonColor?: 'primary' | 'secondary' | 'green'
    alternativeButtonText?: string
    alternativeButtonColor?: 'primary' | 'secondary' | 'green'
    cancelButtonText?: string
    onConfirm?: () => void
    onAlternative?: () => void
    onClose?: () => void
}

type ConfirmationDialogContentType = DialogContentType & {
    variant?: ConfirmationModalVariant
}

function useDialog() {
    const { showContextDialog, hideContextDialog } = useDialogContext()

    const handleSubmit = (onConfirm?: () => void): void => {
        if (onConfirm) onConfirm()
        hideContextDialog('confirm')
    }

    const handleClose = (onClose?: () => void): void => {
        if (onClose) onClose()
        hideContextDialog('cancel')
    }

    const showInfoDialog = (
        dialogContent: Omit<DialogContentType, 'cancelButtonText'>,
        config?: DialogConfigType
    ) => {
        return showContextDialog(
            <InfoModal
                description={dialogContent.content}
                title={dialogContent.title}
                confirmationButtonText={dialogContent.confirmationButtonText}
                confirmationButtonColor={dialogContent.confirmationButtonColor}
                alternativeButtonColor={dialogContent.alternativeButtonColor}
                alternativeButtonText={dialogContent.alternativeButtonText}
                handleSubmit={() => handleSubmit(dialogContent.onConfirm)}
                handleAlternative={() =>
                    handleSubmit(dialogContent.onAlternative)
                }
                handleClose={() => handleClose(dialogContent.onClose)}
                closable={config?.closable}
            />,
            config
        )
    }

    const showConfirmationDialog = (
        dialogContent: ConfirmationDialogContentType,
        config?: DialogConfigType
    ) =>
        showContextDialog(
            <ConfirmationModal
                title={dialogContent.title}
                content={dialogContent.content}
                confirmationButtonText={
                    dialogContent.confirmationButtonText || 'Continuar'
                }
                cancelButtonText={dialogContent.cancelButtonText || 'Cancelar'}
                handleSubmit={() => handleSubmit(dialogContent.onConfirm)}
                handleClose={() => handleClose(dialogContent.onClose)}
                variant={dialogContent.variant}
                closable={config?.closable}
            />,
            config
        )

    const showErrorDialog = (
        dialogContent?: Partial<
            Pick<DialogContentType, 'onConfirm' | 'content'>
        >,
        config?: DialogConfigType
    ) =>
        showInfoDialog(
            {
                title: 'Ops...',
                content: dialogContent?.content || DEFAULT_ERROR_DESCRIPTION,
                confirmationButtonText: 'Entendi',
                onConfirm: dialogContent?.onConfirm,
                onClose: dialogContent?.onConfirm
            },
            config
        )

    return {
        showInfoDialog,
        showConfirmationDialog,
        showErrorDialog
    }
}

export default useDialog
