import {
    SRC_TRUSTVOX_RATING_PRODUCTION,
    SRC_TRUSTVOX_RATING_STAGING
} from './constants'

export const ProductRatingScript = () => {
    const ProductRatingScriptInStaging = (
        <script
            type="text/javascript"
            async
            src={SRC_TRUSTVOX_RATING_STAGING}
        ></script>
    )

    const ProductRatingScriptInProduction = (
        <script
            type="text/javascript"
            async
            src={SRC_TRUSTVOX_RATING_PRODUCTION}
        ></script>
    )

    return (
        <>
            {process.env.NEXT_PUBLIC_SHOW_PRODUCT_STAGING_REVIEWS === 'true'
                ? ProductRatingScriptInStaging
                : ProductRatingScriptInProduction}
        </>
    )
}
