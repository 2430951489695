import { Stack } from '@components/Stack'
import { DESKTOP_SIZE } from '@constants/device'
import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    display: flex;
    height: 80px;
    padding: 16px 20px;
    justify-content: center;
    background-color: ${({ theme }) =>
        process.env.NEXT_PUBLIC_IS_BLACK_FRIDAY === 'true'
            ? theme.colors.dark[1000]
            : theme.colors.primary[500]};
`

export const MaxWidth = styled(Stack).attrs({
    justifyContent: 'center',
    alignItems: 'center',
    direction: 'row',
    spacing: 32
})`
    width: ${DESKTOP_SIZE}px;
`

export const Block = styled.div`
    display: block;
`

export const FavoritesAndCartContainer = styled(Stack).attrs({
    direction: 'row',
    spacing: 16,
    justifyContent: 'center',
    alignItems: 'center'
})``
