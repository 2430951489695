export const pickObjectKeys = <T, K extends keyof T>(
    obj: T,
    keys: K[]
): Pick<T, K> => Object.fromEntries(keys.map(key => [key, obj[key]])) as any

export const excludeObjectKeys = <T, K extends string>(
    { ...obj }: T,
    keys: K[]
): Exclude<T, K> => {
    keys.forEach(key => {
        {
            delete obj[key as unknown as keyof T]
        }
    })
    return obj as any
}

export const excludeEmptyObjectKeys = <T extends Record<any, any>>(
    obj: T
): T => {
    const keys = Object.keys(obj) as (keyof T)[]
    return excludeObjectKeys(
        obj,
        keys.filter(
            key =>
                obj[key] === undefined || obj[key] === null || obj[key] === ''
        ) as string[]
    )
}
