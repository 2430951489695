import { CookiesIcon } from '@assets/svg/CookiesIcon'
import { Stack } from '@components/Stack'
import { Typography } from '@components/ui/Typography'
import { getIconSize } from '@features/home/utils'
import { IconProps } from 'src/types/icons'
import styled, { css } from 'styled-components'

const DesktopContainer = css`
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 1024px;
    height: 120px;
    left: 50%;
    transform: translate(-50%, 0);
    margin: 16px;
`

const MobileContainer = css`
    flex-direction: column;
    justify-content: space-around;
    width: auto;
    height: 180px;
    margin: 16px;
    padding: 20px;
`

export const Container = styled(Stack)`
    position: fixed;
    z-index: 10000;
    bottom: 0;
    border-radius: 4px;
    display: flex;
    background-color: ${({ theme }) => theme.colors.white};
    ${({ theme }) => (theme.isDesktop ? DesktopContainer : MobileContainer)}
`

export const TextIconContainer = styled(Stack).attrs({
    direction: 'row'
})`
    justify-content: space-between;
    align-items: ${({ theme }) => (theme.isDesktop ? 'center' : 'flex-start')};
    width: ${({ theme }) => theme.isDesktop && '80%'};
`

export const Text = styled(Typography).attrs(({ theme, color }) => ({
    fontFamily: 'regular',
    color: color || theme.colors.gray[500],
    size: theme.isDesktop ? 'sm' : 'xs'
}))`
    width: ${({ theme }) => (theme.isDesktop ? '723px' : '80%')};
`

export const CookiesSvgIcon = styled(CookiesIcon).attrs<IconProps>(props => ({
    ...getIconSize(props)
}))``
