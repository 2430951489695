import { Stack } from '@components/Stack'
import { Typography } from '@components/ui/Typography'
import styled from 'styled-components'

export const Title = styled(Typography).attrs(({ theme }) => ({
    size: theme.isDesktop ? 'sm' : 'xs',
    fontFamily: 'medium'
}))`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
`

export const Link = styled(Typography).attrs(({ theme }) => ({
    size: theme.isDesktop ? 'sm' : 'xs',
    variant: 'paragraph',
    fontFamily: 'regular'
}))`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const Container = styled(Stack).attrs({
    direction: 'row',
    spacing: 16,
    justifyContent: 'center',
    fullWidth: true
})`
    > div {
        height: 42px;
    }
`
