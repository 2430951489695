import styled from 'styled-components'

type ContainerProps = {
    reverse?: boolean
}

export const Container = styled.div<ContainerProps>`
    position: relative;
    display: flex;
    flex-direction: ${({ reverse }) => (reverse ? 'column-reverse' : 'column')};
`
