import { ThunkOptions } from '@store/types'
import { handleErrorResponse } from '@store/utils'
import { AppThunk } from '../store'
import { userActions } from '../user/slice'
import { getRecommendations } from './services'
import { RecommendationsThunkPayload, ShowcasesData } from './types'

export const getRecommendationsThunk =
    (
        {
            name,
            categoryId,
            productId,
            desktop,
            cartItens
        }: RecommendationsThunkPayload,
        { onSuccess, onError }: ThunkOptions<ShowcasesData>
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(userActions.createDeviceIdIfDoesntExists())
            const content = await getRecommendations({
                name,
                source: desktop ? 'desktop' : 'mobile',
                deviceId: getState().user.deviceId,
                categoryId,
                userId: getState().user.userId,
                productId,
                cartItens
            })
            onSuccess && onSuccess(content)
        } catch (error: any) {
            handleErrorResponse(error, {
                onError,
                visibility: 'never'
            })
        }
    }
