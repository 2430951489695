import { Stack } from '@components/Stack'
import MuiAppBar from '@material-ui/core/AppBar'
import styled from 'styled-components'

type StyledAppBarProps = {
    $contentTopPadding?: ContentPaddingSizesType
}

const contentPaddingSizes = {
    none: '0',
    sm: '80px',
    lg: '158px'
} as const

export type ContentPaddingSizesType = keyof typeof contentPaddingSizes

const getContentTopPadding = (size: ContentPaddingSizesType) =>
    contentPaddingSizes[size]

export const Main = styled.main<StyledAppBarProps>`
    min-width: 100%;
    padding-top: ${({ theme, $contentTopPadding }) =>
        theme.isDesktop && $contentTopPadding
            ? getContentTopPadding($contentTopPadding)
            : '0'};
`

export const AppBar = styled(MuiAppBar)`
    background: ${({ theme }) => theme.colors.white};
`

export const FixedContainer = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 500;
    border: 1px solid ${({ theme }) => theme.colors.boxShadow};
`

type StyledGridProps = {
    $paddingBottom: number
}

export const Container = styled(Stack)<StyledGridProps>`
    padding-bottom: ${({ $paddingBottom }) => $paddingBottom}px;
`
