import { ArrowDownIcon } from '@assets/svg/ArrowIcons/ArrowDownIcon'
import { CustomLink } from '@components/CustomLink'
import { CustomPopover } from '@components/Layout/components/CustomPopover'
import { Stack } from '@components/Stack'
import { Routes } from '@service/routes'
import { IOProps } from 'react-compose-io'
import { UserAvatar } from '../UserAvatar'
import { LoggedInUserIO } from './io'
import * as Styled from './styles'

type LoggedInUserProps = {
    userName: string
}

export const LoggedInUserView = ({
    _io,
    userName
}: IOProps<LoggedInUserIO, LoggedInUserProps>): JSX.Element => {
    return (
        <Styled.Container>
            <UserAvatar />
            <Stack>
                <Styled.Greeting>Olá,</Styled.Greeting>
                <Styled.UserNameContainer>
                    <CustomPopover
                        buttonContent={
                            <Styled.UserNameButton>
                                <Styled.UserName>{userName}</Styled.UserName>
                                <ArrowDownIcon />
                            </Styled.UserNameButton>
                        }
                        popoverContent={
                            <Styled.PopoverContent>
                                <CustomLink
                                    href={Routes.orders}
                                    target="_parent"
                                >
                                    <Styled.PopoverText>
                                        Meus pedidos
                                    </Styled.PopoverText>
                                </CustomLink>
                                <CustomLink
                                    href={Routes.myAccount}
                                    target="_parent"
                                >
                                    <Styled.PopoverText>
                                        Minha conta
                                    </Styled.PopoverText>
                                </CustomLink>
                                <Styled.LogoutButton onClick={_io.handleLogout}>
                                    <Styled.PopoverText>
                                        Sair
                                    </Styled.PopoverText>
                                </Styled.LogoutButton>
                            </Styled.PopoverContent>
                        }
                    />
                </Styled.UserNameContainer>
            </Stack>
        </Styled.Container>
    )
}
