import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { countProducts } from '@utils/cart'
import { Cart, CartState } from './types'

const initialState: CartState = {
    data: {
        numeroPedido: '',
        itensPedido: [],
        valorTotalProdutosText: '',
        saldoPontos: '',
        valorTotalPedidoText: '',
        valorTotalDescontosText: '',
        cupons: [],
        valorTotalFreteText: '',
        usuarioDePontos: false,
        valorTotalProdutos: '',
        valorTotalEmPontos: 0,
        informacaoEntrega: {}
    },
    numberOfItemsInCart: 0
}

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        clearCartData() {
            return initialState
        },
        setNumberOfItemsInCart(state, action: PayloadAction<number>) {
            state.numberOfItemsInCart = action.payload
        },
        _onUpdateCartSuccess(state, action: PayloadAction<Cart>) {
            state.data = action.payload
            state.numberOfItemsInCart = countProducts(
                action.payload.itensPedido
            )
        }
    }
})

export const cartActions = cartSlice.actions

export default cartSlice
