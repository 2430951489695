import {
    AnyAction,
    combineReducers,
    configureStore,
    ThunkAction
} from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import userSlice from './user/slice'
import appSlice from './app/slice'
import authSlice from './auth/slice'
import cartSlice from './cart/slice'
import checkoutSlice from './checkout/slice'
import favoritesSlice from './favorites/slice'
import productSlice from './product/slice'

const persistConfig = {
    key: 'appEfacil',
    storage,
    whitelist: ['auth', 'user', 'checkout']
}

const combinedReducers = combineReducers({
    app: appSlice.reducer,
    auth: authSlice.reducer,
    user: userSlice.reducer,
    cart: cartSlice.reducer,
    checkout: checkoutSlice.reducer,
    favorites: favoritesSlice.reducer,
    products: productSlice.reducer
})

const persistedReducer = persistReducer(persistConfig, combinedReducers)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER
                ]
            }
        })
    }
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    AnyAction
>

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>()
