import { CartLink } from '@components/Layout/components/CartLink'
import { Logo } from '@components/Layout/components/Logo'
import { MenuButton } from '@components/Layout/components/menu/MenuButton'
import { SearchBar } from '@components/Layout/components/SearchBar'
import { Stack } from '@components/Stack'
import { CategoriesBasePageProps } from '@static/types'
import { IOProps } from 'react-compose-io'
import { CompleteHeaderIO } from '../io'
import * as Styled from './styles'
import { ErrorBoundary } from 'react-error-boundary'

export const CompleteHeaderMobileView = ({
    categoriesData,
    _io
}: IOProps<CompleteHeaderIO, CategoriesBasePageProps>): JSX.Element => {
    return (
        <ErrorBoundary
            fallback={<></>}
            onError={error => console.error('Client Side Error:', error)}
        >
            <Styled.HeaderContainer fullWidth>
                <Stack direction="row" justifyContent="space-between" fullWidth>
                    <Logo
                        assets={_io.assets}
                        layoutClassName="completeHeader"
                    />
                    <Styled.FavoritesAndCartContainer>
                        <CartLink
                            numberOfItemsInCart={_io.numberOfItemsInCart}
                        />
                        <MenuButton
                            categoriesData={categoriesData}
                            isSideMenuOpen={_io.isSideMenuOpen}
                            onToggleMenu={_io.setIsSideMenuOpen}
                            personalizations={_io.personalizations?.nome}
                        />
                    </Styled.FavoritesAndCartContainer>
                </Stack>
                <SearchBar />
            </Styled.HeaderContainer>
        </ErrorBoundary>
    )
}
