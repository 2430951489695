import { EXTERNAL_LINKS } from '@constants/common'
import { ITEMS_PER_PAGE } from '@constants/pagination'
import api from '@service/index'
import axios, { RawAxiosRequestHeaders } from 'axios'
import { formatDepartmentResponse } from './adpter'
import {
    GetAutocompletesPayload,
    GetProductsBySearchPayload,
    PopularSearches,
    SearchAutoCompleteLinx,
    SearchData
} from './types'

export const getSearchProducts = async (
    {
        page = 1,
        resultsPerPage = ITEMS_PER_PAGE,
        searchTerm,
        allowRedirect,
        ...payload
    }: GetProductsBySearchPayload,
    headers?: RawAxiosRequestHeaders,
    useSolr = 'false'
): Promise<SearchData> => {
    if (useSolr === 'false')
        return getProductsBySearchV2(
            {
                page,
                resultsPerPage,
                searchTerm,
                allowRedirect,
                ...payload
            },
            headers
        )
    else
        return getProductsBySearch(
            {
                page,
                resultsPerPage,
                searchTerm,
                allowRedirect,
                ...payload
            },
            headers
        )
}

const getProductsBySearch = async (
    {
        page,
        resultsPerPage,
        searchTerm,
        ...payload
    }: GetProductsBySearchPayload,
    headers?: RawAxiosRequestHeaders
): Promise<SearchData> => {
    return api
        .get<SearchData | undefined>(`catalog/search/native`, {
            params: {
                ...payload,
                terms: encodeURIComponent(searchTerm),
                page,
                resultsPerPage
            },
            headers
        })
        .then(({ data }) => formatDepartmentResponse(data) as SearchData)
}

const getProductsBySearchV2 = async (
    {
        page,
        resultsPerPage,
        searchTerm,
        allowRedirect,
        ...payload
    }: GetProductsBySearchPayload,
    headers?: RawAxiosRequestHeaders
): Promise<SearchData> => {
    return api
        .get<SearchData | undefined>(`catalog/search/v2`, {
            params: {
                ...payload,
                terms: encodeURIComponent(searchTerm),
                page,
                resultsPerPage,
                allowRedirect
            },
            headers
        })
        .then(({ data }) => formatDepartmentResponse(data) as SearchData)
}

export const getAutocompletes = async ({
    terms,
    resultsProducts = 5,
    userId,
    deviceId
}: GetAutocompletesPayload) => {
    return axios
        .get<SearchAutoCompleteLinx>(
            `${EXTERNAL_LINKS.linx}v3/autocompletes/`,
            {
                params: {
                    terms,
                    prefix: terms,
                    resultsProducts,
                    ...(userId && { userId: userId }),
                    deviceId,
                    apiKey: process.env.NEXT_PUBLIC_LINX_API_KEY,
                    origin: process.env.NEXT_PUBLIC_CURRENT_SITE_URL
                }
            }
        )
        .then(res => res.data)
}

export const getPopularSearches = async () => {
    return api
        .get<PopularSearches>(`catalog/search/autocompletes/popular`)
        .then(res => res.data)
}
