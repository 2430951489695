import { Stack } from '@components/Stack'
import { Typography } from '@components/ui/Typography'
import Search from '@material-ui/icons/Search'
import styled from 'styled-components'

export const BackgroundContainer = styled(Stack).attrs({
    alignItems: 'center',
    justifyContent: 'center'
})`
    position: fixed;
    overflow: auto;
    width: 100%;
    height: 100%;
    right: 0px;
    top: 0px;
    z-index: 10000;

    background: ${({ theme }) => theme.colors.backgroundShadow};
`

export const Container = styled(Stack).attrs({
    direction: 'column',
    alignItems: 'flex-start'
})`
    position: absolute;
    z-index: 10000;
    width: 564px;
    height: 541px;
    gap: 24px;
    padding: 32px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.white};
`

export const HeaderContainer = styled(Stack).attrs({
    direction: 'column',
    alignItems: 'flex-start'
})`
    width: 500px;
    gap: 12px;
`
export const TitleContainer = styled(Stack).attrs({
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
})`
    width: 100%;
`

export const Title = styled(Typography).attrs(({ theme }) => ({
    size: 'xl2',
    variant: 'paragraph',
    fontFamily: 'medium',
    color: theme.colors.gray[900]
}))``

export const CloseModalButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    color: ${({ theme }) => theme.colors.gray[900]};
`

export const Subtitle = styled(Typography).attrs(({ theme }) => ({
    size: 'base',
    variant: 'paragraph',
    fontFamily: 'regular',
    color: theme.colors.gray[500]
}))``

export const HorizontalLine = styled.div`
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.colors.gray[100]};
`

export const InputContainer = styled.div`
    position: relative;
    display: inline-block;
    width: 500px;
    height: 56px;
`

export const InputText = styled.input`
    overflow-y: hidden;
    resize: none;
    width: 500px;
    height: 56px;
    border-radius: 4px;
    padding: 16px 36px 16px 16px;
    border: 1px solid ${({ theme }) => theme.colors.gray[100]};
    font-family: ${({ theme }) => theme.fontFamily.sansSerif};
    font-weight: ${({ theme }) => theme.fontWeight.sansSerifRegular};
    color: ${({ theme }) => theme.colors.gray[500]};
    font-size: 16px;

    :focus {
        outline: 1px solid ${({ theme }) => theme.colors.primary[800]};
    }

    ::-webkit-scrollbar {
        width: 0px;
    }

    ::placeholder {
        position: absolute;
    }
`

export const SearchInputButton = styled.button`
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
`

export const SearchIcon = styled(Search)`
    width: 24px;
    height: 24px;
    margin: 0 12px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.gray[500]};
`

export const ChannelContainer = styled(Stack).attrs({})`
    gap: 12px;
    max-height: 288px;
    overflow-y: scroll;
    padding-right: 15px;

    ::-webkit-scrollbar {
        width: 2px;
        height: 89px;
    }

    ::-webkit-scrollbar-track {
        background-color: ${({ theme }) => theme.colors.gray[100]};
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: ${({ theme }) => theme.colors.primary[500]};
    }

    scrollbar-width: thin;
`

export const ChannelItem = styled.button`
    cursor: pointer;
    border: none;
    padding: 12px;
    width: 500px;
    border-radius: 4px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: ${({ theme }) => theme.colors.gray[500]};
    background-color: ${({ theme }) => theme.colors.gray[50]};

    :focus {
        background-color: ${({ theme }) => theme.colors.secondary[500]};
        color: ${({ theme }) => theme.colors.white};
    }
`
