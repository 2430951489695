import { useRouter } from 'next/router'
import packageData from '../../../package.json'
import { useLoadedEffect } from '../useLoadedEffect'

export function useGtmEventLoadedEffect() {
    const router = useRouter()

    const sendLoadedEffectEvent = () => {
        const typedWindow = window as any
        typedWindow.dataLayer = typedWindow.dataLayer || []

        typedWindow.dataLayer.push({
            event: 'next.loadedEffect',
            app_name: packageData.name,
            app_version: packageData.version,
            url: window.location.href
        })
    }

    useLoadedEffect(() => {
        // Envia evento next.loadedEffect em navegações Web
        sendLoadedEffectEvent()

        // Envia evento next.loadedEffect em navegações SPA
        router.events.on('routeChangeComplete', sendLoadedEffectEvent)

        return () => {
            router.events.off('routeChangeComplete', sendLoadedEffectEvent)
        }
    }, [])
}
