const DEFAULT_HOST = 'https://empty.com/'

/**
 * Retorna um objeto URL.
 * Aceita url completa ou apenas path como parâmetro.
 * Se passar apenas path, adiciona host default.
 */
export const getURLSafe = (urlOrPath: string): URL => {
    return new URL(urlOrPath, DEFAULT_HOST)
}

/**
 * Retorna o último recurso do path.
 * Aceita url completa ou apenas path como parâmetro.
 * Url sem path retorna string vazio.
 */
export const getLastPathPart = (urlOrPath: string): string => {
    const pathParts = getNonEmptyPathParts(urlOrPath)

    if (pathParts.length === 0) return ''

    return pathParts[pathParts.length - 1]
}

/**
 * Retorna as partes não vazias do path.
 * Aceita url completa ou apenas path como parâmetro.
 * Url sem path retorna array vazio.
 */
export const getNonEmptyPathParts = (urlOrPath: string): string[] => {
    const pathParts = getURLSafe(urlOrPath).pathname.split('/')

    if (!Array.isArray(pathParts)) return []

    return pathParts.filter(value => value)
}

export const getUrlWithoutHost = (url: URL | string) => {
    const urlObject = typeof url === 'string' ? getURLSafe(url) : url
    return urlObject.pathname + urlObject.search + urlObject.hash
}
export const getUrlPathname = (url: URL | string) => {
    if (typeof url === 'string') {
        return getURLSafe(url).pathname
    }
    return url.pathname
}

export const removeFirstSlashFromPath = (path: string) =>
    path.replace(/^\/+/, '')

export const getSafePathName = (path: string) => path.replace('/desktop', '')

// NOSONAR TODO: trocar pra string[]/number[]
export const makePath = (...paths: any[]) => paths.join('/')

export const parseQueryStringToObject = (
    queryString: string
): Record<string, string> => {
    const searchParams = new URLSearchParams(queryString)

    const queryObject: Record<string, string> = {}
    for (const [key, value] of searchParams) {
        queryObject[key] = value
    }
    return queryObject
}

export const parseObjectToQueryString = (
    queryObject: Record<string, string>
) => {
    const searchParams = new URLSearchParams()
    for (const key in queryObject) {
        searchParams.set(key, queryObject[key])
    }
    const queryString = searchParams.toString()

    if (queryString) return `?${queryString}`
    return ''
}

export function encodeUrl(options: any) {
    const json = JSON.stringify(options)
    return encodeURI(json)
}

export function decodeUrl(path: string) {
    return JSON.parse(decodeURI(path))
}

export function encodeFilterArray(
    filter?: string | string[] | null | undefined
) {
    const encodedArray: string[] = []

    if (filter) {
        if (typeof filter === 'string') {
            encodedArray.push(encodeURIComponent(filter))
        } else {
            filter?.forEach(value => {
                encodedArray.push(encodeURIComponent(value))
            })
        }
    }

    return encodedArray
}
