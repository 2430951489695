import { UserEmailModal } from '@features/myAccount/components/userDataPF/UserEmailModal/io'
import api from '@service/index'
import { pickObjectKeys } from '@utils/objectUtils'
import { AxiosResponse } from 'axios'
import {
    CNPJStatus,
    GetUserPoints,
    Personalizations,
    PFUserRegisterData,
    PJUserRegisterData,
    TelevendaChannel,
    UpdatedUserData,
    UserChangePasswordData,
    UserData,
    UserEmailMasked,
    UserPasswordData,
    UserPointsData
} from './types'

export const getPersonalizations = async () => {
    return api
        .get<Personalizations>('users/custom/personalizations')
        .then(res =>
            pickObjectKeys(res.data, [
                'badgeCarrinho',
                'nome',
                'nomeCompleto',
                'nomeTelevendedor'
            ])
        )
}

export const postUsersPF = async (data: PFUserRegisterData) => {
    return api.post('users', data).then(res => res.data)
}

export const postUsersPj = async (data: PJUserRegisterData) => {
    return api.post('users/pj', data).then(res => res.data)
}

export const updateUserPj = async (data: PJUserRegisterData) => {
    return api.put('users/pj', data).then(res => res.data)
}

export const getCNPJStatus = async (cnpj: string) => {
    return api
        .get<CNPJStatus>('users/validate', {
            params: {
                cpf_cnpj: cnpj
            }
        })
        .then(res => res.data)
}

export const getUserData = async () => {
    return api.get<UserData>('users').then(res => res.data)
}

export const setUserData = async (data: UpdatedUserData) => {
    return api.post('users/update', data).then(res => res.data)
}

export const setUserEmail = async (data: UserEmailModal) => {
    const requestBody = {
        emailAntigo: data.currentEmail,
        emailNovo: data.email,
        senha: data.senha
    }
    return api.post('users/email/update', requestBody).then(res => res.data)
}

export const sendLinkToUserEmail = async (login: string) => {
    const requestBody = {
        login: login
    }
    return api
        .post<AxiosResponse>('users/password', requestBody)
        .then(res => res.data)
}

export const getUserLogin = async (login: string) => {
    return api
        .get<UserEmailMasked>(`users/email?cpf_cnpj=${login}`)
        .then(res => res.data.emailComMascara)
}

export const setUserPassword = async (data: UserPasswordData) => {
    const requestBody = {
        senhaAntiga: data.senhaAntiga,
        senhaNova: data.senhaNova
    }
    return api.post('users/password/update', requestBody).then(res => res.data)
}

export const getUserPoints = async ({
    dataInicial,
    dataFinal
}: GetUserPoints) => {
    return api
        .get<UserPointsData>('users/points', {
            params: {
                dataInicial,
                dataFinal
            }
        })
        .then(res => res.data)
}

export const changePassword = async (passwords: UserChangePasswordData) => {
    return api
        .put('users/password', {
            senhaAntiga: passwords.currentPassword,
            senhaNova: passwords.newPassword
        })
        .then(res => res.data)
}

export const getTelevendasChannelData = async () => {
    return api
        .get<{ canais: TelevendaChannel[] }>('catalog/price/canais', {})
        .then(res => res.data.canais)
}

export const deactivateUserAccount = async () => {
    return api.post('users/deactivate').then(res => res.data)
}
