import { useDeviceContext } from '@contexts/device'
import { CookiesMessageModal } from '@features/home/components/CookiesMessageModal'
import React from 'react'
import { IOProps } from 'react-compose-io'
import { ErrorBoundary } from 'react-error-boundary'
import { SimpleHeader } from './components/header/SimpleHeader'
import { LayoutIO } from './io'
import * as Styled from './styles'
import dynamic from 'next/dynamic'

const LoadingOverlay = dynamic(
    () =>
        import('./components/LoadingOverlay').then(
            module => module.LoadingOverlay
        ),
    { ssr: true }
)

export type LayoutProps = {
    footer?: JSX.Element
    topBanner?: JSX.Element
    children: React.ReactNode
    navbarComponent: JSX.Element
    floatingElement?: React.ReactNode
    removeFloatingElementPadding?: boolean
    contentTopPadding?: Styled.ContentPaddingSizesType
}

export const LayoutView = ({
    footer,
    topBanner,
    children,
    navbarComponent,
    contentTopPadding,
    floatingElement,
    _io
}: IOProps<LayoutIO, LayoutProps>): JSX.Element => {
    const { isDesktop } = useDeviceContext()

    return (
        <Styled.Container
            fullWidth
            className="globalContainer"
            $paddingBottom={_io.paddingBottom}
        >
            <LoadingOverlay show={_io.loading} />

            <ErrorBoundary
                fallback={<SimpleHeader></SimpleHeader>}
                onError={error => console.error('Client Side Error:', error)}
            >
                <Styled.AppBar
                    position={isDesktop ? 'fixed' : 'static'}
                    elevation={0}
                    ref={_io.headerRef}
                >
                    {topBanner}
                    {navbarComponent}
                </Styled.AppBar>
            </ErrorBoundary>
            <Styled.Main $contentTopPadding={contentTopPadding}>
                {children}
            </Styled.Main>

            <ErrorBoundary
                fallback={<></>}
                onError={error => console.error('Client Side Error:', error)}
            >
                {footer}
            </ErrorBoundary>

            {floatingElement && (
                <Styled.FixedContainer ref={_io.fixedContainerRef}>
                    {floatingElement}
                </Styled.FixedContainer>
            )}
            <CookiesMessageModal />
        </Styled.Container>
    )
}
