import { sendImpressionEvent } from '@store/modules/recommendations/services'

export function useShowCaseImpression() {
    const observer = new IntersectionObserver(
        entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const entryImpressionUrl =
                        entry.target.getAttribute('data-impressionurl')

                    if (entryImpressionUrl != null) {
                        sendImpressionEvent(entryImpressionUrl)
                        observer.unobserve(entry.target)
                    }
                }
            })
        },
        { root: null, rootMargin: '0px', threshold: 0.7 }
    )

    document.querySelectorAll('.linx-showcase-container').forEach(element => {
        if (element) {
            observer.observe(element)
        }
    })
}
