import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'

export const Container = styled(Grid)`
    height: 40px;
    cursor: pointer;
    padding: 0 16px;

    :hover {
        border-radius: 5px;
        background-color: ${({ theme }) => theme.colors.gray[50]};
    }
`

export const ArrowIcon = styled(ArrowForwardIos)`
    width: 15px;
    height: 15px;
    color: ${({ theme }) => theme.colors.primary[800]};
`
