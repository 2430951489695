import { useLoading } from '@contexts/loading'
import { getLastPathPart } from '@utils/url'
import { DepartmentMenuItemProps } from './view'

export const departmentMenuItemIO = ({ link }: DepartmentMenuItemProps) => {
    const { showLoading } = useLoading()

    const handleOnClick = () => {
        if (getLastPathPart(window.location.pathname) !== getLastPathPart(link))
            showLoading()
    }

    return {
        handleOnClick
    }
}

export type DepartmentMenuItemIO = ReturnType<typeof departmentMenuItemIO>
