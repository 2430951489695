import { Routes } from '@service/routes'
import { getURLSafe } from '@utils/url'
import { CustomLink } from '@components/CustomLink'
import { IOProps } from 'react-compose-io'
import { MenuItem } from '../MenuItem'
import { MenuItemProps } from '../MenuItem/types'
import { DepartmentMenuItemIO } from './io'

export type DepartmentMenuItemProps = {
    link: string
} & MenuItemProps

export const DepartmentMenuItemView = ({
    link,
    _io,
    ...rest
}: IOProps<DepartmentMenuItemIO, DepartmentMenuItemProps>): JSX.Element => {
    if (!link) return <></>

    return (
        <CustomLink
            href={`${Routes.department}/[...slug].tsx`}
            as={getURLSafe(link).pathname}
            data-testid="linked-menu-item"
            onClick={_io.handleOnClick}
            target={'_parent'}
        >
            <MenuItem {...rest} />
        </CustomLink>
    )
}
