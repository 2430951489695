import { Stack } from '@components/Stack'
import { Typography } from '@components/ui/Typography'
import styled from 'styled-components'

export const Container = styled(Stack).attrs({
    spacing: 12
})`
    width: 100%;
    height: 92px;
    border-radius: 9px;
    padding: 16px;
`

export const PopoverItem = styled(Stack).attrs({
    direction: 'row',
    alignItems: 'center',
    spacing: 8
})`
    height: 24px;
`

export const PopoverText = styled(Typography).attrs(({ theme }) => ({
    size: 'sm',
    fontFamily: 'regular',
    color: theme.colors.gray[500]
}))``
