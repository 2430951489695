import { Routes } from '@service/routes'
import router from 'next/router'
import { parseCookies } from 'nookies'
import {
    INDEX_OF_LOGON_TIME_IN_COOKIES,
    INDEX_OF_LOGON_TIME_IN_COOKIES_TELEVENDEDOR,
    USER_ACTIVITY_KEY
} from '../constants'

export const shouldValidateUserSession = () => {
    return router.pathname !== Routes.resetPassword
}

export const checkIfTheUserIsNotAuthenticatedInCookies = (
    isTelevendedorAuthenticated: boolean
) => {
    try {
        const cookies = parseCookies()
        let cookieKey = ''
        Object.keys(cookies).forEach(function (key) {
            if (key.includes(USER_ACTIVITY_KEY)) cookieKey = key
        })

        const cookieValue = cookies[cookieKey]

        const index = isTelevendedorAuthenticated
            ? INDEX_OF_LOGON_TIME_IN_COOKIES_TELEVENDEDOR
            : INDEX_OF_LOGON_TIME_IN_COOKIES

        if (cookieValue) {
            const cookieSplited = cookieValue.split(',')
            const logonTime = cookieSplited[index]

            return logonTime === 'null'
        } else return true
    } catch (e: unknown) {
        console.error('Error parsing user activity cookie')
    }
}

export const getFormattedChannelName = (channelName: string) => {
    const channelNameSplitted = channelName.split(' ')
    const firstLetter = channelNameSplitted[2]?.charAt(0)
    const restOfTheName = channelNameSplitted[2]?.slice(1)
    return `${firstLetter}${restOfTheName?.toLowerCase()}`
}

export const getFormattedChannelNumber = (channelNumber: string) => {
    const channelNumberSplitted = channelNumber.split(' ')
    return channelNumberSplitted[0]
}

export const getFormattedChannelDescription = (channel: string) => {
    const channelArray = channel.split(' ').slice(4)
    const channelDescription = channelArray.join(' ')
    return channelDescription
}
