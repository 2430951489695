import { appActions } from '@store/modules/app/slice'
import { authActions } from '@store/modules/auth/slice'
import { clearSessionThunk } from '@store/modules/auth/thunk'
import { store } from '@store/modules/store'
import { isClientSide, isStaticOrServerSide } from '@utils/common'
import {
    BrowserQueryParam,
    defaultStoreIdAndChannel,
    getChannelFromRequest,
    getStoreIdAndChannelForRequest
} from '@utils/store-channel'
import axios, {
    AxiosError,
    AxiosResponse,
    InternalAxiosRequestConfig
} from 'axios'
import Router from 'next/router'
import { Routes } from './routes'
import { getUrlWithStoreIdAndTrailingSlash } from './utils'

const getBaseUrl = () => {
    if (isStaticOrServerSide()) {
        return `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}${process.env.NEXT_PUBLIC_BACKEND_RESOURCES_URL}`
    } else {
        return `/${process.env.NEXT_PUBLIC_BACKEND_RESOURCES_URL}`
    }
}

const apiConfig = {
    baseURL: getBaseUrl(),
    withCredentials: true,
    headers: { 'Content-Type': 'text/plain; charset=utf-8' },
    paramsSerializer: {
        indexes: null
    }
}

const api = axios.create(apiConfig)

// Middleware antes da requisição
api.interceptors.request.use(
    function (config: InternalAxiosRequestConfig) {
        const channelFromRequest = getChannelFromRequest(config)

        const { storeId, channel } = getStoreIdAndChannelForRequest()
        if (!channelFromRequest) {
            config.params = {
                ...config.params,
                [BrowserQueryParam.CHANNEL]: channel
            }
        }

        config.url = getUrlWithStoreIdAndTrailingSlash(config.url, storeId)

        return config
    },
    function (error: AxiosError) {
        return Promise.reject(error)
    }
)

// Middleware depois da requisição
api.interceptors.response.use(
    function (response: AxiosResponse) {
        return response
    },
    function (error: AxiosError<any>) {
        if (isClientSide() && error.response?.status === 401) {
            if (
                error.response?.data.erro.includes('SessionInvalid') ||
                error.response?.data.erro.includes('SessionTimeout')
            ) {
                store.dispatch(authActions.logoutUser())
                store.dispatch(authActions.showError())
            } else {
                Router.replace(Routes.login)
            }

            store.dispatch(clearSessionThunk())
            store.dispatch(appActions.setLoading(false))
        }
        return Promise.reject(error)
    }
)

export const apiWithoutInterceptors = axios.create(apiConfig)

/**
 * Lógica paliativa para utilizar API de produção
 * enquanto API de homologação está sem o parâmetro url nos produtos
 * Não trata StoreId e Channel passados por parâmetro
 */
export const __DANGER_API_PROD__ = axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}wcs/resources/${
        defaultStoreIdAndChannel().storeId
    }/`,
    params: {
        [BrowserQueryParam.CHANNEL]: defaultStoreIdAndChannel().channel
    }
})

export const cancelationToken = axios.CancelToken

export default api
