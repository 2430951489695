import { forwardRef, MouseEventHandler, ReactNode } from 'react'
import * as Styled from './StackStyles'

type StackProps = {
    children?: ReactNode
    className?: string
    onClick?: MouseEventHandler<HTMLElement>
    idName?: string
} & Styled.StyledProps

export const Stack = forwardRef<HTMLDivElement, StackProps>(
    ({ children, className, onClick, idName, ...styleProps }, ref) => {
        return (
            <Styled.StackContainer
                className={className}
                {...styleProps}
                onClick={onClick}
                ref={ref}
                id={idName}
            >
                {children}
            </Styled.StackContainer>
        )
    }
)
