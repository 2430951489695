import { Stack } from '@components/Stack'
import styled from 'styled-components'

export const AvatarContainer = styled(Stack).attrs({
    justifyContent: 'center',
    alignItems: 'center'
})`
    background: ${({ theme }) => theme.colors.opacity};
    width: 42px;
    height: 42px;
    border-radius: 50%;
`
