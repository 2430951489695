import { Stack } from '@components/Stack'
import { Typography } from '@components/ui/Typography'
import styled from 'styled-components'

export const Container = styled(Stack).attrs({
    direction: 'row',
    spacing: 16,
    alignItems: 'center',
    justifyContent: 'center'
})``

export const MediaName = styled(Typography).attrs({
    size: 'sm',
    fontFamily: 'regular'
})`
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.white};
`

export const UserName = styled(Typography).attrs({
    size: 'sm',
    fontFamily: 'medium',
    fullWidth: true
})`
    white-space: nowrap;
    overflow: hidden;
    color: ${({ theme }) => theme.colors.white};
    margin-right: 8px;
    text-overflow: ellipsis;
`

export const UserNameContainer = styled(Stack).attrs({
    alignItems: 'center',
    direction: 'row'
})``

export const UserNameButton = styled(Stack).attrs({
    alignItems: 'center',
    direction: 'row',
    fullWidth: true
})`
    background-color: transparent;
    border: none;
    max-width: 140px;
    cursor: pointer;
    padding: 0;
`

export const PopoverContent = styled(Stack).attrs({
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    direction: 'column'
})`
    width: 297px;
    padding: 12px 24px;
    gap: 12px;
    border-radius: 9.06px;
`

export const InfoChannel = styled(Stack)`
    gap: 4px;
`

export const TextChannel = styled(Typography).attrs(({ theme }) => ({
    fontFamily: 'medium',
    size: 'base',
    color: theme.colors.dark[1000]
}))`
    text-align: justify;
`
export const CurrentChannel = styled(Typography).attrs(({ theme }) => ({
    color: theme.colors.dark[500],
    fontFamily: 'regular'
}))``

export const PopoverText = styled(Typography).attrs(({ theme }) => ({
    fontFamily: 'regular',
    size: 'base',
    color: theme.colors.dark[1000]
}))``

export const PopoverButton = styled.button`
    background-color: transparent;
    display: flex;
    align-items: start;
    border: none;
    cursor: pointer;
    padding: 0;

    :hover {
        text-decoration: underline;
    }
`
