import { useLoadedEffect } from '@hooks/useLoadedEffect'
import { getTelevendasChannelDataThunk } from '@store/modules/user/thunk'
import { TelevendaChannel } from '@store/modules/user/types'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { MediaChannelProps } from './view'

export const mediaChannelModalIO = ({
    closeModal,
    isTelevendedor
}: MediaChannelProps) => {
    const [televendasChannel, setTelevendasChannel] = useState<
        TelevendaChannel[]
    >([])
    const [searchValue, setSearchValue] = useState<string>('')
    const [searchResults, setSearchResults] = useState<TelevendaChannel[]>([])
    const dispatch = useDispatch()

    useLoadedEffect(() => {
        if (isTelevendedor) {
            dispatch(
                getTelevendasChannelDataThunk({
                    onSuccess: data => {
                        setTelevendasChannel(data || [])
                        setSearchResults(data || [])
                    }
                })
            )
        }
    }, [isTelevendedor])

    const handleCloseModal = () => {
        closeModal()
    }

    const handleChannelChange = (channel: string) => {
        const queryString = new URLSearchParams(window.location.search)
        queryString.set('canal', channel)
        window.location.search = queryString.toString()
    }

    const handleChannelSearch = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const value = event.target.value
        setSearchValue(value)
        const filteredChannels = televendasChannel.filter(channel =>
            channel.searchField.includes(value.toLowerCase())
        )
        setSearchResults(filteredChannels)
    }

    return {
        handleCloseModal,
        televendasChannel,
        searchValue,
        searchResults,
        handleChannelChange,
        handleChannelSearch
    }
}

export type MediaChannelModalIO = ReturnType<typeof mediaChannelModalIO>
