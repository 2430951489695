import { MouseEventHandler } from 'react'
import * as Styled from './ContentWithIconStyles'
import { Chevron } from '@assets/svg/Chevron'
import { Skeleton } from '@components/ui/Skeleton'
import { ColoredSvg } from '@components/ui/ColoredSvg'
import { themeStyled } from '@theme/theme'

const rotations = {
    up: '0deg',
    right: '90deg',
    down: '180deg'
}

export type ArrowDirections = keyof typeof rotations

export type ContentWithIconType = {
    icon?: JSX.Element
    children?: JSX.Element | string
    arrowDirection?: ArrowDirections
    arrowColor?: string
    onClick?: MouseEventHandler<HTMLButtonElement>
    loading?: boolean
    className?: string
}

export const ContentWithIcon = ({
    icon,
    arrowDirection,
    arrowColor = themeStyled.colors.gray[800],
    children,
    loading,
    onClick,
    className
}: ContentWithIconType): JSX.Element => {
    let classNameJoin = 'textWithIcon'
    if (className) classNameJoin += ' ' + className

    const getChildren = () => {
        if (typeof children === 'string')
            return <Styled.Text>{children}</Styled.Text>
        return children
    }

    return (
        <Styled.ItemContainer
            onClick={onClick}
            className={classNameJoin}
            disableRipple={!onClick}
            data-testid="content-with-icon"
        >
            {icon && (
                <Styled.IconContainer data-testid="iconContainer">
                    {icon}
                </Styled.IconContainer>
            )}

            {loading ? <Skeleton numberOfLoadingLines={1} /> : getChildren()}

            {arrowDirection && (
                <Styled.ChevronContainer
                    data-testid="arrowContainer"
                    rotation={rotations[arrowDirection]}
                >
                    <ColoredSvg svg={<Chevron />} color={arrowColor} />
                </Styled.ChevronContainer>
            )}
        </Styled.ItemContainer>
    )
}
